import React, { useState } from "react";
import moment from "moment";

import {
  BootstrapTable,
  TableHeaderColumn,
  ExportCSVButton,
} from "react-bootstrap-table";

import { Modal, Button } from "react-bootstrap";

import AddCATs from "../../components/AddCATs/addCats";
import CATSDetails from "../../components/CATSDetails/CATSDetails";

import {
  renderShowsTotal,
  timeConvert,
  formatCATSStatus,
  tdStyle,
} from "../utils/tableUtilities";

const ActionButton = ({ row, isAdmin }) => {
  const [showCatsModal, setShowCatsModal] = useState(false);

  const handleShowCatsModal = () => {
    setShowCatsModal(!showCatsModal);
  };

  return (
    <>
      <Button
        style={{ marginRight: "10px" }}
        bsStyle="primary"
        onClick={handleShowCatsModal}
      >
        View Details
      </Button>

      <Modal show={showCatsModal} onHide={handleShowCatsModal}>
        <Modal.Header closeButton>
          <Modal.Title>{row.full_name}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ height: 400 }}>
          <CATSDetails close={handleShowCatsModal} cats={row} />
        </Modal.Body>
      </Modal>
    </>
  );
};

const AddCATsButton = ({ row, isAdmin }) => {
  const [showAddCatsModal, setShowAddCatsModal] = useState(false);

  const handleShowAddCatsModal = () => {
    setShowAddCatsModal(!showAddCatsModal);
  };

  return (
    <>
      <Button
        style={{ float: "right", marginBottom: "2rem", marginRight: "10px" }}
        bsStyle="primary"
        onClick={handleShowAddCatsModal}
      >
        Add CATs / VHT
      </Button>
      <Modal show={showAddCatsModal} onHide={handleShowAddCatsModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add CATs/VHT</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ height: 330 }}>
          <AddCATs close={handleShowAddCatsModal} />
        </Modal.Body>
      </Modal>
    </>
  );
};

const CATSTable = ({ CATS }) => {
  const { data } = CATS;

  const actionButton = (cell, row, enumObject, rowIndex) => {
    return <ActionButton cell={cell} row={row} rowIndex={rowIndex} />;
  };

  const handleExportCSVButtonClick = (onClick) => {
    onClick();
  };

  const createCustomExportCSVButton = (onClick) => {
    return (
      <ExportCSVButton
        btnText="Export to CSV"
        btnGlyphicon="glyphicon-download"
        onClick={() => handleExportCSVButtonClick(onClick)}
      />
    );
  };

  const options = {
    exportCSVBtn: createCustomExportCSVButton,
    page: 1,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "25",
        value: 25,
      },
      {
        text: "All",
        value: data && data.length,
      },
    ],
    sizePerPage: 15,
    pageStartIndex: 1,
    paginationSize: 3,
    prePage: "Prev",
    nextPage: "Next",
    firstPage: "First",
    lastPage: "Last",
    paginationShowsTotal: renderShowsTotal,
    paginationPosition: "bottom",
  };

  return (
    <div className="voucher-table-container">
      <AddCATsButton />
      <BootstrapTable
        data={data}
        striped
        hover
        pagination={true}
        options={options}
        exportCSV
        csvFileName={
          "cats" +
          moment(Date.now()).local().format("YYYY_MM_DD_HHmmss") +
          ".csv"
        }
      >
        <TableHeaderColumn
          isKey
          dataSort={true}
          dataField="full_name"
          thStyle={tdStyle}
          tdStyle={tdStyle}
          csvHeader="VHT / CATs Name"
        >
          VHT / CATs Name
        </TableHeaderColumn>
        <TableHeaderColumn
          dataSort={true}
          dataField="cats_id"
          thStyle={tdStyle}
          tdStyle={tdStyle}
          width="130px"
          csvHeader="VHT / CATS ID"
        >
          CATS / VHT ID
        </TableHeaderColumn>
        <TableHeaderColumn
          dataSort={true}
          dataField="phone_number"
          thStyle={tdStyle}
          tdStyle={tdStyle}
          width="140px"
          csvHeader="Phone Number"
        >
          Phone number
        </TableHeaderColumn>
        <TableHeaderColumn
          dataSort={true}
          dataField="division"
          thStyle={tdStyle}
          tdStyle={tdStyle}
          width="100px"
          csvHeader="Division"
        >
          Division
        </TableHeaderColumn>
        <TableHeaderColumn
          dataSort={true}
          dataField="number_of_households_mapped"
          thStyle={tdStyle}
          tdStyle={tdStyle}
          width="180px"
          csvHeader="Number of Households Mapped"
        >
          Households mapped
        </TableHeaderColumn>
        <TableHeaderColumn
          dataSort={true}
          dataField="date_of_registration"
          dataFormat={timeConvert}
          thStyle={tdStyle}
          tdStyle={tdStyle}
          width="180px"
          csvHeader="Date of Registration"
        >
          Date of registration
        </TableHeaderColumn>
        <TableHeaderColumn
          dataSort={true}
          dataField="isApprovedByDO"
          dataFormat={formatCATSStatus}
          thStyle={tdStyle}
          tdStyle={tdStyle}
          csvFormat={formatCATSStatus}
          csvHeader="Status"
        >
          Status
        </TableHeaderColumn>

        <TableHeaderColumn
          dataField="action"
          dataFormat={actionButton}
          dataAlign="center"
          thStyle={tdStyle}
          tdStyle={tdStyle}
        >
          Action
        </TableHeaderColumn>
      </BootstrapTable>
    </div>
  );
};

export default CATSTable;
