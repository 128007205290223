import React, { Component } from 'react';


export class Privacy extends React.Component{
    render(){
        return(
            <div className="container">
            <div className="col-md-12">
                <h3 className="page-header">Weyonje Privacy Policy</h3>
               
               <h4>1. Privacy policy summary</h4>
               <p>
               Kampala City Council Authorty (KCCA) is the legal entity established by the Uganda Parliament, that is responsible for the operations of the capital City of Kampala in Uganda.
                <br/>
This policy applies to our Weyonje mobile app. Weyonje is a mobile application designed for waste management service providers in the capital Kampala to receive and manage work orders. As a regulating entity, the mobile application is a point of interface between the service providers and KCCA
            <br/>
For information collected under this policy, the data controller is KCCA. Currently, the data protection and privacy bill 2015 exists as the regulatory regime in Uganda which governs the collection, processing and storage of data that KCCA is obligated to uphold. KCCA will also abide by universally-accepted privacy and data protection standards some of which include GPDR to mention but a few.

               </p>
               <br/>
               <h4>2. Information collected by KCCA</h4>
               <p>
               In the course of using our Weyonje mobile application, we may collect information about you and about your use of the app. We affirm our commitment to being transparent in our handling of personal information, and we endeavour to comply with all applicable privacy and data protection laws which affect KCCA.
We will principally collect information from you when you download the weyonje mobile app.<br/>
When you download Weyonje, we will request access to your identity, which will be obtained from any of the accounts already registered with your device. We will also request for access to your device’s media files, location and mobile number.
<br/>
During the course of using Weyonje, certain information will also be collected manually and automatically. KCCA will collect your customer details. KCCA will automatically record statistical information about your usage of Weyonje, your location, as well as information about the device you are using and your internet connection. We do this through log data and location tracking technologies.
<br/>
With log data, when you use Weyonje, certain information gets recorded in our log files such as your device’s internet protocol address, its unique ID, its operating system and location. With tracking technologies, we may use technologies such as tags and small data files to enable us to recognise your device and to track your interaction with Weyonje. We do not use these technologies to identify you and we do not intentionally collect or record personally identifiable information through these technologies. 
<br/>
Information may also be collected from you via social media and other third parties. Where you interact with Weyonje through a social media platform, we may collect information from you directly, or the social network may share with us personal information about you. This can consist of information such as your name, profile picture, gender, e-mail address and date of birth. Please keep in mind that any information provided to us via a third party may also be subject to that third party’s privacy policy.

               </p>
               <br/>
               <h4>3. How we use your information</h4>
               <p>
               We will use the information we collect for our legitimate business purposes and needs information, and to also improve Weyonje user functionality and experience. Specifically, we may use your information to:
               </p>
               <br/>
               <p>a. Analytics, research, development and optimisation of Weyonje;</p>
               <p>b. Marketing, promotion and fundraising to create awareness or improve waste management in the city; </p>
               <p>c. Customer services, including sending you service related communications and responding to your requests and feedback; and</p>
               <p>d. Prevent fraud or other potentially illegal activities like illegal dumping;</p>
               <br/>
                <p>
                Where KCCA proposes to use your personal information for any other uses, we will ensure that we notify you first. You will also be given the opportunity to withhold or withdraw your consent for your use other than as listed above.
                </p>
                <br/>
                <h4>4. How long do we keep your information and where do we store it?  </h4>
                <p>We will hold your personal information on our systems for as long as is reasonably necessary for the relevant service or as long as is set out in any 
                    relevant contract you hold with us. We may also share your information with other with contracted third party service providers, such as developers. Where these companies may be 
                    located in, or use information technology equipment located in a country different from that which your information is collected, we will take steps to ensure that your information receives an 
                    equivalent level of protection.
                    <br/>
                    KCCA will store your information on servers in Uganda that KCCA exclusively controls, and any other cloud providers during the development of the mobile application.
                    </p>
                    <br/>
            <h4>5. Security of information</h4>
            <p>KCCA maintains appropriate organisational and technological safeguards to help protect against loss, misuse or unauthorised access, disclosure, alteration or destruction of the information we hold about you. We also seek to ensure our service providers do the same. Unfortunately, no system can be guaranteed as completely secure and there is an inherent risk in transmitting information over the internet. It may be possible for an unrelated party to intercept or access such transmissions unlawfully. 
                If you believe that your personal information under our control has been compromised, including access to any accounts you hold with our services, please contact us immediately using the details set out below.</p>

                <h3>6. Rights in relation to your information</h3>
                <p>You have certain rights in connection with your personal information and how we handle it.  These rights include:</p>
                <br/>
                <p>
                a. <b>Right of access:</b> You have a right to know what information we hold about you and in some cases to have the information communicated to you. If you wish to exercise this right please contact us letting us know that you wish to exercise your right of access and what information in particular you would like to receive. We reserve the right to ask for reasonable evidence to verify your identity before we provide you with any information.
                </p>
                <p>
               b. <b>Right to correct personal information:</b> We try to keep the information that we hold about you accurate and up to date. Should you realise that any of the information that we hold about you is incorrect, please let us know and we will correct it as soon as we can. 
                </p>
                <p>
                c. <b>Data deletion:</b> In some circumstances, you have a right to have some of the personal information that we hold about you deleted. Should you wish to have any information about you deleted, please contact us immediately. 
                </p>
                <br/>
                <h4>7. Changes to the policy</h4>
                <p>
                This privacy policy may be updated from time to time to reflect changes in law, best practice or in our practices regarding the treatment of personal information. If material changes are made to this privacy policy we will notify you by placing a notice. Any new policy will be effective from the date it is published by us. You should check this policy frequently for updates.
                </p>
                <br/>
                <h4>4. Contact us</h4>
                <p>If you have any questions or comments about this policy or KCCA’s approach to privacy, please contact us at </p>
                <br/>
                <p>
                Kampala Capital City Authority
                <br/>
                City Hall, Plot 1- 3, Apollo Kaggwa Road
                <br/>
                P.O. Box 7010
                <br/>
                Kampala, Uganda
                <br/>
                T: 0800 99 00 00
                <br/>
                Attention: 	Head of Legal / Company Secretary
                <br/>
                        Email: 
                </p>
            </div>
            </div>
        );
    }
}