import React, { useState } from "react";

import { CustomLoading } from "../inc/Layout";

import "../../styles/voucher.css";
import "alertifyjs/build/css/alertify.min.css";
import "alertifyjs/build/css/themes/semantic.min.css";

const alertifyjs = require("alertifyjs");
const gSession = window.sessionStorage;
const token = gSession.getItem("wat");
const createdBy = gSession.getItem("wai");

const DEFAULT_OPTIONS = {
  headers: {
    cache: "no-store",
    credentials: "same-origin",
    authorization: token,
    "content-type": "application/json",
  },
};

const addressEndpoint = require("../../env_config").default;

function AddVoucherSetting({ allDivisions, close }) {
  const { data } = allDivisions;

  const [programName, setProgramName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [divisions, setDivisions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [maxVouchersPerDivision, setMaxVouchersPerDivision] = useState(0);
  const [defaultVoucherValidity, setDefaultVoucherValidity] = useState(0);
  const [defaultVoucherValue, setDefaultVoucherValue] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [kccaPercentage, setKccaPercentage] = useState(0);

  const handleToggleDivisionCheckbox = (checked, id, name) => {
    if (checked) {
      setDivisions([...divisions, { divisionID: id, divisionName: name }]);
    } else {
      setDivisions(divisions.filter((d) => d.divisionID !== id));
    }
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();

    const payload = {
      divisions: divisions.map((d) => ({
        ...d,
        maxVouchers: parseInt(maxVouchersPerDivision),
      })),

      startDate,
      endDate,
      maxVouchersPerDivision: parseInt(maxVouchersPerDivision),
      defaultVoucherValidity: parseInt(defaultVoucherValidity),
      defaultVoucherValue: parseInt(defaultVoucherValue),
      createdBy,
      programName,
      kccaPercentage,
      beneficiaryAmount: parseInt(
        defaultVoucherValue - (kccaPercentage / 100) * defaultVoucherValue
      ),
    };

    const url = addressEndpoint + `api/v1/voucherProgram/`;

    await fetch(url, {
      method: "POST",
      body: JSON.stringify(payload),
      ...DEFAULT_OPTIONS,
    })
      .then((result) => {
        if (!result.ok) throw result;
        return result.json();
      })
      .then((res) => {
        setLoading(false);
        close();

        alertifyjs.success(
          "Voucher Program Created. Approval notification sent to DPHE (Dr. Daniel Okello)",
          10
        );

        window.location.href = "/vouchers";
      })
      .catch((error) => {
        error.json().then((body) => {
          setLoading(false);
          setErrorMessage(body.error);
        });
      });
  };

  return (
    <div className="bootstrap-iso">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12">
            {loading ? (
              <CustomLoading text="Creating voucher program" />
            ) : (
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className="col-md-12 editCATForm">
                  <div className="form-group ">
                    <label
                      className="control-label requiredField"
                      htmlFor="programName"
                    >
                      Program Name
                    </label>
                  </div>

                  <div className="form-group">
                    <input
                      className="form-control"
                      id="programName"
                      name="programName"
                      onChange={(e) => setProgramName(e.target.value)}
                      style={{ width: "200px" }}
                    />
                  </div>
                </div>
                <div className="col-md-12 editCATForm">
                  <div className="form-group ">
                    <label
                      className="control-label requiredField"
                      htmlFor="startDate"
                    >
                      Voucher Period Start Date
                    </label>
                  </div>

                  <div className="form-group ">
                    <input
                      className="form-control"
                      id="startDate"
                      name="startDate"
                      type="date"
                      onChange={(e) => setStartDate(e.target.value)}
                      style={{ width: "200px" }}
                    />
                  </div>
                </div>
                <div className="col-md-12 editCATForm">
                  <div className="form-group ">
                    <label
                      className="control-label requiredField"
                      htmlFor="endDate"
                    >
                      Voucher Period End Date
                    </label>
                  </div>

                  <div className="form-group ">
                    <input
                      className="form-control"
                      id="endDate"
                      name="endDate"
                      type="date"
                      onChange={(e) => setEndDate(e.target.value)}
                      style={{ width: "200px" }}
                    />
                  </div>
                </div>
                <div className="col-md-12 editCATForm">
                  <div className="form-group ">
                    <label
                      className="control-label requiredField"
                      htmlFor="full_name"
                    >
                      Active / Participating Divisions
                    </label>
                  </div>

                  <div className="form-group" style={{ display: "flex" }}>
                    {data &&
                      data.map((division) => {
                        return (
                          <ul className="vouchers-ul none" key={Math.random()}>
                            {
                              <li
                                key={Math.random()}
                                style={{
                                  display: "flex",
                                }}
                              >
                                <input
                                  style={{ marginRight: 5 }}
                                  type="checkbox"
                                  checked={divisions
                                    .map((d) => d.divisionID)
                                    .includes(division._id)}
                                  onChange={(e) =>
                                    handleToggleDivisionCheckbox(
                                      e.target.checked,
                                      division._id,
                                      division.name
                                    )
                                  }
                                ></input>
                                {division.name}
                              </li>
                            }
                          </ul>
                        );
                      })}
                  </div>
                </div>
                <div className="col-md-12 editCATForm">
                  <div className="form-group ">
                    <label
                      className="control-label requiredField"
                      htmlFor="defaultVoucherValidity"
                    >
                      Default Voucher Validity (days)
                    </label>
                  </div>

                  <div className="form-group">
                    <input
                      className="form-control"
                      id="defaultVoucherValidity"
                      name="defaultVoucherValidity"
                      onChange={(e) =>
                        setDefaultVoucherValidity(parseInt(e.target.value))
                      }
                      style={{ width: "100px" }}
                    />
                  </div>
                </div>
                <div className="col-md-12 editCATForm">
                  <div className="form-group ">
                    <label
                      className="control-label requiredField"
                      htmlFor="defaultVoucherValue"
                    >
                      Default Voucher Value (UGX)
                    </label>
                  </div>

                  <div className="form-group">
                    <input
                      className="form-control"
                      id="defaultVoucherValue"
                      name="defaultVoucherValue"
                      onChange={(e) =>
                        setDefaultVoucherValue(parseInt(e.target.value))
                      }
                      style={{ width: "150px" }}
                    />
                  </div>
                </div>

                <div className="col-md-12 editCATForm">
                  <div className="form-group ">
                    <label
                      className="control-label requiredField"
                      htmlFor="kccaPercentage"
                    >
                      KCCA Percentage
                    </label>
                  </div>

                  <div className="form-group">
                    <input
                      className="form-control"
                      id="kccaPercentage"
                      name="kccaPercentage"
                      onChange={(e) =>
                        setKccaPercentage(parseInt(e.target.value))
                      }
                      style={{ width: "150px" }}
                    />
                  </div>
                </div>

                <div className="col-md-12 editCATForm">
                  <div className="form-group ">
                    <label
                      className="control-label requiredField"
                      htmlFor="beneficiaryAmount"
                    >
                      Beneficiary to pay
                    </label>
                  </div>

                  <div className="form-group">
                    <input
                      className="form-control"
                      id="beneficiaryAmount"
                      name="beneficiaryAmount"
                      value={
                        defaultVoucherValue === "" || kccaPercentage === ""
                          ? 0
                          : parseInt(
                              defaultVoucherValue -
                                (kccaPercentage / 100) * defaultVoucherValue
                            )
                      }
                      disabled
                      style={{ width: "150px" }}
                    />
                  </div>
                </div>
                <div className="col-md-12 editCATForm">
                  <div className="form-group ">
                    <label
                      className="control-label requiredField"
                      htmlFor="maxVouchersPerDivision"
                    >
                      Total Number of Vouchers per Division
                    </label>
                  </div>

                  <div className="form-group">
                    <input
                      className="form-control"
                      id="maxVouchersPerDivision"
                      name="maxVouchersPerDivision"
                      onChange={(e) => {
                        setMaxVouchersPerDivision(e.target.value);
                      }}
                      style={{ width: "150px" }}
                    />
                  </div>
                </div>

                {errorMessage && (
                  <div className="col-md-12">
                    <p style={{ color: "red" }}>
                      {errorMessage.message || errorMessage}
                    </p>
                  </div>
                )}

                <div className="col-md-12">
                  <div
                    className="form-group"
                    style={{
                      display: "flex",
                      justifyContent: "space-evenly",
                      marginTop: 15,
                    }}
                  >
                    <button
                      className="btn btn-primary "
                      name="submit"
                      type="submit"
                    >
                      SAVE
                    </button>
                    <button
                      onClick={close}
                      className="btn btn-default "
                      name="cancel"
                      type="button"
                      style={{
                        backgroundColor: "red",
                        color: "white",
                      }}
                    >
                      CANCEL
                    </button>
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddVoucherSetting;
