import { GeoSearchControl, GoogleProvider } from 'leaflet-geosearch';
import React, { Component } from 'react';
import 'leaflet-geosearch/assets/css/leaflet.css';
import 'leaflet/dist/leaflet.css';
import 'alertifyjs/build/css/alertify.min.css';
import 'alertifyjs/build/css/themes/semantic.min.css';
import Select from 'react-select';
import Switch from 'react-toggle-switch';
import 'react-toggle-switch/dist/css/switch.min.css';
import { CustomLoading } from './inc/Layout';

const io = require('socket.io-client');

const L = require('leaflet');
const Pointer = require('../assets/pointer.png');
const alertifyjs = require('alertifyjs');
const addr = require('../env_config').default;
var unirest = require('unirest');

const provider = new GoogleProvider({
  region: 'ug',
  params: {
    key: 'AIzaSyCGy8oKABLzT1zJluhQnvSTZAm2uuXb08k',
  },
});

var PointerIcon = L.icon({
  iconUrl: Pointer,
  iconSize: [50, 50], // size of the icon
  iconAnchor: [22, 94], // point of the icon which will correspond to marker's location
  popupAnchor: [-3, -76], // point from which the popup should open relative to the iconAnchor
});

const searchControl = new GeoSearchControl({
  provider: provider,
  showMarker: true,
  style: 'bar',
  marker: {
    // optional: L.Marker    - default L.Icon.Default
    icon: PointerIcon,
    draggable: true,
  },
  showPopup: false,
  popupFormat: ({ query, result }) => result.label, // optional: function    - default returns result label
  maxMarkers: 1, // optional: number      - default 1
  retainZoomLevel: false, // optional: true|false  - default false
  animateZoom: true, // optional: true|false  - default true
  autoClose: true, // optional: true|false  - default false
  searchLabel: 'Enter address', // optional: string      - default 'Enter address'
  keepResult: true,
});

const gSession = window.sessionStorage;
const auth = gSession.getItem('wat');
const loggedInId = gSession.getItem('wai');
const divisionID = gSession.getItem('divisionID');

class InnerControlMap extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  pointArea(data) {}
  renderMap() {
    const { lat, long } = this.props;
    var map;
    map = new L.Map('innerMap');
    var osmUrl = 'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
    var osmAttrib = 'Weyonjje';
    var osm = new L.TileLayer(osmUrl, {
      minZoom: 7,
      zoomControl: false,
      attribution: osmAttrib,
    });
    map.setView(
      new L.LatLng(lat || 0.30809999999999965, long || 32.59680000000029),
      14
    );
    map.addLayer(osm);

    // Only add search control if dispatch from call center and not voucher
    if (lat === null && long === null) {
      map.addControl(searchControl);
    }

    // Show marker of location if voucher
    if (lat !== null && long !== null) {
      var marker = L.marker([lat, long], { icon: PointerIcon });
      marker.addTo(map);
    }
    map.on('geosearch/showlocation', function (e) {
      var address = document.getElementById('address');
      var desiredTitle = document.querySelector('.selectedLocation');
      address.value = e.location.label;
      address.setAttribute('long', e.location.x);
      address.setAttribute('lat', e.location.y);
      desiredTitle.innerHTML =
        'You have Selected <b>' + e.location.label + '</b>';
    });
    map.on('geosearch/marker/dragend', function (e) {
      var address = document.getElementById('address');
      address.setAttribute('long', e.location.lng);
      address.setAttribute('lat', e.location.lat);
    });
  }
  componentDidMount() {
    this.renderMap();
  }
  render() {
    return (
      <div
        className='col-md-12 innerMap'
        style={{ height: '430px', marginBottom: '50px' }}
        id='innerMap'
      ></div>
    );
  }
}

export class AddNewJobForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      driverOptions: [],
      av: [],
      selectedOption: null,
      switched: false,
      beneficiaryInformed: false,
      phone_number:
        (props.voucherDetails && props.voucherDetails.beneficiaryNumber) ||
        null,
      full_name:
        (props.voucherDetails && props.voucherDetails.beneficiary) || null,
      long: (props.voucherDetails && props.voucherDetails.lng) || null,
      lat: (props.voucherDetails && props.voucherDetails.lat) || null,
      address: (props.voucherDetails && props.voucherDetails.address) || null,
      isVoucherJob: props.voucherDetails !== undefined ? true : false,
      spType: (props.voucherDetails && props.voucherDetails.spType) || null,
      isLoading: false,
      loadingText: 'Loading...',
      jobID: null,
    };
    this.handleSubmit.bind(this);
    this.addNewJob.bind(this);
    this.handleChangeOnSelect.bind(this);
  }

  handleChangeOnSelect(selectedOption) {
    this.setState({ selectedOption });
  }

  close() {
    this.props.close();
  }

  toggleSwitch = () => {
    this.setState((prevState) => {
      return {
        switched: !prevState.switched,
      };
    });
  };

  toggleBeneficiaryInformed = () => {
    this.setState((prevState) => {
      return {
        beneficiaryInformed: !prevState.beneficiaryInformed,
      };
    });
  };

  async handleSubmit(e) {
    const thisApp = this;
    this.setState({
      isLoading: true,
      loadingText: 'Creating Job dispatch',
    });
    e.preventDefault();
    var full_name = document.getElementById('full_name');
    var phone_number = document.getElementById('phone_number');
    var address = document.getElementById('address');
    var facility_type = document.getElementById('facility_type');
    var long = this.state.long || address.getAttribute('long');
    var lat = this.state.lat || address.getAttribute('lat');
    let data = {
      full_name: full_name.value,
      phone_number: phone_number.value,
      address: this.state.address || address.value,
      facility_type: facility_type.value,
      long: long || '930493',
      lat: lat || '0238293',
      id: loggedInId,
      auto_dispatch: this.state.switched,
      isVoucherJob: this.state.isVoucherJob,
    };

    if (data.auto_dispatch === true && this.state.driverOptions.length > 0) {
      thisApp.setState({
        isLoading: true,
        loadingText: 'Sending Automated dispatch',
      });

      this.AddSendToNearest(data);
    } else {
      //require entrepreneur id
      if (this.state.selectedOption === null) {
        thisApp.setState({
          isLoading: false,
          loadingText: 'Sending Manual dispatch',
        });
        alertifyjs.message('No available service providers at the moment', 2);
        return false;
      } else {
        thisApp.setState({
          isLoading: true,
          loadingText: 'Automating dispatch',
        });
        await this.addNewJob(data);
      }
    }
  }

  AddSendToNearest(data) {
    this.setState({
      isLoading: true,
      loadingText: 'Finding nearest service provider',
    });
    const thisApp = this;
    var req = unirest('POST', addr + 'api/job');

    req.headers({
      'cache-control': 'no-cache',
      'content-type': 'application/x-www-form-urlencoded',
      authorization: auth,
    });

    req.form(data);

    req.end(function (res) {
      if (res.error) {
        thisApp.setState({
          isLoading: false,
        });
      } else {
        thisApp.findTheNearestSp(res.body.data, data);
      }
    });
  }

  findTheNearestSp(job, data) {
    const thisApp = this;
    var req = unirest('POST', addr + 'api/sp/nearest');

    req.headers({
      'cache-control': 'no-cache',
      'content-type': 'application/json',
      authorization: auth,
    });
    let datax = {
      coordinates: [data.long, data.lat],
      //this should be and object of coordinates with ids
      sps: this.state.av,
    };
    req.send(datax);

    req.end(function (res) {
      if (res.error) {
        thisApp.setState({
          isLoading: false,
          loadingText: 'Finding nearest service provider',
        });
      } else {
        if (res.body.length > 0) {
          thisApp.updateDispatch(job, res.body[0]);
        } else {
          thisApp.setState({
            isLoading: false,
            loadingText: 'Finding nearest service provider',
          });
          alertifyjs.error('There is no service provider near by', 5);
        }
      }
    });
  }

  updateDispatch(job, sp) {
    const thisApp = this;
    thisApp.setState({
      isLoading: true,
      loadingText: 'Finalizing dispatch',
    });
    var req = unirest('PUT', addr + 'api/dispatch/job/' + job._id);

    req.headers({
      'cache-control': 'no-cache',
      'content-type': 'application/x-www-form-urlencoded',
      authorization: auth,
    });
    let dispatchData = {
      ent_id: sp._id,
    };
    req.send(dispatchData);

    req.end(function (res) {
      if (res.error) {
        thisApp.setState({
          isLoading: false,
        });
        alertifyjs.error('Could not save dispatch', 5);
      } else {
        if (thisApp.state.isVoucherJob) {
          const voucherInfo = {
            voucherValue: thisApp.props.voucherDetails.value,
            voucherNumber: thisApp.props.voucherDetails.voucherReadableID,
            division: thisApp.props.voucherDetails.validForDivision,
            voucherBeneficiaryAmount:
              thisApp.props.voucherDetails.beneficiaryAmount,
          };
          thisApp.sendNotifications(
            sp.device_id,
            job._id,
            sp.userId,
            voucherInfo
          );
        } else {
          thisApp.sendNotifications(sp.device_id, job._id, sp.userId);
        }
      }
    });
  }

  updateJob(job, callback) {
    var req = unirest('PUT', addr + 'api/job/' + job._id);

    req.headers({
      'cache-control': 'no-cache',
      'content-type': 'application/x-www-form-urlencoded',
      authorization: auth,
    });
    req.send(job);

    req.end(function (res) {
      if (res.error) {
        return callback(res.error);
      } else {
        return callback(null, res.body);
      }
    });
  }

  checkNotificationStatus(id, callback) {
    var req = unirest(
      'GET',
      'https://api.pushy.me/pushes/' +
        id +
        '?api_key=2ab2394589ec7669a24880ede64fe112e969eeb7a62e42ba655c51a2ca0fe3b6'
    );

    req.headers({
      'cache-control': 'no-cache',
      'content-type': 'application/json',
    });

    req.end(function (res) {
      if (res.error) {
        return callback(res.error);
      } else {
        return callback(null, res.body);
      }
    });
  }

  async addNewJob(data) {
    const thisApp = this;
    var req = unirest('POST', addr + 'api/job');

    req.headers({
      'cache-control': 'no-cache',
      'content-type': 'application/x-www-form-urlencoded',
      authorization: auth,
    });

    req.form(data);

    await req.end(function (res) {
      if (res.error) {
        thisApp.setState({
          isLoading: false,
        });
      } else {
        // Save the JobID in state, so we can send it to the voucher update later
        thisApp.setState({ jobID: res.body.data._id });

        thisApp.updateDispatch(res.body.data, thisApp.state.selectedOption);
      }
    });
  }

  sendNotifications(device_id, job_id, userId = '', voucherDetails) {
    const thisApp = this;
    var req = unirest('POST', addr + 'api/dispatch');

    req.headers({
      'cache-control': 'no-cache',
      'content-type': 'application/x-www-form-urlencoded',
      authorization: auth,
    });

    req.form({
      device_id,
      job_id,
      userId,
      // Only set these if its a voucher job
      // ...(voucherDetails && {voucherValue: voucherDetails.voucherValue}),
      ...(voucherDetails && { voucherValue: voucherDetails.voucherValue }),
      ...(voucherDetails && { voucherNumber: voucherDetails.voucherNumber }),
      ...(voucherDetails && { voucherDivision: voucherDetails.division }),
      ...(voucherDetails && {
        voucherBeneficiaryAmount: voucherDetails.voucherBeneficiaryAmount,
      }),
      ...(voucherDetails && { isVoucherJob: true }),
    });
    req.end(function (res) {
      if (res.error) {
        thisApp.setState({
          isLoading: false,
          loadingText:
            'Service provider could not receive the job notification',
        });
        alertifyjs.error(
          'Service provider could not receive the job notification please resend ',
          5
        );
      } else {
        thisApp.setState({
          isLoading: false,
        });
        if (res.body[0].success === 0) {
          thisApp.setState({
            isLoading: false,
            //loadingText:"Finalising dispatch"
          });
          // Only navigate to dispatchers page if its not a voucher request
          if (thisApp.props.voucherDetails) {
            window.location.href = '/vouchers';
          } else {
            window.location.href = '/dispatchers';
          }

          // window.location.href = '/dispatchers';
          alertifyjs.error(
            'Service provider could not receive the job notification please resend',
            5
          );
        } else if (res.body[0].success === 1) {
          let job = {
            notification: res.body[0].results[0].message_id,
            _id: job_id,
          };
          thisApp.updateJob(job, function (err, response) {
            if (err) {
              thisApp.setState({
                isLoading: false,
              });
              alertifyjs.error('Notification was not delivered', 2);
              if (thisApp.props.voucherDetails) {
                window.location.href = '/vouchers';
              } else {
                window.location.href = '/dispatchers';
              }

              // window.location.href = '/dispatchers';
            } else {
              alertifyjs.success(
                'Notification was sent, waiting for confirmation',
                5,
                async function () {
                  // Update the voucher to signal its been dispatached to an SP
                  if (thisApp.state.isVoucherJob) {
                    const DEFAULT_OPTIONS = {
                      headers: {
                        cache: 'no-store',
                        credentials: 'same-origin',
                        authorization: auth,
                        'content-type': 'application/json',
                      },
                    };
                    const url =
                      addr +
                      `api/v1/officer/voucher/actionVoucher/${thisApp.props.voucherDetails._id}`;
                    const data = {
                      doID: loggedInId,
                      status: 3,
                      spID: thisApp.state.selectedOption._id,
                      jobID: job_id,
                    };

                    await fetch(url, {
                      method: 'PATCH',
                      body: JSON.stringify(data),
                      ...DEFAULT_OPTIONS,
                    }).then((response) => {
                      if (response.status === 200) {
                        thisApp.close();
                        window.location.href = '/vouchers';
                      }
                    });
                    thisApp.close();
                    window.location.href = '/vouchers';
                  } else {
                    thisApp.close();
                    window.location.href = '/dispatchers';
                  }
                }
              );
            }
          });
        } else {
        }
      }
    });
  }
  async componentDidMount() {
    const thisApp = this;

    const availableDrivers = await fetch(addr + 'api/sp/available', {
      method: 'GET',
      headers: {
        cache: 'no-store',
        credentials: 'same-origin',
        authorization: auth,
        'content-type': 'application/json',
      },
    }).then((res) => {
      return res.json();
    });

    if (availableDrivers.length === 0) {
      thisApp.setState({
        selectedOption: null,
      });
    }

    const drivers = [];
    const available = [];

    let returnedDrivers = [];

    // Filter out and return only SPs allowed to handle voucher jobs for this division
    if (thisApp.state.isVoucherJob) {
      returnedDrivers = availableDrivers.filter((d) => {
        return (
          d.isVoucherSP === true &&
          d.approved === true &&
          d.division === divisionID &&
          d.type === this.state.spType
        );
      });
    } else {
      returnedDrivers = availableDrivers;
    }

    returnedDrivers.map((driver) => {
      drivers.push({
        value: driver.device_id,
        userId: driver.userId,
        device_id: driver.device_id,
        _id: driver._id,
        label:
          driver.full_name + ' - ' + driver.type + ' - ' + driver.phone_number,
      });

      available.push({
        long: driver.long,
        lat: driver.lat,
        userId: driver.userId,
        device_id: driver.device_id,
        _id: driver._id,
      });
    });

    thisApp.setState({
      driverOptions: drivers,
      av: available,
    });
  }
  render() {
    const { selectedOption } = this.state;
    let ft = [
      'Lined',
      'Unlined',
      'Lined pit latrine with slab',
      'Unlined pit latrine without slab',
      'Flush toilet connected to septic tank',
      'Unlined pit latrine with slab',
      'Composting toilet',
      'I dont know',
    ];
    let ftOptions = ft.map((ft) => (
      <option value={ft} key={Math.random()}>
        {ft}
      </option>
    ));
    return (
      <React.Fragment>
        <div className='bootstrap-iso'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-md-12 col-sm-12 col-xs-12'>
                {this.state.isLoading && this.state.isLoading === true ? (
                  <CustomLoading text={this.state.loadingText} />
                ) : (
                  <form
                    onSubmit={(e) => this.handleSubmit(e)}
                    className='col-md-12 addnewJob'
                    id='addNewJob'
                  >
                    <div className='col-md-6'>
                      <div className='form-group '>
                        <label
                          className='control-label requiredField'
                          htmlFor='phone_number'
                        >
                          Customer Phone Number |{' '}
                          {this.props.voucherDetails ? 'true' : 'false'}
                          <span className='asteriskField'>*</span>
                        </label>
                        <input
                          className='form-control'
                          id='phone_number'
                          name='phone_number'
                          required
                          type='tel'
                          defaultValue={this.state.phone_number || ''}
                          onChange={(e) =>
                            this.setState({ phone_number: e.target.value })
                          }
                          readOnly={this.props.voucherDetails ? true : false}
                        />
                      </div>
                      <br className='clearBoth' />
                      <div className='form-group '>
                        <label
                          className='control-label requiredField'
                          htmlFor='email'
                        >
                          Customer Name
                          <span className='asteriskField'>*</span>
                        </label>
                        <input
                          className='form-control'
                          id='full_name'
                          name='full_name'
                          required
                          // placeholder='John'
                          type='text'
                          defaultValue={this.state.full_name || ''}
                          readOnly={this.props.voucherDetails ? true : false}
                        />
                      </div>
                      <div className='hidden form-group '>
                        <label
                          className='control-label requiredField'
                          htmlFor='location'
                        >
                          Customer Address
                          <span className='asteriskField'>*</span>updateDispatch
                        </label>
                        <input
                          disabled
                          className=' form-control'
                          id='address'
                          long=''
                          lat=''
                          name='location'
                          placeholder='Select a location on the map'
                          type='text'
                          readOnly={this.props.voucherDetails ? true : false}
                        />
                        <span className='help-block' id='hint_location'>
                          Search for a customer location
                        </span>
                      </div>

                      <div className='form-group '>
                        <label
                          className='control-label requiredField'
                          htmlFor='select'
                        >
                          Toilet Facility
                          <span className='asteriskField'>*</span>
                        </label>
                        {this.props.voucherDetails ? (
                          <>
                            <input
                              className='form-control'
                              id='facility_type'
                              name='select'
                              required
                              // placeholder='John'
                              type='text'
                              value={
                                this.props.voucherDetails.facilityType || ''
                              }
                              readOnly={
                                this.props.voucherDetails ? true : false
                              }
                            />
                          </>
                        ) : (
                          <select
                            className='select form-control'
                            id='facility_type'
                            required
                            name='select'
                          >
                            {ftOptions}
                          </select>
                        )}
                      </div>

                      <br className='clearBoth' />
                      <div className='form-group '>
                        <label
                          className='control-label requiredField'
                          htmlFor='select'
                        >
                          Auto dispatch
                          <span className='asteriskField'>*</span>
                        </label>
                        <br className='clearBoth' />
                        <Switch
                          onClick={this.toggleSwitch}
                          on={this.state.switched}
                        />
                      </div>
                      {this.state.isVoucherJob && (
                        <>
                          <br className='clearBoth' />
                          <div className='form-group '>
                            <label
                              className='control-label requiredField'
                              htmlFor='select'
                            >
                              Beneficiary informed ?
                              <span className='asteriskField'>*</span>
                            </label>
                            <br className='clearBoth' />
                            <Switch
                              onClick={this.toggleBeneficiaryInformed}
                              on={this.state.beneficiaryInformed}
                            />
                          </div>{' '}
                        </>
                      )}

                      <br className='clearBoth' />
                      {!this.state.switched && (
                        <React.Fragment>
                          <div className='form-group '>
                            <label
                              className='control-label requiredField'
                              htmlFor='phone_number'
                            >
                              Search for available service Providers (
                              {this.state.driverOptions.length})
                              <span className='asteriskField'>*</span>
                            </label>
                            <Select
                              className='e_phone_number'
                              onChange={(e) => this.handleChangeOnSelect(e)}
                              value={selectedOption}
                              placeholder='Select a driver'
                              options={this.state.driverOptions}
                              isDisabled={
                                this.state.isVoucherJob &&
                                !this.state.beneficiaryInformed
                              }
                            />
                          </div>
                          <br className='clearBoth' />
                        </React.Fragment>
                      )}
                    </div>
                    <div className='col-md-6'>
                      <div className='form-group '>
                        <label
                          className='control-label'
                          htmlFor='customers_location'
                        >
                          Select customer's location
                        </label>
                      </div>
                      <p className='selectedLocation'></p>
                      <InnerControlMap
                        lat={this.state.lat}
                        long={this.state.long}
                      />
                    </div>

                    <div className='col-md-12'>
                      <div className='form-group'>
                        <button
                          className='btn btn-primary '
                          name='submit'
                          type='submit'
                          disabled={
                            this.state.isVoucherJob &&
                            !this.state.beneficiaryInformed
                          }
                        >
                          CREATE
                        </button>
                        &nbsp; &nbsp; &nbsp;
                        <button
                          onClick={this.props.close}
                          className='btn btn-default '
                          name='cancel'
                          type='button'
                        >
                          CANCEL
                        </button>
                      </div>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export class ReassignJob extends Component {
  constructor(props) {
    super(props);
    this.state = {
      driverOptions: [],
      av: [],
      selectedOption: null,
      switched: false,
      phone_number: null,
      full_name: null,
      long: null,
      lat: null,
      address: null,
      jobData: null,
      isLoading: false,
      loadingText: 'Loading...',
    };
    this.handleSubmit.bind(this);

    this.handleChangeOnSelect.bind(this);
    this.getJobById.bind(this);
  }
  handleChangeOnSelect(selectedOption) {
    this.setState({ selectedOption });
  }
  close() {
    this.props.close();
  }
  toggleSwitch = () => {
    this.setState((prevState) => {
      return {
        switched: !prevState.switched,
      };
    });
  };

  getJobById(id) {
    const thisApp = this;
    thisApp.setState({
      isLoading: true,
    });
    fetch(addr + 'api/job/' + id, {
      headers: {
        authorization: auth,
      },
    })
      .then((res) => res.json())
      .then(function (data) {
        thisApp.setState({
          isLoading: false,
        });
        thisApp.setState({
          jobData: data,
        });
      })
      .catch(function (error) {
        thisApp.setState({
          isLoading: false,
        });
      });
  }
  handleSubmit(e) {
    const thisApp = this;
    thisApp.setState({
      isLoading: true,
    });
    e.preventDefault();
    let data = {
      _id: this.props._id,
      auto_dispatch: this.state.switched,
      sp: this.state.av,
    };

    // if sidpatch is true
    if (data.auto_dispatch === true && this.state.driverOptions.length > 0) {
      thisApp.setState({
        isLoading: true,
        loadingText: 'Sending automated dispatch',
      });

      let job = {
        _id: data._id,
        lat: this.state.jobData.customer.location.coordinates[1],
        long: this.state.jobData.customer.location.coordinates[0],
      };
      this.findTheNearestSp(job, data.sp);
    } else {
      //require entremprenuer id
      if (this.state.selectedOption === null) {
        return false;
      } else {
        //send add job then send job with device id and job id
        let job = {
          _id: data._id,
        };
        //alert(JSON.stringify(data))
        this.updateDispatch(job, this.state.selectedOption);
      }
    }

    //  this.addNewJob(data);
  }
  findTheNearestSp(job, data) {
    //driverOptions
    const thisApp = this;
    var req = unirest('POST', addr + 'api/sp/nearest');

    req.headers({
      'cache-control': 'no-cache',
      'content-type': 'application/json',
      authorization: auth,
    });
    let datax = {
      coordinates: [job.long, job.lat],
      sps: this.state.av,
    };
    req.send(datax);

    req.end(function (res) {
      if (res.error) {
        thisApp.setState({
          isLoading: false,
        });
      } else {
        if (res.body.length > 0) {
          thisApp.setState({
            isLoading: true,
            loadingText: 'Finding nearest service provider',
          });
          thisApp.updateDispatch(job, res.body[0]);
        } else {
          thisApp.setState({
            isLoading: false,
          });
          alertifyjs.error('There is no service provider near by', 5);
        }
      }
    });
  }

  updateDispatch(job, sp) {
    const thisApp = this;
    thisApp.setState({
      isLoading: true,
      loadingText: 'Finalizing dispatch',
    });
    var req = unirest('PUT', addr + 'api/dispatch/job/' + job._id);

    req.headers({
      'cache-control': 'no-cache',
      'content-type': 'application/x-www-form-urlencoded',
      authorization: auth,
    });
    let dispatchData = {
      ent_id: sp._id,
      dispatch_time: Date.now(),
    };
    req.send(dispatchData);

    req.end(function (res) {
      if (res.error) {
        thisApp.setState({
          isLoading: false,
        });
        alertifyjs.error('Could not save dispatch', 5);
      } else {
        thisApp.sendNotifications(sp.device_id, job._id, sp.userId);
      }
    });
  }

  updateJob(job, callback) {
    var req = unirest('PUT', addr + 'api/job/' + job._id);

    req.headers({
      'cache-control': 'no-cache',
      'content-type': 'application/x-www-form-urlencoded',
      authorization: auth,
    });
    req.send(job);

    req.end(function (res) {
      if (res.error) {
        return callback(res.error);
      } else {
        return callback(null, res.body);
      }
    });
  }

  sendNotifications(device_id, job_id, userId = '') {
    const thisApp = this;
    var req = unirest('POST', addr + 'api/dispatch');

    req.headers({
      'cache-control': 'no-cache',
      'content-type': 'application/x-www-form-urlencoded',
      authorization: auth,
    });

    req.form({
      device_id: device_id,
      job_id: job_id,
      userId: userId,
    });

    req.end(function (res) {
      if (res.error) {
        thisApp.setState({
          isLoading: false,
        });
        alertifyjs.error(
          'Service provider could not receive the job notification',
          5
        );
      } else {
        if (res.body[0].success === 0) {
          thisApp.setState({
            isLoading: false,
          });
          alertifyjs.error(
            'Service provider could not receive the job notification, system is resending',
            5
          );
          thisApp.close();
        } else if (res.body[0].success === true) {
          let job = {
            notification: res.body[0].id,
            _id: job_id,
          };
          thisApp.updateJob(job, function (err, response) {
            if (err) {
              thisApp.setState({
                isLoading: false,
              });
              thisApp.close();
              alertifyjs.error('Notification was not delivered', 2);
            } else {
              thisApp.setState({
                isLoading: false,
              });
              thisApp.close();
              alertifyjs.success(
                'Notification was sent, waiting for confirmation',
                5,
                function () {
                  thisApp.close();
                }
              );
            }
          });
        } else {
        }
      }
    });
  }
  componentDidMount() {
    const thisApp = this;
    const socket = io(addr, {
      reconnection: true,
      transports: ['websocket'],
    });
    let user;

    socket.on('connect', function () {
      socket.on('online users', (data) => {
        let options = [];
        let av = [];
        if (data.length == 0) {
          thisApp.setState({
            selectedOption: null,
          });
        }
        data.forEach((element) => {
          options.push({
            value: element.device_id,
            userId: element.userId,
            device_id: element.device_id,
            _id: element._id,
            label:
              element.full_name +
              ' - ' +
              element.type +
              ' - ' +
              element.phone_number,
          });
          av.push({
            long: element.long,
            lat: element.lat,
            userId: element.userId,
            device_id: element.device_id,
            _id: element._id,
          });
        });

        thisApp.setState({
          driverOptions: options,
          av: av,
        });
      });

      socket.emit('request online', {}, (data) => {
        let options = [];
        let av = [];
        if (data.length == 0) {
          thisApp.setState({
            selectedOption: null,
          });
        }
        data.forEach((element) => {
          options.push({
            value: element.device_id,
            userId: element.userId,
            device_id: element.device_id,
            _id: element._id,
            label:
              element.full_name +
              ' - ' +
              element.type +
              ' - ' +
              element.phone_number,
          });
          av.push({
            long: element.long,
            lat: element.lat,
            userId: element.userId,
            device_id: element.device_id,
            _id: element._id,
          });
        });

        thisApp.setState({
          driverOptions: options,
          av: av,
        });
      });
    });
    this.getJobById(this.props._id);
  }
  render() {
    const { selectedOption } = this.state;
    return (
      <React.Fragment>
        <div className='bootstrap-iso'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-md-12 col-sm-12 col-xs-12'>
                {this.state.isLoading === true ? (
                  <CustomLoading text={this.state.loadingText} />
                ) : (
                  <form
                    onSubmit={(e) => this.handleSubmit(e)}
                    className='col-md-12'
                  >
                    <div className='col-md-12'>
                      <div className='form-group '>
                        <label
                          className='control-label requiredField'
                          htmlFor='phone_number'
                        >
                          Customer Phone Number
                        </label>
                        <p>
                          {this.state.jobData &&
                            this.state.jobData.customer &&
                            this.state.jobData.customer.phone_number}
                        </p>
                      </div>
                      <br className='clearBoth' />
                      <div className='form-group '>
                        <label
                          className='control-label requiredField'
                          htmlFor='email'
                        >
                          Customer Name
                        </label>
                        <p>
                          {this.state.jobData &&
                            this.state.jobData.customer &&
                            this.state.jobData.customer.full_name}
                        </p>
                      </div>

                      <div className='form-group '>
                        <label
                          className='control-label requiredField'
                          htmlFor='select'
                        >
                          Toilet Facility
                        </label>

                        <p>
                          {this.state.jobData &&
                            this.state.jobData.customer &&
                            this.state.jobData.customer.facility_type}
                        </p>
                      </div>

                      <div className='form-group '>
                        <label
                          className='control-label requiredField'
                          htmlFor='select'
                        >
                          Customer location
                        </label>
                        <p>
                          {this.state.jobData &&
                            this.state.jobData.customer &&
                            this.state.jobData.customer.location.address}
                        </p>
                      </div>

                      <br className='clearBoth' />
                      <div className='form-group '>
                        <label
                          className='control-label requiredField'
                          htmlFor='select'
                        >
                          Auto dispatch
                        </label>
                        <br className='clearBoth' />
                        <Switch
                          onClick={this.toggleSwitch}
                          on={this.state.switched}
                        />
                      </div>

                      <br className='clearBoth' />
                      {this.state.switched === false ? (
                        <React.Fragment>
                          <div className='form-group '>
                            <label
                              className='control-label requiredField'
                              htmlFor='phone_number'
                            >
                              Search for available service Providers (
                              {this.state.driverOptions.length})
                              <span className='asteriskField'>*</span>
                            </label>
                            <Select
                              className='e_phone_number'
                              onChange={(e) => this.handleChangeOnSelect(e)}
                              value={selectedOption}
                              placeholder='Select a driver'
                              options={this.state.driverOptions}
                            />
                          </div>
                          <br className='clearBoth' />
                        </React.Fragment>
                      ) : (
                        <React.Fragment></React.Fragment>
                      )}
                    </div>

                    <div className='col-md-12'>
                      <div className='form-group'>
                        <button
                          className='btn btn-primary '
                          name='submit'
                          type='submit'
                        >
                          RE-ASSIGN
                        </button>
                        &nbsp; &nbsp; &nbsp;
                        <button
                          onClick={this.props.close}
                          className='btn btn-default '
                          name='cancel'
                          type='button'
                        >
                          CANCEL
                        </button>
                      </div>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
