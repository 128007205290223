import React, { useState, useMemo } from "react";
import { Grid, Tabs, Tab, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartLine } from "@fortawesome/free-solid-svg-icons";

import moment from "moment";

// Highcharts for time series test
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import Drilldown from "highcharts/modules/drilldown.js";

// Components
import { Layout } from "../../components/inc/Layout";
import { CustomLoading } from "../../components/inc/Layout";
import StatsCard from "../../components/StatsCard/StatsCard";
import Card from "../../components/Card/Card";

// Dumping Charts
import {
  //Volumes
  dumpingVolumeAtSewagePlants,
  dumpingVolumeBySPTypeTimeSeries,
  dumpingVolumeAtSewagePlantsPie,

  //Trips
  trendOfDumpingTrips,
  dumpingTripsRecordedAtSewageLocationsPerDivision,
  dumpingTripsPerDivisionByKCCADrivers,
} from "../../components/Charts/dumpingCharts";

// Emptying Charts
import {
  emptyingTripsPerDivision,
  emptyingTripsOfFSByCesspoolEmptiersVsGulpers,
  emptyingTripsInInformalSettlementsVsOverallPie,
} from "../../components/Charts/emptyingCharts";

// High Maps
import {
  dumpingByDivisionsMap,
  emptyingTripsByParishMap,
  emptyingTripsFromInformalSettlementsMap,
} from "../../components/Charts/highMaps";

// Custom Hooks
import { useGetData } from "../../components/customHooks/tweyonjeDB";

// Utils
import { calculatePercentageDifference } from "../../components/utils/percentageDifferenceCalculator";

require("highcharts/modules/map")(Highcharts);

// Variables
const startOfMonth = moment().startOf("month").format("YYYY-MM-DD");

// const prevMonthFirstDay = moment()
//   .subtract(1, "months")
//   .date(1)
//   .local()
//   .format("YYYY-MM-DD");

const todaysDate = moment().local().format("YYYY-MM-DD");

const initialValue = 0;

export default function Dashboard() {
  const [fromFilter, setFromFilter] = useState(startOfMonth);
  const [toFilter, setToFilter] = useState(todaysDate);

  const [key, setKey] = useState("overview");

  // Data fetching and loading
  const [{ dumps, jobs, jobsCustomerAndSP, isLoading }] = useGetData(
    fromFilter,
    toFilter
  );

  // Charts and Bar Charts
  const [
    chartDumpingVolumeBySPTimeSeries,
    setChartDumpingVolumeBySPTimeSeries,
  ] = useState();

  const [
    pieChartDumpingVolumeAtSewagePlants,
    setPieChartDumpingVolumeAtSewagePlants,
  ] = useState();

  // Dumping charts
  const [chartTrendOfDumpingTrips, setChartTrendOfDumpingTrips] = useState();

  const [
    chartDumpingTripsRecordedAtSewageLocationsPerDivision,
    setChartDumpingTripsRecordedAtSewageLocationsPerDivision,
  ] = useState();

  const [
    chartDumpingVolumeAtSewagePlants,
    setChartDumpingVolumeAtSewagePlants,
  ] = useState();

  const [
    chartDumpingTripsPerDivisionByKCCADrivers,
    setChartDumpingTripsPerDivisionByKCCADrivers,
  ] = useState();

  // Emptying Charts
  const [
    chartEmptyingTripsPerDivision,
    setChartEmptyingTripsPerDivision,
  ] = useState();

  const [
    chartEmptyingTripsOfFSByCesspoolEmptiersVsGulpers,
    setChartEmptyingTripsOfFSByCesspoolEmptiersVsGulpers,
  ] = useState();

  const [
    pieChartEmptyingTripsInformalSettlementsVsOverall,
    setPieChartEmptyingTripsInformalSettlementsVsOverall,
  ] = useState();

  // Calculate Percentage
  const [
    revenuePercentageDifference,
    setRevenuePercentageDifference,
  ] = useState();

  const [
    totalVolumeDumpedPercentageDifference,
    setTotalVolumeDumpedPercentageDifference,
  ] = useState();

  const [
    jobsCompletedPercentageDifference,
    setJobsCompletedPercentageDifference,
  ] = useState();

  const [
    tripsByKCCAPercentageDifference,
    setTripsByKCCAPercentageDifference,
  ] = useState();

  const [
    callCenterReferalsPercentageDifference,
    setCallCenterReferalsPercentageDifference,
  ] = useState();

  const [
    serviceProviderContactsPercentageDifference,
    setServiceProviderContactsPercentageDifference,
  ] = useState();

  const [
    emptyingTripsPercentageDifference,
    setEmptyingTripsPercentageDifference,
  ] = useState();

  const [
    dumpingTripsPercentageDifference,
    setDumpingTripsPercentageDifference,
  ] = useState();

  const [
    dumpingVolumeAtBugolobiPercentageDifference,
    setDumpingVolumeAtBugolobiPercentageDifference,
  ] = useState();

  const [
    dumpingVolumeAtLubigiPercentageDifference,
    setDumpingVolumeAtLubigiPercentageDifference,
  ] = useState();

  // Maps
  const [mapDumpingByDivisions, setMapDumpingByDivisions] = useState();
  const [mapEmptyingByParishes, setEmptyingByParishes] = useState();
  const [
    mapEmptyingInformalSettlementByParishes,
    setMapEmptyingInformalSettlementByParishes,
  ] = useState();

  // Generate Chart's and chart data when data is ready
  useMemo(() => {
    if (dumps && dumps) {
      // Charts
      setChartDumpingVolumeBySPTimeSeries(
        dumpingVolumeBySPTypeTimeSeries(dumps)
      );

      setPieChartDumpingVolumeAtSewagePlants(
        dumpingVolumeAtSewagePlantsPie(dumps)
      );

      setChartDumpingTripsRecordedAtSewageLocationsPerDivision(
        dumpingTripsRecordedAtSewageLocationsPerDivision(dumps)
      );

      setChartTrendOfDumpingTrips(trendOfDumpingTrips(dumps));

      setChartDumpingVolumeAtSewagePlants(dumpingVolumeAtSewagePlants(dumps));

      setChartDumpingTripsPerDivisionByKCCADrivers(
        dumpingTripsPerDivisionByKCCADrivers(dumps)
      );

      setMapDumpingByDivisions(dumpingByDivisionsMap(dumps));

      // Percentage difference
      setTotalVolumeDumpedPercentageDifference(
        calculatePercentageDifference(dumps, "totalVolumeDumped")
      );

      setTripsByKCCAPercentageDifference(
        calculatePercentageDifference(
          dumps,
          "totalNumberOfDumpingTripsByKCCATrucks"
        )
      );

      setDumpingTripsPercentageDifference(
        calculatePercentageDifference(dumps, "totalNumberOfDumpingTrips")
      );

      setDumpingVolumeAtBugolobiPercentageDifference(
        calculatePercentageDifference(dumps, "totalVolumeDumpedAtBugolobi")
      );

      setDumpingVolumeAtLubigiPercentageDifference(
        calculatePercentageDifference(dumps, "totalVolumeDumpedAtLubigi")
      );
    }
  }, [dumps]);

  useMemo(() => {
    if (jobs && jobs) {
      // Charts
      setEmptyingByParishes(emptyingTripsByParishMap(jobs));

      setMapEmptyingInformalSettlementByParishes(
        emptyingTripsFromInformalSettlementsMap(jobs)
      );

      setChartEmptyingTripsPerDivision(emptyingTripsPerDivision(jobs));

      setChartEmptyingTripsOfFSByCesspoolEmptiersVsGulpers(
        emptyingTripsOfFSByCesspoolEmptiersVsGulpers(jobs)
      );

      setPieChartEmptyingTripsInformalSettlementsVsOverall(
        emptyingTripsInInformalSettlementsVsOverallPie(jobs)
      );

      // Percentage difference
      setRevenuePercentageDifference(
        calculatePercentageDifference(jobs, "totalRevenue")
      );

      setJobsCompletedPercentageDifference(
        calculatePercentageDifference(jobs, "totalNumberOfEmptyingTrips")
      );

      setCallCenterReferalsPercentageDifference(
        calculatePercentageDifference(jobs, "callCenterReferals")
      );

      setServiceProviderContactsPercentageDifference(
        calculatePercentageDifference(jobs, "serviceProviderContacts")
      );

      setEmptyingTripsPercentageDifference(
        calculatePercentageDifference(jobs, "totalNumberOfEmptyingTrips")
      );
    }
  }, [jobs]);

  return (
    <Layout>
      <div className="col-md-6 title">
        <h4>
          <FontAwesomeIcon icon={faChartLine} /> Dashboard{" "}
        </h4>
        <br />
        {/* <footer> Detailed Information regarding the top indicators </footer> */}
      </div>
      <div className="col-md-12">
        <form className="form-inline">
          <div className="form-group">
            <label htmlFor="email">From:</label>
            <input
              name="from"
              value={fromFilter}
              onChange={(event) => setFromFilter(event.target.value)}
              className="form-control"
              type="date"
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">To:</label>
            <input
              name="to"
              value={toFilter}
              onChange={(event) => setToFilter(event.target.value)}
              className="form-control"
              type="date"
            />
          </div>
        </form>
      </div>
      <div className="col-md-12" style={{ marginTop: "15px" }}>
        {isLoading ? (
          <CustomLoading text="Loading dashboard data..." />
        ) : (
          <Tabs id="tabs" activeKey={key} onSelect={(key) => setKey(key)}>
            <Tab eventKey="overview" title="OVERVIEW">
              <Grid fluid>
                <Row>
                  <Col lg={3} sm={6}>
                    <StatsCard
                      statsValue={
                        jobs &&
                        new Intl.NumberFormat("lg-UG").format(
                          Math.ceil(
                            jobs
                              .map((j) => j.totalRevenue)
                              .reduce(
                                (accumulator, currentValue) =>
                                  accumulator + currentValue,
                                initialValue
                              )
                          )
                        )
                      }
                      statsText="Total Revenue"
                      statsSymbol="UGX"
                      difference={revenuePercentageDifference}
                    />
                  </Col>
                  <Col lg={3} sm={6}>
                    <StatsCard
                      statsValue={
                        dumps &&
                        new Intl.NumberFormat("lg-UG").format(
                          Math.ceil(
                            dumps
                              .map((d) => d.totalVolumeDumped / 1000)
                              .reduce(
                                (accumulator, currentValue) =>
                                  accumulator + currentValue,
                                initialValue
                              )
                          )
                        )
                      }
                      statsText="Total Volume Dumped"
                      statsSymbol="M"
                      statsExtraSymbol="3"
                      difference={totalVolumeDumpedPercentageDifference}
                    />
                  </Col>
                  <Col lg={3} sm={6}>
                    <StatsCard
                      statsValue={
                        jobsCustomerAndSP &&
                        new Intl.NumberFormat("lg-UG").format(
                          Math.ceil(
                            jobsCustomerAndSP
                              .map((a) => a.count)
                              .reduce(
                                (accumulator, currentValue) =>
                                  accumulator + currentValue,
                                initialValue
                              )
                          )
                        )
                      }
                      statsText="Jobs Completed"
                      difference={jobsCompletedPercentageDifference}
                    />
                  </Col>
                  <Col lg={3} sm={6}>
                    <StatsCard
                      statsValue={
                        dumps &&
                        new Intl.NumberFormat("lg-UG").format(
                          Math.ceil(
                            dumps
                              .map(
                                (a) => a.totalNumberOfDumpingTripsByKCCATrucks
                              )
                              .reduce(
                                (accumulator, currentValue) =>
                                  accumulator + currentValue,
                                initialValue
                              )
                          )
                        )
                      }
                      statsText="Dumping trips by KCCA"
                      difference={tripsByKCCAPercentageDifference}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <Card
                      content={
                        <HighchartsReact
                          highcharts={Drilldown(Highcharts)}
                          options={
                            chartDumpingVolumeBySPTimeSeries &&
                            chartDumpingVolumeBySPTimeSeries
                          }
                        />
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={8}>
                    <Card
                      content={
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={
                            chartDumpingTripsPerDivisionByKCCADrivers &&
                            chartDumpingTripsPerDivisionByKCCADrivers
                          }
                        />
                      }
                    />
                  </Col>
                  <Col md={4}>
                    <Card
                      content={
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={
                            pieChartDumpingVolumeAtSewagePlants &&
                            pieChartDumpingVolumeAtSewagePlants
                          }
                        />
                      }
                    />
                  </Col>
                </Row>
              </Grid>
            </Tab>
            <Tab eventKey="emptying" title="EMPTYING">
              <Grid fluid>
                <Row>
                  <Col lg={3} sm={6}>
                    <StatsCard
                      statsValue={
                        jobsCustomerAndSP &&
                        new Intl.NumberFormat("lg-UG").format(
                          Math.ceil(jobsCustomerAndSP.length)
                        )
                      }
                      statsText="Total Customers"
                      // difference={calculatePercentageDifference(jobsCustomerAndSP)}
                    />
                  </Col>
                  <Col lg={3} sm={6}>
                    <StatsCard
                      statsValue={
                        jobs &&
                        new Intl.NumberFormat("lg-UG").format(
                          Math.ceil(
                            jobs
                              .map((a) => a.callCenterReferals)
                              .reduce(
                                (accumulator, currentValue) =>
                                  accumulator + currentValue,
                                initialValue
                              )
                          )
                        )
                      }
                      statsText="Call Center Refferals"
                      difference={callCenterReferalsPercentageDifference}
                    />
                  </Col>
                  <Col lg={3} sm={6}>
                    <StatsCard
                      statsValue={
                        jobs &&
                        new Intl.NumberFormat("lg-UG").format(
                          Math.ceil(
                            jobs
                              .map((a) => a.serviceProviderContacts)
                              .reduce(
                                (accumulator, currentValue) =>
                                  accumulator + currentValue,
                                initialValue
                              )
                          )
                        )
                      }
                      statsText="Service Provider Contacts"
                      difference={serviceProviderContactsPercentageDifference}
                    />
                  </Col>
                  <Col lg={3} sm={6}>
                    <StatsCard
                      statsValue={
                        jobs &&
                        new Intl.NumberFormat("lg-UG").format(
                          Math.ceil(
                            jobs
                              .map((a) => a.totalNumberOfEmptyingTrips)
                              .reduce(
                                (accumulator, currentValue) =>
                                  accumulator + currentValue,
                                initialValue
                              )
                          )
                        )
                      }
                      statsText="Emptying trips"
                      difference={emptyingTripsPercentageDifference}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} sm={12} xs={12} md={12}>
                    <Card
                      content={
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={
                            chartEmptyingTripsOfFSByCesspoolEmptiersVsGulpers &&
                            chartEmptyingTripsOfFSByCesspoolEmptiersVsGulpers
                          }
                        />
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} sm={12} xs={12} md={12}>
                    <Card
                      content={
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={
                            chartEmptyingTripsPerDivision &&
                            chartEmptyingTripsPerDivision
                          }
                        />
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} sm={12} xs={12} md={12}>
                    <Card
                      content={
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={
                            pieChartEmptyingTripsInformalSettlementsVsOverall &&
                            pieChartEmptyingTripsInformalSettlementsVsOverall
                          }
                        />
                      }
                    />
                  </Col>
                </Row>
              </Grid>
            </Tab>
            <Tab eventKey="dumping" title="DUMPING">
              <Grid fluid>
                <Row>
                  <Col lg={3} sm={6}>
                    <StatsCard
                      statsValue={
                        dumps &&
                        new Intl.NumberFormat("lg-UG").format(
                          Math.ceil(
                            dumps
                              .map((d) => d.totalVolumeDumped / 1000)
                              .reduce(
                                (accumulator, currentValue) =>
                                  accumulator + currentValue,
                                initialValue
                              )
                          )
                        )
                      }
                      statsText="Volume dumped"
                      statsSymbol="M"
                      statsExtraSymbol="3"
                      difference={totalVolumeDumpedPercentageDifference}
                    />
                  </Col>
                  <Col lg={3} sm={6}>
                    <StatsCard
                      statsValue={
                        dumps &&
                        new Intl.NumberFormat("lg-UG").format(
                          Math.ceil(
                            dumps
                              .map((d) => d.totalNumberOfDumpingTrips)
                              .reduce(
                                (accumulator, currentValue) =>
                                  accumulator + currentValue,
                                initialValue
                              )
                          )
                        )
                      }
                      statsText="Dumping trips"
                      difference={dumpingTripsPercentageDifference}
                    />
                  </Col>
                  <Col lg={3} sm={6}>
                    <StatsCard
                      statsValue={
                        dumps &&
                        new Intl.NumberFormat("lg-UG").format(
                          Math.ceil(
                            dumps
                              .map((d) => d.totalVolumeDumpedAtBugolobi / 1000)
                              .reduce(
                                (accumulator, currentValue) =>
                                  accumulator + currentValue,
                                initialValue
                              )
                          )
                        )
                      }
                      statsText="Volume at Bugolobi"
                      statsSymbol="M"
                      statsExtraSymbol="3"
                      difference={dumpingVolumeAtBugolobiPercentageDifference}
                    />
                  </Col>
                  <Col lg={3} sm={6}>
                    <StatsCard
                      statsValue={
                        dumps &&
                        new Intl.NumberFormat("lg-UG").format(
                          Math.ceil(
                            dumps
                              .map((d) => d.totalVolumeDumpedAtLubigi / 1000)
                              .reduce(
                                (accumulator, currentValue) =>
                                  accumulator + currentValue,
                                initialValue
                              )
                          )
                        )
                      }
                      statsText="Volume at Lubigi"
                      statsSymbol="M"
                      statsExtraSymbol="3"
                      difference={dumpingVolumeAtLubigiPercentageDifference}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} sm={12} xs={12} md={12}>
                    <Card
                      content={
                        <HighchartsReact
                          highcharts={Drilldown(Highcharts)}
                          options={
                            chartTrendOfDumpingTrips && chartTrendOfDumpingTrips
                          }
                        />
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} sm={12} xs={12} md={12}>
                    <Card
                      content={
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={
                            chartDumpingVolumeAtSewagePlants &&
                            chartDumpingVolumeAtSewagePlants
                          }
                        />
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} sm={12} xs={12} md={12}>
                    <Card
                      content={
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={
                            chartDumpingTripsRecordedAtSewageLocationsPerDivision &&
                            chartDumpingTripsRecordedAtSewageLocationsPerDivision
                          }
                        />
                      }
                    />
                  </Col>
                </Row>
              </Grid>
            </Tab>
            <Tab eventKey="test" title="MAP VIEW">
              <Grid fluid>
                <Row>
                  <Col md={6}>
                    <Card
                      content={
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={
                            mapDumpingByDivisions && mapDumpingByDivisions
                          }
                          constructorType={"mapChart"}
                        />
                      }
                    />
                  </Col>
                  <Col md={6}>
                    <Card
                      content={
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={
                            mapEmptyingByParishes && mapEmptyingByParishes
                          }
                          constructorType={"mapChart"}
                        />
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Card
                      content={
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={
                            mapEmptyingInformalSettlementByParishes &&
                            mapEmptyingInformalSettlementByParishes
                          }
                          constructorType={"mapChart"}
                        />
                      }
                    />
                  </Col>
                </Row>
              </Grid>
            </Tab>
          </Tabs>
        )}
      </div>
    </Layout>
  );
}
