import { commonChartOptions, commonChartPlotOptions } from "./chartOptions";
// Chart Colors
import { dumpingSiteColors } from "./chartColors";

const voucherStatusPieChart = ({
  pending,
  approvedVouchers,
  declined,
  redeemed,
}) => {
  return {
    chart: {
      // plotBackgroundColor: "red",
      backgroundColor: "rgba(255, 255, 255, 0)",
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
      width: 200,
      height: 200,
    },

    ...commonChartOptions,
    title: {
      text: "",
    },

    tooltip: {
      pointFormat: "{series.name}: <b>{point.percentage:.0f}%</b>",
    },
    plotOptions: {
      pie: {
        size: 180,
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: false,
        },
        // showInLegend: true,
      },
    },
    series: [
      {
        name: "Voucher status",
        colorByPoint: true,
        innerSize: "80%",
        data: [
          {
            name: "Pending",
            y: pending,
            color: "grey",
          },
          {
            name: "Declined",
            y: declined,
            color: "red",
          },
          {
            name: "Redeemed",
            y: redeemed,
            color: "#F7A21A",
          },
          {
            name: "Approved",
            y: approvedVouchers,
            color: "green",
          },
        ],
      },
    ],
  };
};

const divisionAllocationPieChart = ({ vouchersLeft, approvedVouchers }) => {
  return {
    chart: {
      // plotBackgroundColor: "red",
      backgroundColor: "rgba(255, 255, 255, 0)",
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
      width: 200,
      height: 200,
    },

    ...commonChartOptions,
    title: {
      text: "",
    },

    tooltip: {
      pointFormat: "{series.name}: <b>{point.percentage:.0f}%</b>",
    },
    plotOptions: {
      pie: {
        size: 180,
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: false,
        },
        // showInLegend: true,
      },
    },
    series: [
      {
        name: "Voucher status",
        colorByPoint: true,
        innerSize: "80%",
        data: [
          {
            name: "Vouchers left",
            y: vouchersLeft,
            color: "grey",
          },
          {
            name: "Vouchers approved",
            y: approvedVouchers,
            color: "green",
          },
        ],
      },
    ],
  };
};

const paymentsPieChart = ({ paymentsDueAmount, paymentsClearedAmount }) => {
  return {
    chart: {
      // plotBackgroundColor: "red",
      backgroundColor: "rgba(255, 255, 255, 0)",
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
      width: 200,
      height: 200,
    },

    ...commonChartOptions,
    title: {
      text: "",
    },

    tooltip: {
      pointFormat: "{series.name}: <b>{point.percentage:.0f}%</b>",
    },
    plotOptions: {
      pie: {
        size: 180,
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: false,
        },
      },
    },
    series: [
      {
        name: "Payments status",
        colorByPoint: true,
        innerSize: "80%",
        data: [
          {
            name: "Payments due amount",
            y: paymentsDueAmount,
            color: "#ed5548",
          },
          {
            name: "Payments cleared amount",
            y: paymentsClearedAmount,
            color: "#38713c",
          },
        ],
      },
    ],
  };
};

export { voucherStatusPieChart, divisionAllocationPieChart, paymentsPieChart };
