import React, { Component } from "react";
import { Layout } from "./inc/Layout";
import { Button, Panel, NavDropdown, MenuItem } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faCalendar, faTimes } from "@fortawesome/free-solid-svg-icons";
import GoogleMapReact from "google-map-react";
import moment from "moment";
import { AddNewJobForm } from "./Dispatch";
import { Modal } from "react-bootstrap";
const addr = require("../env_config").default;
var unirest = require("unirest");
let div = require("../assets/div.json");

/*
    Image Icons file imports
*/

const customerInProgress = require("../assets/customerInProgress.png");
const customerPending = require("../assets/customerPending.png");
const customerScheduled = require("../assets/customerScheduled.png");
const customerCompleted = require("../assets/customerCompleted.png");

const spInProgress = require("../assets/spInprogress.png");
const spPending = require("../assets/spPending.png");
const spScheduled = require("../assets/spScheduled.png");
const spCompleted = require("../assets/spCompleted.png");

/*

End of image icons update

*/

const gSession = window.sessionStorage;
const token = gSession.getItem("wat");
const loggedInId = gSession.getItem("wai");

export class LiveTracking extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      selectedText: "Inprogress",
      Inprogress: [],
      selected: [],
      show: false,
    };
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.getInProgress = this.getInProgress.bind(this);
    this.getPending = this.getPending.bind(this);
    this.getScheduled = this.getScheduled.bind(this);
    this.getCompleted = this.getCompleted.bind(this);
    this.loadDetails = this.loadDetails.bind(this);
    this.closeDetails = this.closeDetails.bind(this);
    this.updateDD = this.updateDD.bind(this);
    this.updateSelected = this.updateSelected.bind(this);
  }
  componentDidMount() {
    const thisApp = this;
    setInterval(function() {
      thisApp.getInProgress("reload");
      thisApp.getPending();
      thisApp.getScheduled();
      thisApp.getCompleted();
      thisApp.updateSelected(thisApp.state.selectedText);
    }, 1000 * 60);

    this.getInProgress();
    this.getPending();
    this.getScheduled();
    this.getCompleted();
  }

  getInProgress(status = "") {
    const thisApp = this;

    var req = unirest("GET", addr + "api/jobs/inprogress");

    req.headers({
      "cache-control": "no-cache",
      authorization: token,
    });

    req.end(function(res) {
      if (res.error) console.log(res);

      if (res.status == 200) {
        let x = [];
        let q = x.push(res.body);
        if (status === "reload") {
          thisApp.setState(
            {
              Inprogress: res.body.reverse(),
              //selected: res.body,
              isLoaded: true,
            },
          );
        } else {
          thisApp.setState(
            {
              Inprogress: res.body.reverse(),
              selected: res.body,
              isLoaded: true,
            },
          );
        }
      } else {
        thisApp.setState(
          {
            isLoaded: false,
          },
        );
      }
    });
  }
  getPending() {
    const thisApp = this;

    var req = unirest("GET", addr + "api/jobs/pending");

    req.headers({
      "cache-control": "no-cache",
      authorization: token,
    });

    req.end(function(res) {
      if (res.error) console.log(res);

      if (res.status == 200) {
        thisApp.setState(
          {
            Pending: res.body.reverse(),
            isLoaded: true,
          },
        );
      } else {
        console.log(res.body);
        thisApp.setState(
          {
            isLoaded: false,
          },
        );
      }
    });
  }

  getScheduled() {
    const thisApp = this;
    let status;
    var req = unirest("GET", addr + "api/jobs/scheduled");

    req.headers({
      "cache-control": "no-cache",
      authorization: token,
    });

    req.end(function(res) {
      if (res.error) console.log(res);

      if (res.status == 200) {
        let x = [];
        let q = x.push(res.body);
        thisApp.setState(
          {
            Scheduled: res.body.reverse(),
            isLoaded: true,
          },
        );
      } else {
        thisApp.setState(
          {
            isLoaded: false,
          },
        );
      }
    });
  }
  getCompleted() {
    const thisApp = this;
    let status;
    var req = unirest("GET", addr + "api/jobs/completed");

    req.headers({
      "cache-control": "no-cache",
      authorization: token,
    });

    req.end(function(res) {
      if (res.error) console.log(res);

      if (res.status == 200) {
        let x = [];
        let q = x.push(res.body);
        thisApp.setState(
          {
            Completed: res.body.reverse(),
            isLoaded: true,
          },
        );
      } else {
        thisApp.setState(
          {
            isLoaded: false,
          },
        );
      }
    });
  }
  loadDetails(job, e) {
    this.setState(
      {
        showMore: false,
      },
      function() {
        this.setState(
          {
            details: job,
            showMore: true,
          },
         
        );
      }
    );
  }
  updateDD(distance, duration) {
    this.setState(
      {
        duration: duration,
        distance: distance,
      },
     
    );
  }
  closeDetails() {
    this.setState({
      showMore: false,
    });
  }
  updateSelected(selected, e) {
    this.setState({
      selected: this.state[selected],
      selectedText: selected,
      showMore: false,
    });
  }
  handleClose() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }
  render() {
    return (
      <Layout>
        <div className="col-md-8 title">
          <h4>
            {" "}
            <FontAwesomeIcon icon={faCalendar} /> Live tracking{" "}
            <span className="badge">Today</span>
          </h4>
        </div>
        <div className="col-md-4">
          <Button onClick={this.handleShow} bsStyle="primary pull-right">
            <FontAwesomeIcon icon={faPlus} /> Create Dispatch
          </Button>
        </div>
        <div className="col-md-12" style={{ marginBottom: "15px" }}>
          <Cards
            onClick={(e) => this.updateSelected("Scheduled", e)}
            type="scheduled"
            title={this.state.Scheduled && this.state.Scheduled.length}
            details="Scheduled"
          />
          <Cards
            onClick={(e) => this.updateSelected("Pending", e)}
            type="pending"
            title={this.state.Pending && this.state.Pending.length}
            details="Pending"
          />
          <Cards
            onClick={(e) => this.updateSelected("Inprogress", e)}
            type="inprogress"
            title={this.state.Inprogress && this.state.Inprogress.length}
            details="In progress"
          />
          <Cards
            onClick={(e) => this.updateSelected("Completed", e)}
            type="completed"
            title={this.state.Completed && this.state.Completed.length}
            details="Completed"
          />
        </div>
        <div className="col-md-12">
          <div className="col-md-4 sectionList">
            <ListSection
              updateSelected={(selected, e) => this.updateSelected(selected, e)}
              selectedText={this.state.selectedText}
              loadDetails={(job, e) => this.loadDetails(job, e)}
              data={this.state.selected && this.state.selected}
            />
          </div>
          {this.state.showMore == true ? (
            <React.Fragment>
              <div className="col-md-3 sectionDetails">
                <DetailSection
                  distance={this.state.distance && this.state.distance}
                  duration={this.state.duration && this.state.duration}
                  closeDetails={this.closeDetails}
                  data={this.state.details && this.state.details}
                />
              </div>
              <div className="col-md-5">
                <div style={{ height: "65vh", width: "auto" }}>
                  <MapContainer
                    selectedText={this.state.selectedText}
                    updateDD={this.updateDD}
                    data={this.state.details && this.state.details}
                  />
                </div>
              </div>
            </React.Fragment>
          ) : (
            <div className="col-md-8">
              <div style={{ height: "65vh", width: "auto" }}>
                <MapContainerAll
                  class={this.state.selectedText}
                  data={this.state.selected && this.state.selected}
                />
              </div>
            </div>
          )}
        </div>
        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Add a job dispatch</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AddNewJobForm close={this.handleClose} />
          </Modal.Body>
        </Modal>
      </Layout>
    );
  }
}

class Cards extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: "",
    };
    this.showSelected = this.showSelected.bind(this);
  }
  componentDidMount() {}
  showSelected() {
    //active on all
    this.props.onClick();
  }
  render() {
    return (
      <Panel onClick={this.showSelected} className={"card clickable col-md-2"}>
        <Panel.Body className={this.props.type}>
          <h5>{this.props.title}</h5>
          <p>{this.props.details}</p>
        </Panel.Body>
      </Panel>
    );
  }
}

Cards.defaultProps = {
  title: 0,
  details: "Details",
};

class ListSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: "In Progress",
    };
    this.loadDetails = this.loadDetails.bind(this);
    this.filter = this.filter.bind(this);
  }
  loadDetails(job, e) {
    this.props.loadDetails(job, e);
  }
  filter(e, name) {
    this.setState({
      filter: name,
    });
    this.props.updateSelected(name);
  }
  render() {
    let jobs;
    jobs =
      this.props.data &&
      this.props.data.map((job) => (
        <Panel onClick={(e) => this.loadDetails(job, e)} className="marginTop" key={job._id}>
          <Panel.Body>
            <div className="section">
              {/*  moment(element.start_time).format('Do MMMM YYYY, h:mm:ss a') */}
              <h5>
                CUSTOMER{" "}
                <span className="pull-right">
                  {moment(
                    job.date_of_registration && job.date_of_registration
                  ).format("h:mm:ss a")}
                </span>
              </h5>
              <p>
                {job.customers
                  ? job.customers.full_name
                  : job.first_name && job.first_name + " " + job.last_name}
              </p>
            </div>
            <div className="section">
              <h5>SERVICE PROVIDER</h5>
              <p>
                {job.entreprenuer &&
                job.entreprenuer.full_name &&
                job.entreprenuer.full_name
                  ? job.entreprenuer.full_name
                  : job.entreprenuer.first_name + " " + job.entreprenuer &&
                    job.entreprenuer.last_name}{" "}
                - {job.entreprenuer && job.entreprenuer.phone_number}
              </p>
            </div>
            <div className="section">
              <h5>JOB LOCATION</h5>
              <p>
                {job.customers && job.customers.location
                  ? job.customers.location.address +
                    " - " +
                    job.customers.location.division +
                    " Division"
                  : job.address && job.address}
              </p>
            </div>
          </Panel.Body>
        </Panel>
      ));
    return (
      <div>
        <div className="col-md-12">
          <h4 className="page-header">
            {this.props.selectedText === "Inprogress"
              ? "In progress"
              : this.props.selectedText}
          </h4>
        </div>
        <div className="col-md-12 padding-normal">
          <NavDropdown
            eventKey={3}
            className="pull-right"
            title={
              this.props.selectedText === "Inprogress"
                ? "In progress"
                : this.props.selectedText
            }
            id="basic-nav-dropdown"
          >
            <MenuItem
              onClick={(e) => this.filter(e, "Inprogress")}
              eventKey={3.1}
            >
              In Progress
            </MenuItem>
            <MenuItem onClick={(e) => this.filter(e, "Pending")} eventKey={3.2}>
              Pending
            </MenuItem>
            <MenuItem
              onClick={(e) => this.filter(e, "Scheduled")}
              eventKey={3.3}
            >
              Scheduled
            </MenuItem>
            <MenuItem divider />
            <MenuItem
              onClick={(e) => this.filter(e, "Completed")}
              eventKey={3.4}
            >
              Completed
            </MenuItem>
          </NavDropdown>
        </div>
        <br />
        {this.props.data && this.props.data.length > 0 ? (
          jobs
        ) : (
          <h5>
            <span className="label label-default">
              There are no jobs in the current state at the moment.
            </span>
          </h5>
        )}
      </div>
    );
  }
}

class DetailSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.closeDetails = this.closeDetails.bind(this);
  }
  closeDetails() {
    this.props.closeDetails(false);
  }

  render() {
    //let distance = this.getDistance()
    return (
      <div>
        <Panel className="marginTop">
          <Panel.Title>
            JOB INFORMATION{" "}
            <Button onClick={this.closeDetails} bsStyle="default">
              <FontAwesomeIcon icon={faTimes} />
            </Button>{" "}
          </Panel.Title>
          <Panel.Body>
            <div className="section">
              <h5>
                TRAVEL TIME{" "}
                <span className="pull-right">
                  {this.props.duration && this.props.duration.text}
                </span>
              </h5>
            </div>
            <div className="section">
              <h5>
                TRAVEL DISTANCE{" "}
                <span className="pull-right">
                  {this.props.distance && this.props.distance.text}
                </span>
              </h5>
            </div>
            <div className="section">
              <h5>
                Start{" "}
                <span className="pull-right">
                  {moment(this.props.data.date_of_registration).format(
                    "h:mm:ss a"
                  )}
                </span>
              </h5>
            </div>
            <div className="section">
              <h5>
                FACILITY TYPE{" "}
                <span className="pull-right">
                  {this.props.data.customers && this.props.data.customers
                    ? this.props.data.customers.facility_type
                    : this.props.data.facility_type}
                </span>
              </h5>
            </div>
            <div className="locationView">
              <div className="illustration"></div>
              <div className="info"></div>
              <div className="section">
                <h5>DESTINATION </h5>
                <p>
                  {this.props.data.address
                    ? this.props.data.address
                    : this.props.data.customers &&
                      this.props.data.customers.location.address}
                </p>
              </div>
            </div>
            <div className="section">
              <h5>
                AMOUNT RECEIVED:{" "}
                <span className="pull-right">
                  {this.props.data.income_recieved &&
                  this.props.data.income_recieved
                    ? this.props.data.income_recieved
                    : "n/a"}
                </span>
              </h5>
            </div>
            <div className="section">
              <h5>
                INITIATED BY:{" "}
                <span className="pull-right">
                  {this.props.data.source &&
                  this.props.data.source.from === "Driver"
                    ? "Service provider"
                    : "KCCA call center"}
                </span>
              </h5>
              <hr />
            </div>
          </Panel.Body>
        </Panel>
      </div>
    );
  }
}
//AIzaSyCBIkDJLaKO6FAioip7OsDUsOYVO5p33C4

class MapContainerAll extends React.Component {
  constructor(props) {
    super(props);

    this.apiIsLoaded = this.apiIsLoaded.bind(this);
  }
  static defaultProps = {
    center: { lat: 0.3476, lng: 32.5825 },
    zoom: 11,
  };
  handleIconTypes(type) {
    let icon;
    if (type === "Inprogress") {
      return (icon = spInProgress);
    } else if (type === "Pending") {
      return (icon = spPending);
    } else if (type === "Scheduled") {
      return (icon = spScheduled);
    } else if (type === "Completed") {
      return (icon = spCompleted);
    }
  }
  apiIsLoaded(map, maps) {
    if (map) {
      var infowindow = new maps.InfoWindow();
      map.data.loadGeoJson(addr + "assets/parishes.json");
      map.data.setStyle({
        fillColor: "rgba(70, 161, 66, 0.1)",
        strokeWeight: 2,
        strokeColor: "#214e1f",
      });
      map.data.addListener("mouseover", function(event) {
        map.data.revertStyle();
        map.data.overrideStyle(event.feature, {
          strokeWeight: 3,
          fillColor: "rgba(255, 224, 61,0.3)",
        });
      });

      map.data.addListener("click", function(event) {
        let place = event.feature.getProperty("PNAME2014");
        let html = place; // combine place and magnitude, inserting additional text between them
        infowindow.setContent(html); // show the html variable in the infowindow
        infowindow.setPosition(
          event.feature
            .getGeometry()
            .getAt(0)
            .getAt(0)
        ); // anchor the infowindow at the marker
        infowindow.setOptions({ pixelOffset: new maps.Size(0, -30) });
        infowindow.open(map);
      });

      map.data.addListener("mouseout", function(event) {
        map.data.revertStyle();
      });
    }
  }
  handleIconTypec(type) {
    let icon;
    if (type === "Inprogress") {
      return (icon = customerInProgress);
    } else if (type === "Pending") {
      return (icon = customerPending);
    } else if (type === "Scheduled") {
      return (icon = customerScheduled);
    } else if (type === "Completed") {
      return (icon = customerCompleted);
    }
  }

  render() {
    let jobs;
    jobs =
      this.props.data &&
      this.props.data.map((job) => (
        <AnyReactComponent
          lat={
            job.customers
              ? job.customers.location.coordinates[1]
              : job.lat && job.lat
          }
          lng={
            job.customers
              ? job.customers.location.coordinates[0]
              : job.long && job.long
          }
          text={"C"}
          type={this.handleIconTypec(this.props.class)}
          key={job._id}
        />
      ));
    return (
      <GoogleMapReact
        defaultCenter={this.props.center}
        defaultZoom={this.props.zoom}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => this.apiIsLoaded(map, maps)}
        bootstrapURLKeys={{ key: "AIzaSyCBIkDJLaKO6FAioip7OsDUsOYVO5p33C4" }}
      >
        {jobs}
      </GoogleMapReact>
    );
  }
}

let or;
let de;
let or_long;
let or_lat;
let entreprenuer_long;
let entreprenuer_lat;
let status;
let customer_long;
let customer_lat;
class MapContainer extends React.Component {
  static defaultProps = {
    center: { lat: 0.3476, lng: 32.5825 },
    zoom: 11,
  };
  constructor(props) {
    super(props);
    this.state = {
      duration: "",
      distance: "",
    };
    this.calculateAndDisplayRoute = this.calculateAndDisplayRoute.bind(this);
  }

  apiIsLoaded(map, maps) {
    status = this.props.data.status;
    or_long =
      this.props.data.start_point && this.props.data.start_point.coordinates[0];

    or_lat =
      this.props.data.start_point && this.props.data.start_point.coordinates[1];

    customer_long = this.props.data.customers
      ? this.props.data.customers.location.coordinates[0]
      : this.props.data.long && this.props.data.long;
    customer_lat = this.props.data.customers
      ? this.props.data.customers.location.coordinates[1]
      : this.props.data.lat && this.props.data.lat;

    entreprenuer_long =
      this.props.data.entreprenuer &&
      this.props.data.entreprenuer.last_seen.location &&
      this.props.data.entreprenuer.last_seen.location.coordinates &&
      this.props.data.entreprenuer.last_seen.location.coordinates[0] &&
      this.props.data.entreprenuer.last_seen.location.coordinates[0]
        ? this.props.data.entreprenuer.last_seen.location.coordinates[0]
        : this.props.data.entreprenuer.last_seen.location &&
          this.props.data.entreprenuer.last_seen.location.coordinates.long &&
          this.props.data.entreprenuer.last_seen.location.coordinates.long;

    entreprenuer_lat =
      this.props.data.entreprenuer &&
      this.props.data.entreprenuer.last_seen.location &&
      this.props.data.entreprenuer.last_seen.location.coordinates &&
      this.props.data.entreprenuer.last_seen.location.coordinates[1] &&
      this.props.data.entreprenuer.last_seen.location.coordinates[1]
        ? this.props.data.entreprenuer.last_seen.location.coordinates[1]
        : this.props.data.entreprenuer.last_seen.location &&
          this.props.data.entreprenuer.last_seen.location.coordinates.lat &&
          this.props.data.entreprenuer.last_seen.location.coordinates.lat;

    if (
      map &&
      this.props.selectedText != "Pending" &&
      this.props.selectedText != "Scheduled"
    ) {
      var directionsService = new maps.DirectionsService();
      var directionsDisplay = new maps.DirectionsRenderer();
      // var geometry = new maps.geometry;
      //  or = new maps.LatLng(or_lat, or_long);
      // alert(or_lat+ " ----  "+or_long);
      or = new maps.LatLng(or_lat, or_long);
      de = new maps.LatLng(customer_lat, customer_long);
      // var distance = geometry.spherical.computeDistanceBetween(or, de);
      // by default distance is in meters
      //  distance = Math.round(distance/ 1000) + "Km";
      // console.log(distance);
      this.calculateAndDisplayRoute(directionsService, directionsDisplay);
      directionsDisplay.setMap(map);

      // do your thing with map or maps
    }
    if (map) {
      var infowindow = new maps.InfoWindow();
      map.data.loadGeoJson(addr + "assets/parishes.json");
      map.data.setStyle({
        fillColor: "rgba(70, 161, 66, 0.1)",
        strokeWeight: 2,
        strokeColor: "#214e1f",
      });
      map.data.addListener("mouseover", function(event) {
        map.data.revertStyle();
        map.data.overrideStyle(event.feature, {
          strokeWeight: 3,
          fillColor: "rgba(255, 224, 61,0.3)",
        });
      });

      map.data.addListener("click", function(event) {
        let place = event.feature.getProperty("PNAME2014");
        let html = place; // combine place and magnitude, inserting additional text between them
        infowindow.setContent(html); // show the html variable in the infowindow
        infowindow.setPosition(
          event.feature
            .getGeometry()
            .getAt(0)
            .getAt(0)
        ); // anchor the infowindow at the marker
        infowindow.setOptions({ pixelOffset: new maps.Size(0, -30) });
        infowindow.open(map);
      });

      map.data.addListener("mouseout", function(event) {
        map.data.revertStyle();
      });
    }
  }
  calculateAndDisplayRoute(directionsService, directionsDisplay) {
    const thisApp = this;
    let waypts;

    status <= 2
      ? (waypts = [
          {
            //entreprenuer_lat, entreprenuer_long
            location: { lat: entreprenuer_lat, lng: entreprenuer_long },
            stopover: true,
          },
        ])
      : (waypts = [
          {
            //entreprenuer_lat, entreprenuer_long
            location: { lat: customer_lat, lng: customer_long },
            stopover: true,
          },
        ]);

    directionsService.route(
      {
        origin: or,
        waypoints: waypts,
        optimizeWaypoints: true,
        destination: de,
        travelMode: "DRIVING",
      },
      function(response, status) {
        if (status === "OK") {
          console.log(response.routes[0].legs[0].distance);
          console.log(response.routes[0].legs[0].duration);
          thisApp.props.updateDD(
            response.routes[0].legs[0].distance,
            response.routes[0].legs[0].duration
          );

          directionsDisplay.setDirections(response);
        } else {
          console.log("Directions request failed due to " + status);
        }
      }
    );
  }
  handleIconTypes(type) {
    let icon;
    if (type === "Inprogress") {
      return (icon = spInProgress);
    } else if (type === "Pending") {
      return (icon = spPending);
    } else if (type === "Scheduled") {
      return (icon = spScheduled);
    } else if (type === "Completed") {
      return (icon = spCompleted);
    }
  }
  handleIconTypec(type) {
    let icon;
    if (type === "Inprogress") {
      return (icon = customerInProgress);
    } else if (type === "Pending") {
      return (icon = customerPending);
    } else if (type === "Scheduled") {
      return (icon = customerScheduled);
    } else if (type === "Completed") {
      return (icon = customerCompleted);
    }
  }

  render() {
    let customer_long = this.props.data.customers
      ? this.props.data.customers.location.coordinates[0]
      : this.props.data.long && this.props.data.long;
    let customer_lat = this.props.data.customers
      ? this.props.data.customers.location.coordinates[1]
      : this.props.data.lat && this.props.data.lat;

    let entreprenuer_long =
      this.props.data.entreprenuer &&
      this.props.data.entreprenuer.last_seen.location &&
      this.props.data.entreprenuer.last_seen.location.coordinates &&
      this.props.data.entreprenuer.last_seen.location.coordinates[0] &&
      this.props.data.entreprenuer.last_seen.location.coordinates[0]
        ? this.props.data.entreprenuer.last_seen.location.coordinates[0]
        : this.props.data.entreprenuer.last_seen.location &&
          this.props.data.entreprenuer.last_seen.location.coordinates.long &&
          this.props.data.entreprenuer.last_seen.location.coordinates.long;

    let entreprenuer_lat =
      this.props.data.entreprenuer &&
      this.props.data.entreprenuer.last_seen.location &&
      this.props.data.entreprenuer.last_seen.location.coordinates &&
      this.props.data.entreprenuer.last_seen.location.coordinates[1] &&
      this.props.data.entreprenuer.last_seen.location.coordinates[1]
        ? this.props.data.entreprenuer.last_seen.location.coordinates[1]
        : this.props.data.entreprenuer.last_seen.location &&
          this.props.data.entreprenuer.last_seen.location.coordinates.lat &&
          this.props.data.entreprenuer.last_seen.location.coordinates.lat;

    return (
      <GoogleMapReact
        defaultCenter={this.props.center}
        defaultZoom={this.props.zoom}
        libraries="geometry"
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => this.apiIsLoaded(map, maps)}
        bootstrapURLKeys={{ key: "AIzaSyCBIkDJLaKO6FAioip7OsDUsOYVO5p33C4" }}
      >
        <AnyReactComponent
          lat={customer_lat}
          lng={customer_long}
          type={this.handleIconTypec(this.props.selectedText)}
        />

        {this.props.selectedText === "Pending" ||
        this.props.selectedText === "Completed" ||
        this.props.selectedText === "Scheduled" ? (
          <React.Fragment></React.Fragment>
        ) : (
          <AnyReactComponent
            lat={entreprenuer_lat}
            lng={entreprenuer_long}
            type={this.handleIconTypes(this.props.selectedText)}
          />
        )}
      </GoogleMapReact>
    );
  }
}

const AnyReactComponent = ({ text, className, type }) => (
  <div className="iconWrapper">
    <img src={type}></img>
  </div>
);
