import React, { useState, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Table } from "react-bootstrap";
import { paymentsPieChart } from "../Charts/voucherCharts";
import "../../styles/voucher.css";

const gSession = window.sessionStorage;
const divisionName = gSession.getItem("division");

function VoucherOverviewCard({ vouchers, isAdmin, division, voucherProgram }) {
  const {
    count,
    approvedVouchers,
    maxVouchersPerMonth,
    pending,
    declined,
    redeemed,
    paymentsDue,
    paymentsDueAmount,
    paymentsCleared,
    paymentsClearedAmount,
    data: voucherData,
  } = vouchers;

  const { data: voucherProgramData } = voucherProgram;

  const divisionData = voucherProgramData
    ?.filter((program) => program.isActive === true)
    ?.map((program) => {
      const result = {
        totalCompleted: voucherData.filter(
          (v) =>
            v.jobStatus === 1 &&
            v.status === 6 &&
            v.voucherProgram === program.programName
        ).length,
        totalGulpersComplete: voucherData.filter(
          (v) =>
            v.spType === "Gulper" &&
            v.status === 6 &&
            v.jobStatus === 1 &&
            v.voucherProgram === program.programName
        ).length,
        totalEmptiersComplete: voucherData.filter(
          (v) =>
            v.spType === "Emptier" &&
            v.status === 6 &&
            v.jobStatus === 1 &&
            v.voucherProgram === program.programName
        ).length,
        totalVouchersAvailable: program.totalVouchersAvailable,
        programName: program.programName,
        isActive: program.isActive,
        defaultVoucherValidity: program.defaultVoucherValidity,
        defaultVoucherValue: program.defaultVoucherValue,
        division: {
          ...(isAdmin
            ? [program.divisions]
            : program.divisions.find((d) => d.divisionName === divisionName)),
          totalGulpersComplete: voucherData.filter(
            (v) =>
              v.spType === "Gulper" &&
              v.status === 6 &&
              v.jobStatus === 1 &&
              v.validForDivision === divisionName &&
              v.voucherProgram === program.programName
          ).length,
          totalGulpers: voucherData.filter(
            (v) =>
              v.spType === "Gulper" &&
              v.jobStatus === 1 &&
              v.validForDivision === divisionName &&
              v.voucherProgram === program.programName
          ).length,
          totalEmptiersComplete: voucherData.filter(
            (v) =>
              v.spType === "Emptier" &&
              v.status === 6 &&
              v.jobStatus === 1 &&
              v.validForDivision === divisionName &&
              v.voucherProgram === program.programName
          ).length,
          totalEmptiers: voucherData.filter(
            (v) =>
              v.spType === "Emptier" &&
              v.jobStatus === 1 &&
              v.validForDivision === divisionName &&
              v.voucherProgram === program.programName
          ).length,
          completed: voucherData.filter(
            (v) =>
              v.status === 6 &&
              v.jobStatus === 1 &&
              v.validForDivision === divisionName &&
              v.voucherProgram === program.programName
          ).length,
        },
      };

      return result;
    });

  const [paymentsPie, setPaymentsPie] = useState();

  const vouchersLeft =
    (maxVouchersPerMonth && maxVouchersPerMonth) -
    (approvedVouchers && approvedVouchers);

  useEffect(() => {
    setPaymentsPie(
      paymentsPieChart({ paymentsDueAmount, paymentsClearedAmount })
    );
  }, [
    count,
    approvedVouchers,
    maxVouchersPerMonth,
    pending,
    declined,
    redeemed,
    paymentsDue,
    paymentsDueAmount,
    paymentsCleared,
    paymentsClearedAmount,
    vouchersLeft,
  ]);

  return (
    <div className="voucher-stats-container">
      <div
        className="col-lg-12 col-sm-12"
        style={{
          display: "flex",
          margin: "auto",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Table responsive striped bordered hover style={{ fontSize: 18 }}>
          <thead style={{ fontWeight: "normal" }}>
            <tr>
              <th>Program</th>
              <th
                style={{
                  backgroundColor: "#337ab7",
                  color: "#b4d4f1",
                  fontWeight: "bolder",
                }}
              >
                Vouchers
              </th>
              <th
                style={{
                  backgroundColor: "#e5f0eb",
                  color: "#38713c",
                  fontWeight: "bolder",
                }}
              >
                Approved
              </th>
              <th
                style={{
                  backgroundColor: "#fceae9",
                  color: "#ee5122",
                  fontWeight: "bolder",
                }}
              >
                Declined
              </th>

              <th
                style={{
                  backgroundColor: "#f0f0f1",
                  fontWeight: "bolder",
                  color: "#6e6e73",
                }}
              >
                Gulper Completed
              </th>
              <th
                style={{
                  backgroundColor: "#f0f0f1",
                  fontWeight: "bolder",
                  color: "#6e6e73",
                }}
              >
                Cesspol Completed
              </th>
              <th
                style={{
                  backgroundColor: "#fef5e8",
                  fontWeight: "bolder",
                  color: "#f7a21a",
                }}
              >
                Completed
              </th>
              <th
                style={{
                  backgroundColor: "#f0f0f1",
                  fontWeight: "bolder",
                  color: "#6e6e73",
                }}
              >
                Remaining
              </th>
            </tr>
          </thead>

          <tbody>
            {divisionData?.map((p) => {
              return (
                <tr
                  key={p.programName}
                  style={{ fontSize: 15, textAlign: "center" }}
                >
                  <td style={{ fontSize: 15, textAlign: "left" }}>
                    {p.programName}
                  </td>
                  <td
                    style={{
                      backgroundColor: "#337ab7",
                      color: "#b4d4f1",
                      fontWeight: "bolder",
                    }}
                  >
                    {isAdmin
                      ? p.totalVouchersAvailable
                      : p.division.maxVouchers}
                  </td>
                  <td
                    style={{
                      backgroundColor: "#e5f0eb",
                      color: "#38713c",
                      fontWeight: "bolder",
                    }}
                  >
                    {isAdmin
                      ? p.division[0]
                          ?.map((d) => d.approvedVouchers)
                          ?.reduce((a, b) => a + b, 0)
                      : p.division.approvedVouchers}
                  </td>
                  <td
                    style={{
                      backgroundColor: "#fceae9",
                      color: "#ee5122",
                      fontWeight: "bolder",
                    }}
                  >
                    {isAdmin
                      ? p.division[0]
                          .map((d) => d.declinedVouchers)
                          .reduce((a, b) => a + b, 0)
                      : p.division.declinedVouchers}
                  </td>

                  <td
                    style={{
                      backgroundColor: "#f0f0f1",
                      fontWeight: "bolder",
                      color: "#6e6e73",
                    }}
                  >
                    {isAdmin
                      ? p.totalGulpersComplete
                      : p.division.totalGulpersComplete}
                  </td>
                  <td
                    style={{
                      backgroundColor: "#f0f0f1",
                      fontWeight: "bolder",
                      color: "#6e6e73",
                    }}
                  >
                    {isAdmin
                      ? p.totalEmptiersComplete
                      : p.division.totalEmptiersComplete}
                  </td>

                  <td
                    style={{
                      backgroundColor: "#fef5e8",
                      fontWeight: "bolder",
                      color: "#f7a21a",
                    }}
                  >
                    {isAdmin ? p.totalCompleted : p.division.completed}
                  </td>
                  <td
                    style={{
                      backgroundColor: "#f0f0f1",
                      fontWeight: "bolder",
                      color: "#6e6e73",
                    }}
                  >
                    {isAdmin
                      ? p.division[0]
                          .map((d) => d.maxVouchers)
                          .reduce((a, b) => a + b, 0) -
                        p.division[0]
                          .map((d) => d.approvedVouchers)
                          .reduce((a, b) => a + b, 0)
                      : p.division.maxVouchers - p.division.approvedVouchers}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>

      {
        isAdmin && (
          <>
            <div
              className="col-lg-4 col-sm-12"
              style={{
                display: "flex",
                borderLeft: "4px solid #38713c",
                margin: "auto",
              }}
            >
              <div className="voucher-payments-graph">
                <div className="voucher-payments-graph-container">
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={paymentsPie && paymentsPie}
                  />
                </div>
              </div>
              <div className="voucher-payments" style={{ margin: "auto" }}>
                <div className="voucher-payments-row">
                  <span className="payment due">{paymentsDue}</span>
                  <span className="payment-description due">
                    <p>Payments due</p>
                    <p>
                      UGX{"  "}
                      {new Intl.NumberFormat("lg-UG").format(paymentsDueAmount)}
                    </p>
                  </span>
                </div>

                <div className="voucher-payments-row">
                  <span className="payment cleared">{paymentsCleared}</span>
                  <span className="payment-description cleared">
                    <p>Payments cleared</p>
                    <p>
                      UGX{"  "}
                      {new Intl.NumberFormat("lg-UG").format(
                        paymentsClearedAmount
                      )}
                    </p>
                  </span>
                </div>

                {/* <div className="voucher-payments-row">
                <span className="payment overdue">{pending}</span>
                <span className="payment-description overdue">
                  <p>Payments overdue</p>
                  <p>UGX 3,900,000</p>
                </span>
              </div> */}
              </div>
            </div>
          </>
        )

        // : (
        //   <div
        //     className="col-lg-4 col-sm-12"
        //     style={{ display: "flex", margin: "auto" }}
        //   >
        //     <div className="voucher-stats-graph">
        //       <div className="voucher-stats-graph-container">
        //         <HighchartsReact
        //           highcharts={Highcharts}
        //           options={divisionAllocationPie && divisionAllocationPie}
        //         />
        //       </div>
        //     </div>
        //     <div className="voucher-stats" style={{ margin: "auto" }}>
        //       <div className="voucher-stats-row">
        //         <span className="stat total">
        //           {maxVouchersPerMonth && maxVouchersPerMonth}
        //         </span>
        //         <span className="description total">
        //           Voucher allocation for {division}
        //         </span>
        //       </div>

        //       <div className="voucher-stats-row">
        //         <span className="stat approved">
        //           {approvedVouchers && approvedVouchers}
        //         </span>
        //         <span className="description approved"> Approved vouchers</span>
        //       </div>

        //       <div className="voucher-stats-row">
        //         <span className="stat pending">
        //           {`${vouchersLeft && vouchersLeft}`}
        //         </span>
        //         <span className="description pending"> Vouchers Left</span>
        //       </div>
        //     </div>
        //   </div>
        // )
      }
    </div>
  );
}

export default VoucherOverviewCard;
