import React, { Component } from 'react';
export class NotFound extends Component {
  render() {
    return (
      <h1>
          Weyonjje Admin Console Requested Page not Found
      </h1>
    );
  }
}

export class Layout extends Component {
    render(){
        return(
            <div className="container-fluid">
            </div>
        );
    }
}

