import React, { Component } from 'react';
import {Layout, CustomLoading} from './inc/Layout';
import { NavDropdown, MenuItem} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFillDrip} from '@fortawesome/free-solid-svg-icons'
import moment from 'moment';
import { Check } from './Jobs';
import { BootstrapTable, TableHeaderColumn, ExportCSVButton } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';


const addr = require('../env_config').default;
var unirest = require("unirest");


const gSession = window.sessionStorage;
const token =gSession.getItem("wat");
const loggedInId =gSession.getItem("wai");
let prevMonthFirstDay = moment().subtract(1, 'months').date(1).local().format('YYYY-MM-DD');
var endOfDay = new Date();
endOfDay.setHours(23, 59, 59, 999);

export class Dumps extends Component{
    constructor(props){
      super(props);
      this.state = {
        dumps:[],
        isLoaded:false,
        location:"All",
        type:"All",
        from: prevMonthFirstDay,
        to: moment(endOfDay).local().format('YYYY-MM-DD'),
        manageColomns:{
          full_name: false,
          location: false,
          phone_number: false,
          sp_type: false,
          volume: false,
          date:false
        }
      }
      this.getData = this.getData.bind(this);
      this.handleInputChange = this.handleInputChange.bind(this);
    }
    componentDidMount(){
        //passin mentor id
        this.getData();
    }
    updateTable(colomn) {
      //make a copy of state
      let manageColomns = this.state.manageColomns;
     
      if (this.state.manageColomns[colomn] === true ){
        manageColomns[colomn] = false;
        this.setState({
          manageColomns: manageColomns 
        })
      }else{
        manageColomns[colomn] = true;
        this.setState({
          manageColomns: manageColomns 
        })
      }
      
    }
    getData(url="api/dumps"){
        const thisApp = this;
        let status;
        var req = unirest("GET", addr+url);
    
        req.headers({
          "cache-control": "no-cache",
          "authorization":token
        });
    
    
        req.end(function (res) {
          if (res.error) console.log(res);
    
          console.log(res.status);
          if(res.status == 200){
              console.log(res.body);
              let x = [];
            //  let q = x.push(res.body);
            thisApp.setState({
              dumps:res.body,
              isLoaded:true
            },() => console.log(thisApp.state));
          }
          else{
            thisApp.setState({
              isLoaded:true
            },() => console.log(thisApp.state));
          }
        });
      }
      handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
    
        this.setState({
          [name]: value
        },()=>
        //status
        //from
        //to
          this.getData("api/dumps?location="+this.state.location+"&type="+this.state.type+"&from="+this.state.from+"&to="+this.state.to)
        );
      }
       timeConvert(cell, row) {
         console.log(row);
         return cell && cell ? (moment(cell).format("DD/MM/YY h:mm:ss a")) : ("Date not available");
      }

      enumFormatter(cell, row, enumObject) {
        return enumObject[cell];
      }
      handleExportCSVButtonClick = (onClick) => {
        // Custom your onClick event here,
        // it's not necessary to implement this function if you have no any process before onClick
        console.log('This is my custom function for ExportCSVButton click event');
        onClick();
      }
      createCustomExportCSVButton = (onClick) => {
        return (
          <button className="btn btn-primary" onClick={ onClick }>Export to CSV</button>
         
        );
      }

      renderShowsTotal(start, to, total) {
        return (
          <p style={ { color: 'blue' } }>
            From { start } to { to }, totals is { total }&nbsp;&nbsp;
          </p>
        );
      }
      calcVol(cell, row){ 
         console.log(cell);
        let x = cell && cell ? (parseInt(cell)/1000):(0);
        return  x+ " m3";
      }
      handleExportCSVButtonClick = (onClick) => {
        const thisApp = this;
    
        onClick();
        // this.returnAlltheData(function(){
        //   thisApp.refs.table.handleExportCSV();
        // });
        
      }
      createCustomExportCSVButton = (onClick) => {
        return (
          <ExportCSVButton
            btnText='Export to CSV'
            btnGlyphicon='glyphicon-download'
            onClick={ () => this.handleExportCSVButtonClick(onClick) }/>
        );
      }
    render(){
      let products = this.state.dumps

      const sp_type = {
        Gulper: 'Gulper',
        Emptier: 'Emptier'
      };
      const location ={
          lubigi:'Lubigi Dumping Site',
          bugolobi:'Bugolobi Dumping Site'
      }


          const options = {
            exportCSVBtn: this.createCustomExportCSVButton,
            page: 1,  // which page you want to show as default
            sizePerPageList: [ {
              text: '10', value: 10
            }, {
              text: '25', value: 25
            }, {
              text: 'All', value: products && products.length
            } ], // you can change the dropdown list for size per page
            sizePerPage: 10,  // which size per page you want to locate as default
            pageStartIndex: 1, // where to start counting the pages
            exportCSVBtn: this.createCustomExportCSVButton,
            paginationSize: 3,  // the pagination bar size.
            prePage: 'Prev', // Previous page button text
            nextPage: 'Next', // Next page button text
            firstPage: 'First', // First page button text
            lastPage: 'Last', // Last page button text
            paginationShowsTotal: this.renderShowsTotal,  // Accept bool or function
            paginationPosition: 'bottom'  // default is bottom, top and both is all available
            // hideSizePerPage: true > You can hide the dropdown for sizePerPage
            // alwaysShowAllBtns: true // Always show next and previous button
            // withFirstAndLast: false > Hide the going to First and Last page button
          };

          let locationList=["Lubigi Dumping Site", "	Bugolobi Dumping Site"];
          let locationFilter = locationList.map((item)=>
          <option value={item}>{item}</option>
          );
          let typeList=["Gulper", "Emptier"];
          let typeFilter = typeList.map((item)=>
          <option value={item}>{item}</option>
          );
          
        return(
            <Layout>
                 <div className="col-md-8 title">
                                <h4> <FontAwesomeIcon icon={faFillDrip} /> Dumps</h4>
                            <br/>
                            </div>
                            <div className="col-md-12">
              <form class="form-inline">
             
                <div class="form-group">
                <label for="email">Dumping location:</label>
                  <select name="location" value={this.state.location} onChange={this.handleInputChange} className="form-control">
                    <option value="All">All</option>
                    {locationFilter}
                  </select>
              </div>

              <div class="form-group">
                <label for="email">Service provider type:</label>
                  <select name="type" value={this.state.type} onChange={this.handleInputChange} className="form-control">
                    <option value="All">All</option>
                    {typeFilter}
                  </select>
              </div>

              <div class="form-group">
                <label for="email">From:</label>
                 <input name="from" value={this.state.from} onChange={this.handleInputChange} className="form-control" type="date"/>
              </div>
              <div class="form-group">
                <label for="email">To:</label>
                 <input name="to" value={this.state.to} onChange={this.handleInputChange} className="form-control" type="date"/>
              </div>

              <NavDropdown eventKey={3} className="pull-right" title="Manage columns" id="basic-nav-dropdown">
              <MenuItem onClick={(e, full_name)=>this.updateTable("full_name")} eventKey={3.1}>
                  <Check state={this.state.manageColomns.full_name}/> SP name
              </MenuItem>
              <MenuItem onClick={(e, phone_number)=>this.updateTable("phone_number")} eventKey={3.1}>
                  <Check state={this.state.manageColomns.phone_number }/> SP number
              </MenuItem>
              <MenuItem onClick={(e, sp_type)=>this.updateTable("sp_type")} eventKey={3.1}>
                  <Check state={this.state.manageColomns.sp_type}/> SP Type
              </MenuItem>
              <MenuItem onClick={(e, location)=>this.updateTable("location")} eventKey={3.1}>
                  <Check state={this.state.manageColomns.location}/> Dumping Location
              </MenuItem>
              <MenuItem onClick={(e, volume)=>this.updateTable("volume")} eventKey={3.1}>
                  <Check state={this.state.manageColomns.volume}/> Volume</MenuItem>
              <MenuItem onClick={(e, date)=>this.updateTable("date")} eventKey={3.1}>
                  <Check state={this.state.manageColomns.date}/> Date
              </MenuItem>
            </NavDropdown>

              </form>
               
              </div>
               <div className="card padding-normal  col-md-12">
               {
                 this.state.isLoaded === false ? (
                  <CustomLoading text= "Loading dumping data"></CustomLoading>
                 ):(
                  <BootstrapTable 
                  headerContainerClass='table-header'
              tableContainerClass='table-responsive table-onScreen'
              
                  data={products} striped hover pagination={ true } options={ options } exportCSV csvFileName={'dumps_'+moment(Date.now()).local().format("YYYY_MM_DD_HHmmss")+".csv"} pagination>
                <TableHeaderColumn  dataSort={ true } isKey dataField='dump_id'>Dump ID</TableHeaderColumn>
                <TableHeaderColumn   hidden={this.state.manageColomns.full_name} dataSort={ true } dataField='full_name'>SP name</TableHeaderColumn>
                <TableHeaderColumn  hidden={this.state.manageColomns.phone_number} dataSort={ true } dataField='phone_number'>SP number</TableHeaderColumn>
                <TableHeaderColumn  hidden={this.state.manageColomns.sp_type}  dataSort={ true } dataField='sp_type'>SP Type</TableHeaderColumn>
                <TableHeaderColumn hidden={this.state.manageColomns.location}   dataSort={ true } dataField='location'>Dumping Location</TableHeaderColumn>
                <TableHeaderColumn hidden={this.state.manageColomns.volume}    csvFormat={this.calcVol} dataSort={ true } dataFormat={ this.calcVol } dataField='volume'>Volume</TableHeaderColumn>
                <TableHeaderColumn   hidden={this.state.manageColomns.long}   dataField='long'>Longitudes</TableHeaderColumn>
                <TableHeaderColumn   hidden={this.state.manageColomns.lat}  dataField='lat'>Latitudes</TableHeaderColumn>
            </BootstrapTable>
                 )
               }
               
                </div>
            </Layout>
        );
    }


}





