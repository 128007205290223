import React, { Component } from 'react';
import {Layout, CustomLoading} from './inc/Layout';
import {Tabs, Tab} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faUser} from '@fortawesome/free-solid-svg-icons'
import moment from 'moment';
import { BootstrapTable, TableHeaderColumn, ExportCSVButton } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';

//
const addr = require('../env_config').default;
var unirest = require("unirest");
const gSession = window.sessionStorage;
const token =gSession.getItem("wat");
const loggedInId =gSession.getItem("wai");


const ServiceProviderProfile = ({match}) => {
    return(
     <React.Fragment>
         <Page id={match.params.id}/>
         
     </React.Fragment>
    );
}

var endOfDay = new Date();
endOfDay.setHours(23, 59, 59, 999);

class Page extends Component{
    constructor(props){
      super(props);
      this.state = {
        sp:{},
        dumps:[],
        from: "2018-07-01",
        to: moment(endOfDay).local().format('YYYY-MM-DD')
      }
    }
    componentDidMount(){
        //passing mentor id
        //this.getData();
    }
        
    render(){
        return(
            <Layout>
                 <ControlledTabs id={this.props.id}/>
            </Layout>
        );
    }


}
class Summary extends Component{
    constructor(props){
      super(props);
      this.state = {
        sps:{}
      }
      this.getData = this.getData.bind(this);
    }
    componentDidMount(){
     this.getData();
    }
    getData(){
        
      const thisApp = this;
      let status;
      var req = unirest("GET", addr+"api/entreprenuer/summary/"+this.props.id);
  
      req.headers({
        "cache-control": "no-cache",
        "authorization":token
      });
  
  
      req.end(function (res) {
        if (res.error) console.log(res);
  
        console.log(res);
        if(res.status == 200){
            console.log(res.body);

          thisApp.setState({
              sp:res.body.data,
              isLoaded:true
          },() => console.log(thisApp.state));
        }
        else{
          thisApp.setState({
            isLoaded:true
          },() => console.log(thisApp.state));
        }
      });
    }
    render(){
        return(
            <React.Fragment>
            <div className="col-md-12 title">
            <h4> <Link to="/sps"className="btn">
                <FontAwesomeIcon icon={faArrowLeft} />
            </Link> Service provider details</h4>
            </div>
            <div className="card padding-normal col-md-12">
                <div className="col-md-1">
                    <div className="userIcon">
                    <FontAwesomeIcon color="#707070" icon={faUser} />
                    </div>
                </div>
                <div className="col-md-9 summary">
                    <div className="col-md-3">
                    <span className="key"><span className="value">{this.state.sp && this.state.sp.full_name} {this.state.sp && this.state.sp.phone_number}</span></span>
                        <span className="key">Jobs: <span className="value">{this.props.jobs}</span></span>
                    </div>
                    <div className="col-md-2">
                        <span className="key">ID: <span className="value">{this.state.sp && this.state.sp.ent_id}</span></span>
                        <span className="key">Dumps: <span className="value">{this.props.dumps}</span></span>
                    </div>
                    <div className="col-md-4">
                        <span className="key">Type: <span className="value">{this.state.sp && this.state.sp.type}</span></span>
                        <span className="key">Total Earnings: <span className="value">UGX {parseInt(this.props.te).toLocaleString('en')}</span></span>
                    </div>
                     
                     <div className="col-md-3">
                     <span className="key hidden">Status: <span className="value">{this.props.data && this.props.data.status}</span></span>
                     </div>
                     
                </div>
            </div>
            </React.Fragment>
        );
    }
}


class TagFilter extends Component{
    constructor(props){
      super(props);
      this.state = {
        sps:{}
      }
    }
    componentDidMount(){
      const btn = document.querySelectorAll('.tag-filter-section .btn');
      //btn.classList.remove('btn-primary');
      btn.forEach(function(btni){
       // 
       
        btni.addEventListener('click', function(i){
          document.querySelector('.tag-filter-section .btn-primary').classList.remove('btn-primary');
          btni.classList.remove('btn-primary');
          i.target.classList.add('btn-primary');
        });
      });
      
      
    }

    render(){
        return(
            <React.Fragment>
                <div className="col-md-12 padding-normal title">
                    <h4>Filter by duration</h4>
                </div> 
            <div className="tag-filter-section col-md-12">
                <button value="3000" onClick={(e, days)=>this.props.date(30000)} className="btn btn-all btn-primary">All</button>
                <button value="7" onClick={(e, days)=>this.props.date(7)} className="btn btn-1w">1 Week</button>
                <button value="30" onClick={(e, days)=>this.props.date(30)}  className="btn btn-1m">1 Month</button>
                <button value="90" onClick={(e, days)=>this.props.date(90)} className="btn btn-3m">3 Months</button>
                <button value="180" onClick={(e, days)=>this.props.date(180)}  className="btn btn-6m">6 Months</button>
                <button value="360" onClick={(e, days)=>this.props.date(360)}  className="btn btn-1y">1 Year</button>
            </div>
            </React.Fragment>
        );
    }
}

class ControlledTabs extends React.Component {
    constructor(props, context) {
      super(props, context);
      this.state = {
        key: 'jobs',
        from: "2018-07-01",
        to: moment(endOfDay).local().format('YYYY-MM-DD'),
        jobs:[],
        dumps:[],
        te:0
      };
      this.getData= this.getData.bind(this);
      this.getDumps= this.getDumps.bind(this);
      this.updateResults = this.updateResults.bind(this);

    }
    updateResults(days){
      //todays' data
      let dateTo = moment().format('YYYY-MM-DD');

      // days to
     let  dateFrom = moment().subtract(days,'d').format('YYYY-MM-DD');
      this.setState({
        from:dateFrom,
        to:dateTo
      },()=>
        this.getData()
      )

    }
    componentDidMount(){
      this.getData();
    }
    getData(){
      const thisApp = this;
      let status;
      var req = unirest("GET", addr+"api/jobs/serviceprovider/"+this.props.id+"?from="+this.state.from+"&to"+this.state.to);
      req.headers({
        "cache-control": "no-cache",
        "authorization":token
      });
        req.end(function (res) {
        if (res.error) console.log(res);
  
        console.log(res);
       
        if(res.status == 200){
            console.log(res.body);
            let x = [];
          //  let q = x.push(res.body);
          //calculate volume
          let income = 0;
          res.body.data.forEach((job)=>
          income += !job.income_received || job.income_received.length === 0 || isNaN(job.income_received)?0:parseInt(job.income_received)
          );
          thisApp.setState({
              jobs:res.body.data,
              isLoaded:true,
              te:income
          },() =>  thisApp.getDumps());
        }
        else{
          thisApp.setState({
            isLoaded:true
          },() => console.log(thisApp.state));
        }
      });
    }
    getDumps(){
      const thisApp = this;
      let status;
      var req = unirest("GET", addr+"api/dumps/serviceprovider/"+this.props.id+"?from="+this.state.from+"&to"+this.state.to);
  
      req.headers({
        "cache-control": "no-cache",
        "authorization":token
      });
  
  
      req.end(function (res) {
        if (res.error) console.log(res);
  
        console.log(res);
        if(res.status == 200){
            console.log(res.body);
            let x = [];
          //  let q = x.push(res.body);
          thisApp.setState({
              dumps:res.body,
              isLoaded:true
          },() => console.log(thisApp.state));
        }
        else{
          thisApp.setState({
            isLoaded:true
          },() => console.log(thisApp.state));
        }
      });
    }
  
    render() {
      return (
        <React.Fragment>
            <Summary te ={this.state.te} jobs={this.state.jobs.length} dumps={this.state.dumps.length} id={this.props.id} />
          <TagFilter date={this.updateResults}/>
       
          <div className="col-md-12 card padding-normal">
        <Tabs
          id="controlled-tab-example"
          activeKey={this.state.key}
          onSelect={key => this.setState({ key })}
        >
          <Tab eventKey="jobs" title="Jobs">
            <JobsTable date={this.props.date} id={this.props.id} jobs={this.state.jobs}/>
          </Tab>
          <Tab eventKey="dumps" title="Dumps">
            <DumpsTable dumps={this.state.dumps}/>
          </Tab>
        </Tabs>
        </div>
        </React.Fragment>
      );
    }
  }

  class JobsTable extends React.Component{
    constructor(props, context) {
        super(props, context);
        this.state = {
         isLoaded:true,
         jobs:[]
        };
      }
      componentDidMount(){
       
      }
      handleExportCSVButtonClick = (onClick) => {
        // Custom your onClick event here,
        // it's not necessary to implement this function if you have no any process before onClick
        console.log('This is my custom function for ExportCSVButton click event');
        onClick();
      }
      createCustomExportCSVButton = (onClick) => {
        return (
          <ExportCSVButton
            btnText='Export to CSV'
            onClick={ () => this.handleExportCSVButtonClick(onClick) }/>
        );
      }
      moneyConvert(cell, row) {
        console.log(row)
        let amount = "UGX 0";
        if (cell) {
          amount = "UGX " + parseInt(cell).toLocaleString('en')
        }
       // alert(cell)
        return amount;
      }
      timeConvert(cell, row) {
        //console.log(row);
    
        return cell && cell ? (moment(cell).format("DD/MM/YY h:mm:ss a")) : ("Date not available");
      }
    
      render() {
        let products = this.props.jobs
        const options = {
            sizePerPage: 10000,  // which size per page you want to locate as default
            pageStartIndex: 1, // where to start counting the pages
            paginationSize: 1,
            exportCSVBtn: this.createCustomExportCSVButton,
            prePage: 'Prev', // Previous page button text
            nextPage: 'Next', // Next page button text
            firstPage: 'First', // First page button text
            paginationPosition: 'bottom'  // default is bottom, top and both is all available
          };
        return (
            
            <div className="content-container padding-normal col-md-12">
          {this.state.isLoaded === true ? (
            <BootstrapTable data={products}
              striped
              hover
              exportCSV
              maxHeight='360px'
              pagination={true}
              options={options}
              pagination>
              <TableHeaderColumn width="80px" dataSort={true} isKey dataField='job_id'>Job ID</TableHeaderColumn>
              <TableHeaderColumn  dataSort={true} dataField='full_name'>Customer name</TableHeaderColumn>
              <TableHeaderColumn dataField='phone_number'>Customer Phone</TableHeaderColumn>
              <TableHeaderColumn dataSort={true} dataField='location'>Location</TableHeaderColumn>
              <TableHeaderColumn dataSort={true} dataField='division'>Division</TableHeaderColumn>
              <TableHeaderColumn dataSort={true} dataField='facility_type'>Facility type</TableHeaderColumn>
              <TableHeaderColumn dataSort={true} dataField='income_received' dataFormat={this.moneyConvert} csvFormat={this.moneyConvert}>Amount Received</TableHeaderColumn>
              <TableHeaderColumn dataSort={true} dataField='finish_time' dataFormat={this.timeConvert} csvFormat={this.timeConvert}>Date and Time</TableHeaderColumn>
            </BootstrapTable>
          ) : (
              <CustomLoading text="Loading service provider's dumps" />
            )}

        </div>
        );
      }
  }

  class DumpsTable extends React.Component{
    constructor(props, context) {
        super(props, context);
        this.state = {
            isLoaded:true,
        };
      }
      showDumping(cell, row){
        return row.location.address;
      }
      timeConvert(cell, row) {
        //console.log(row);
    
        return cell && cell ? (moment(cell).format("DD/MM/YY h:mm:ss a")) : ("Date not available");
      }
      calcVol(cell, row){ 
       // console.log(cell);
       let x = cell && cell ? (parseInt(cell)/1000):(0);
       return  x+ " m3";
     }
     dateFormatter(cell, row) {
      return `${('0' + cell.getDate()).slice(-2)}/${('0' + (cell.getMonth() + 1)).slice(-2)}/${cell.getFullYear()}`;
    }
      render() {
        let products = this.props.dumps
        const options = {
            sizePerPage: 50,  // which size per page you want to locate as default
            pageStartIndex: 1, // where to start counting the pages
            paginationSize: 10,
            prePage: 'Prev', // Previous page button text
            nextPage: 'Next', // Next page button text
            firstPage: 'First', // First page button text
            paginationPosition: 'bottom'  // default is bottom, top and both is all available
          };
        return (
            <div className="content-container padding-normal col-md-12">
          {this.state.isLoaded === true ? (
            <BootstrapTable data={products}
              striped
              hover
              maxHeight='360px'
              pagination={true}
              options={options}
              pagination>
             { <TableHeaderColumn  dataSort={ true } hidden={true} isKey dataField="_id">Dump ID</TableHeaderColumn> } 
                <TableHeaderColumn dataSort={ true } dataField='location'>Dumping Location</TableHeaderColumn>
                <TableHeaderColumn dataSort={ true } dataFormat={ this.calcVol } dataField='volume'>Volume</TableHeaderColumn>
                <TableHeaderColumn dataSort={ true } dataFormat={ this.timeConvert } dataField='date'>Date</TableHeaderColumn>
            </BootstrapTable>
          ) : (
              <CustomLoading text="Loading service provider's jobs" />
            )}

        </div> 
        );
      }
  }


export default ServiceProviderProfile





