import React from "react";
import "../../styles/voucher.css";

function CATSOverviewCard({ CATS, isAdmin }) {
  const {
    count,
    totalNumberOfCatsFromCentral,
    totalNumberOfCatsFromKawempe,
    totalNumberOfCatsFromMakindye,
    totalNumberOfCatsFromNakawa,
    totalNumberOfCatsFromRubaga,
    totalNumberOfHouseholdsMappedInCentral,
    totalNumberOfHouseholdsMappedInKawempe,
    totalNumberOfHouseholdsMappedInMakindye,
    totalNumberOfHouseholdsMappedInNakawa,
    totalNumberOfHouseholdsMappedInRubaga,
  } = CATS;

  return (
    isAdmin && (
      <div className="voucher-stats-container" style={{ height: "auto" }}>
        <div className="col-lg-6 col-sm-3" style={{ display: "flex" }}>
          <div className="voucher-stats">
            <div className="voucher-stats-row">
              <span className="stat total">{count && count}</span>
              <span className="description total">
                Total Number of CATS / VHTS
              </span>
            </div>
            <div className="voucher-stats-row">
              <span className="stat approved">
                {totalNumberOfCatsFromCentral}
              </span>
              <span className="description approved"> Central</span>
            </div>

            <div className="voucher-stats-row">
              <span className="stat pending">
                {totalNumberOfCatsFromKawempe}
              </span>
              <span className="description pending"> Kawempe</span>
            </div>

            <div className="voucher-stats-row">
              <span className="stat declined">
                {totalNumberOfCatsFromMakindye}
              </span>
              <span className="description declined"> Makindye</span>
            </div>

            <div className="voucher-stats-row">
              <span className="stat redeemed">
                {totalNumberOfCatsFromNakawa}
              </span>
              <span className="description redeemed"> Nakawa</span>
            </div>

            <div className="voucher-stats-row">
              <span className="stat redeemed">
                {totalNumberOfCatsFromRubaga}
              </span>
              <span className="description redeemed"> Rubaga</span>
            </div>
          </div>
        </div>
        <div
          className="col-lg-6 col-sm-3"
          style={{ display: "flex", borderLeft: "4px solid #38713c" }}
        >
          <div className="voucher-payments-graph">
            <div className="voucher-payments-graph-container">
              {/* <HighchartsReact
                  highcharts={Highcharts}
                  options={voucherStatusPie && voucherStatusPie}
                /> */}
            </div>
          </div>
          <div className="voucher-stats">
            <div className="voucher-stats-row">
              <span className="stat total">{count && count}</span>
              <span className="description total">
                Total Number of Households Mapped
              </span>
            </div>
            <div className="voucher-stats-row">
              <span className="stat approved">
                {totalNumberOfHouseholdsMappedInCentral}
              </span>
              <span className="description approved">
                Mapped households in Central
              </span>
            </div>

            <div className="voucher-stats-row">
              <span className="stat pending">
                {totalNumberOfHouseholdsMappedInKawempe}
              </span>
              <span className="description pending">
                Mapped households in Kawempe
              </span>
            </div>

            <div className="voucher-stats-row">
              <span className="stat declined">
                {totalNumberOfHouseholdsMappedInMakindye}
              </span>
              <span className="description declined">
                Mapped households in Makindye
              </span>
            </div>

            <div className="voucher-stats-row">
              <span className="stat redeemed">
                {totalNumberOfHouseholdsMappedInNakawa}
              </span>
              <span className="description redeemed">
                Mapped households in Nakawa
              </span>
            </div>

            <div className="voucher-stats-row">
              <span className="stat redeemed">
                {totalNumberOfHouseholdsMappedInRubaga}
              </span>
              <span className="description redeemed">
                Mapped households in Rubaga
              </span>
            </div>
          </div>
        </div>
      </div>
    )
  );
}

export default CATSOverviewCard;
