import React, { useState } from "react";
import { Button } from "react-bootstrap";
import Switch from "react-toggle-switch";

import { CustomLoading } from "../inc/Layout";

const addressEndpoint = require("../../env_config").default;
const alertifyjs = require("alertifyjs");
const gSession = window.sessionStorage;
const token = gSession.getItem("wat");
const divisionID = gSession.getItem("divisionID");

const DEFAULT_OPTIONS = {
  headers: {
    cache: "no-store",
    credentials: "same-origin",
    authorization: token,
    "content-type": "application/json",
  },
};

// This is dirty but this "component" is used for both editing SP and CATs/VHT details
const CATSDetails = ({ cats, close, sp }) => {
  console.log(cats);
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [editedName, setEditedName] = useState(cats.full_name);
  const [editedPhone, setEditedPhone] = useState(cats.phone_number);
  const [isApproved, setIsApproved] = useState(
    sp ? cats.approved : cats.isApprovedByDO
  );

  const [editedWorkAddress, setEditedWorkAddress] = useState(cats.work_address);
  const [editedOrganization, setEditedOrganization] = useState(cats.org_name);
  const [editedPlate, setEditedPlate] = useState(cats.plate);

  const toggleSwitch = () => {
    setIsApproved(!isApproved);
  };

  const handleEditForm = () => {
    setEdit(!edit);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let payload = {
      ...(cats.full_name !== editedName && { full_name: editedName }),
      ...(cats.phone_number !== editedPhone && { phone_number: editedPhone }),
      ...(sp && {
        org: {
          division: divisionID,
          name:
            cats.org_name !== editedOrganization
              ? editedOrganization
              : cats.org_name,
          plate: cats.plate !== editedPlate ? editedPlate : cats.plate,
        },
        ...(cats.approved !== isApproved && { approved: isApproved }),
        ...(cats.work_address !== editedWorkAddress && {
          work_address: editedWorkAddress,
        }),
      }),
      ...(!sp && {
        ...(cats.isApprovedByDO !== isApproved && {
          isApprovedByDO: isApproved,
        }),
      }),
    };

    if (Object.entries(payload).length === 0) {
      close();
    }

    const url = sp
      ? addressEndpoint + `api/entreprenuer/${cats._id}`
      : addressEndpoint + `api/v1/cats/${cats._id}`;

    await fetch(url, {
      method: sp ? "PUT" : "PATCH",
      body: JSON.stringify(payload),
      ...DEFAULT_OPTIONS,
    })
      .then((result) => {
        if (!result.ok) throw result;
        return result.json();
      })
      .then((res) => {
        setLoading(false);
        close();

        alertifyjs.success(
          `Successfully edited ${sp ? "SP" : "CATS"} ${cats.full_name}`,
          10
        );

        window.location.href = "/vouchers";
      })
      .catch((error) => {
        error.json().then((body) => {
          setLoading(false);
          setErrorMessage(body.error);
        });
      });
  };

  return (
    <div className="bootstrap-iso">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12">
            {loading ? (
              <CustomLoading text={`Updating ${sp ? "SP" : "VHT"} details `} />
            ) : (
              <>
                <Button
                  style={{ marginRight: "15px", width: "150px" }}
                  bsStyle="primary pull-right"
                  onClick={handleEditForm}
                >
                  {edit ? `Done` : `Edit`}
                </Button>
                <form
                  onSubmit={(e) => handleSubmit(e)}
                  style={{ marginTop: "50px" }}
                >
                  <div className="col-md-12 editCATForm">
                    <div className="form-group ">
                      <label
                        className="control-label requiredField"
                        htmlFor="full_name"
                      >
                        Full Name
                      </label>
                    </div>

                    <div className="form-group ">
                      <input
                        className="form-control"
                        id="full_name"
                        name="full_name"
                        defaultValue={cats.full_name}
                        onChange={(e) => setEditedName(e.target.value)}
                        readOnly={!edit}
                        style={{ width: "500px" }}
                      />
                    </div>
                  </div>
                  <div className="col-md-12 editCATForm">
                    <div className="form-group ">
                      <label
                        className="control-label requiredField"
                        htmlFor="full_name"
                      >
                        Phone number
                      </label>
                    </div>

                    <div className="form-group">
                      <input
                        className="form-control"
                        id="phone_number"
                        name="phone_number"
                        defaultValue={cats.phone_number}
                        onChange={(e) => setEditedPhone(e.target.value)}
                        readOnly={!edit}
                        style={{ width: "500px" }}
                      />
                    </div>
                  </div>
                  <div className="col-md-12 editCATForm">
                    {!sp && (
                      <>
                        <div className="form-group ">
                          <label
                            className="control-label requiredField"
                            htmlFor="full_name"
                          >
                            Division
                          </label>
                        </div>

                        <div className="form-group">
                          <input
                            className="form-control"
                            id="division"
                            name="division"
                            defaultValue={cats.division}
                            readOnly
                            style={{ width: "500px" }}
                          />
                        </div>
                      </>
                    )}
                  </div>
                  <div className="col-md-12 editCATForm">
                    {!sp && (
                      <>
                        <div className="form-group ">
                          <label
                            className="control-label requiredField"
                            htmlFor="full_name"
                          >
                            Referral Code
                          </label>
                        </div>

                        <div className="form-group ">
                          <input
                            className="form-control"
                            id="referral_code"
                            name="referral_code"
                            defaultValue={cats.referral_code}
                            readOnly
                            style={{ width: "500px" }}
                          />
                        </div>
                      </>
                    )}
                  </div>
                  {sp && (
                    <div className="col-md-12 editCATForm">
                      <>
                        <div className="form-group ">
                          <label
                            className="control-label requiredField"
                            htmlFor="full_name"
                          >
                            Number plate
                          </label>
                        </div>

                        <div className="form-group ">
                          <input
                            className="form-control"
                            id="plate"
                            name="plate"
                            defaultValue={cats.plate}
                            onChange={(e) => setEditedPlate(e.target.value)}
                            readOnly={!edit}
                            style={{ width: "500px" }}
                          />
                        </div>
                      </>
                    </div>
                  )}
                  {sp && (
                    <div className="col-md-12 editCATForm">
                      <>
                        <div className="form-group ">
                          <label
                            className="control-label requiredField"
                            htmlFor="full_name"
                          >
                            Organization
                          </label>
                        </div>

                        <div className="form-group ">
                          <input
                            className="form-control"
                            id="org_name"
                            name="org_name"
                            defaultValue={cats.org_name}
                            onChange={(e) =>
                              setEditedOrganization(e.target.value)
                            }
                            readOnly={!edit}
                            style={{ width: "500px" }}
                          />
                        </div>
                      </>
                    </div>
                  )}
                  {sp && (
                    <div className="col-md-12 editCATForm">
                      <>
                        <div className="form-group ">
                          <label
                            className="control-label requiredField"
                            htmlFor="work_address"
                          >
                            Work address
                          </label>
                        </div>

                        <div className="form-group ">
                          <input
                            className="form-control"
                            id="work_address"
                            name="work_address"
                            defaultValue={cats.work_address}
                            onChange={(e) =>
                              setEditedWorkAddress(e.target.value)
                            }
                            readOnly={!edit}
                            style={{ width: "500px" }}
                          />
                        </div>
                      </>
                    </div>
                  )}
                  <div className="col-md-12 editCATForm">
                    <div className="form-group ">
                      <label
                        className="control-label requiredField"
                        htmlFor="full_name"
                      >
                        Active
                      </label>
                    </div>

                    <div
                      className="form-group"
                      style={{
                        opacity: !edit ? "0.2" : "1",
                        pointerEvents: !edit ? "none" : "all",
                      }}
                    >
                      <Switch onClick={toggleSwitch} on={isApproved} />
                    </div>
                  </div>

                  {errorMessage && (
                    <div className="col-md-12">
                      <p style={{ color: "red" }}>
                        {errorMessage.message || errorMessage}
                      </p>
                    </div>
                  )}

                  <div className="col-md-12">
                    <div className="form-group">
                      <button
                        className="btn btn-primary "
                        name="submit"
                        type="submit"
                        disabled={edit === false}
                      >
                        SAVE
                      </button>
                      &nbsp; &nbsp; &nbsp;
                      <button
                        onClick={close}
                        className="btn btn-default "
                        name="cancel"
                        type="button"
                      >
                        CANCEL
                      </button>
                    </div>
                  </div>
                </form>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CATSDetails;
