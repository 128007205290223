const serverUrl = require('./config.json');

function env_config() {
  if (
    !process.env.NODE_ENV ||
    process.env.NODE_ENV === 'development' ||
    window.location.hostname === 'localhost'
  ) {
    return serverUrl.dev;
  } else if (
    process.env.NODE_ENV === 'production' &&
    window.location.hostname !== 'dashboard.testing.weyonje.com' &&
    window.location.hostname !== '35.225.146.185' &&
    window.location.hostname !== 'dashboard.staging.weyonje.com'
  ) {
    return serverUrl.prod;
  } else if (
    window.location.hostname === '35.225.146.185' ||
    window.location.hostname === 'dashboard.testing.weyonje.com'
  ) {
    return serverUrl.testing;
  } else if (window.location.hostname === 'dashboard.staging.weyonje.com') {
    console.log('Serving from staging api');
    return serverUrl.staging;
  } else {
    return serverUrl.prod;
  }
}
let addr;

export default addr = env_config();
