import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAlignRight, faCalendar, faTachometerAlt, faWater, faTint, faFillDrip, faShareSquare, faCreditCard} from '@fortawesome/free-solid-svg-icons'
import {ListGroup, ListGroupItem} from 'react-bootstrap';
import {Link} from 'react-router-dom';
export class SideNav extends Component{
    constructor(props){
      super(props);
      this.state = {
        mini:false,
        show:true
      }
      this.ToggleMenu = this.ToggleMenu.bind(this);
    }
  handleSidebar() {
    let sideNavBar = document.getElementById("sideNavBar");
    let detailComponent = document.getElementById("detailComponent");
    if (this.state.mini === false) {
      this.setState({
        mini: true
      });
      sideNavBar.classList.remove("col-md-2");
      sideNavBar.classList.add("col-md-1");
      detailComponent.classList.remove("col-md-10");
      detailComponent.classList.add("col-md-11");
    } else {
      this.setState({
        mini: false
      });
      sideNavBar.classList.remove("col-md-1");
      sideNavBar.classList.add("col-md-2");
      detailComponent.classList.remove("col-md-11");
      detailComponent.classList.add("col-md-10");
    }
  }
    ToggleMenu(e){
        e.preventDefault();
        let stateX = this.state.show;
        if(stateX ===false){
          this.setState({
            show:true
          });
        }
        else{
          this.setState({
            show:false
          });
        }
        
      }
    render(){
        return(
    <div className={this.state.show ?("sideNav"):("sideNav hiddenMenu")}>
          <nav className="headerNav navbar navbar-default navbar-static-top">
        <div className="navbar-header">
                     <a className="navbar-brand" href="#">Weyonje</a>
          <button type="button" className="btn onMobile" onClick={this.ToggleMenu} id="handleSidebar">
          <FontAwesomeIcon icon={faAlignRight}/>
          </button>
      </div>
    </nav>
<div id="listMenu" className="col-md-12">
<ListGroup>
  <ListGroupItem id="dashboardLink"><Link to="/dashboard"><FontAwesomeIcon icon={faTachometerAlt} />{" "}{this.state.mini === false && "Dashboard"}</Link></ListGroupItem>
  <ListGroupItem id="liveTrackingLink"><Link to="/"><FontAwesomeIcon icon={faCalendar}/> { this.state.mini ===false && "Live tracking"}</Link></ListGroupItem>
  <ListGroupItem id="JobsLink"><Link to="/jobs"><FontAwesomeIcon icon={faTachometerAlt}/> { this.state.mini ===false && "Jobs"}</Link></ListGroupItem>
  <ListGroupItem id="spLink"><Link to="/sps"><FontAwesomeIcon icon={faWater}/>  { this.state.mini ===false && "Service Providers"}</Link></ListGroupItem>
  <ListGroupItem id="customerLink"><Link to="/customers"><FontAwesomeIcon icon={faTint}/>   { this.state.mini ===false && "Customers"}</Link></ListGroupItem>
  <ListGroupItem id="dumpsLink"><Link to="/dumps"><FontAwesomeIcon icon={faFillDrip}/>   { this.state.mini ===false && "Dumps"}</Link></ListGroupItem>
  <ListGroupItem id="dispatchLink"><Link to="/dispatchers"><FontAwesomeIcon icon={faShareSquare}/>   { this.state.mini ===false && "Dispatchers"}</Link></ListGroupItem>
  <ListGroupItem id="voucherLink"><Link to="/vouchers"><FontAwesomeIcon icon={faCreditCard}/>   { this.state.mini ===false && "Vouchers"}</Link></ListGroupItem>

</ListGroup>
</div>
    </div>
        );
    }


}
