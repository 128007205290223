import React, { useState } from 'react';
import moment from 'moment';

import {
  BootstrapTable,
  TableHeaderColumn,
  ExportCSVButton,
} from 'react-bootstrap-table';

import {
  renderShowsTotal,
  formatVoucherValues,
  tdStyle,
  timeConvert,
  formatDivisions,
  formatVoucherProgramStatus,
} from '../utils/tableUtilities';

import { Modal, Button } from 'react-bootstrap';

import AddVoucherProgram from '../AddVoucherProgram/AddVoucherProgram';

const alertifyjs = require('alertifyjs');
const gSession = window.sessionStorage;
const token = gSession.getItem('wat');
const role = gSession.getItem('role');

const DEFAULT_OPTIONS = {
  headers: {
    cache: 'no-store',
    credentials: 'same-origin',
    authorization: token,
    'content-type': 'application/json',
  },
};

const addressEndpoint = require('../../env_config').default;

const AddVoucherProgramButton = ({ row, divisions }) => {
  const [showAddVoucherProgramModal, setShowAddVoucherProgramModal] = useState(
    false
  );

  const handleShowAddCatsModal = () => {
    setShowAddVoucherProgramModal(!showAddVoucherProgramModal);
  };

  return (
    <>
      <Button
        style={{ float: 'right', marginBottom: '2rem', marginRight: '10px' }}
        bsStyle='primary'
        onClick={handleShowAddCatsModal}
      >
        Add Voucher Program
      </Button>
      <Modal show={showAddVoucherProgramModal} onHide={handleShowAddCatsModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add CATs/VHT</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ height: 550 }}>
          <AddVoucherProgram
            close={handleShowAddCatsModal}
            allDivisions={divisions}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

const ActionButton = ({ row, isAdmin }) => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const actionProgram = async (type) => {
    setLoading(true);

    const payload = {
      ...(type === 'approved' ? { approved: 1 } : { approved: 2 }),
      ...(type === 'approved' ? { isActive: true } : { isActive: false }),
      approvedBy: gSession.getItem('name'),
      dateApproved: Date.now(),
    };

    const url = addressEndpoint + `api/v1/voucherProgram/${row._id}`;

    await fetch(url, {
      method: 'PATCH',
      body: JSON.stringify(payload),
      ...DEFAULT_OPTIONS,
    })
      .then((result) => {
        if (!result.ok) throw result;
        return result.json();
      })
      .then((res) => {
        setLoading(false);
        alertifyjs.success(
          `Voucher Program ${type} by ${gSession.getItem('name')}`,
          5
        );

        setTimeout(() => {
          window.location.href = '/vouchers';
        }, 3000);
      })
      .catch((error) => {
        error.json().then((body) => {
          setLoading(false);
          setErrorMessage(body.error);
        });
      });
  };

  return (
    <>
      <Button
        style={{ marginRight: '10px' }}
        bsStyle='primary'
        onClick={() => actionProgram('approved')}
        disabled={loading || row.approved >= 1}
      >
        {loading ? 'Approving' : 'Approve'}
      </Button>
      <Button
        style={{ marginRight: '10px' }}
        bsStyle='danger'
        onClick={() => actionProgram('declined')}
        disabled={loading || row.approved >= 1}
      >
        {loading ? 'Declining' : 'Decline'}
      </Button>
    </>
  );
};

function DPHEView({ programs, showActions, divisions }) {
  const actionButton = (cell, row, enumObject, rowIndex) => {
    return <ActionButton cell={cell} row={row} rowIndex={rowIndex} />;
  };

  const createCustomExportCSVButton = (onClick) => {
    return (
      <ExportCSVButton
        btnText='Export to CSV'
        btnGlyphicon='glyphicon-download'
        onClick={() => handleExportCSVButtonClick(onClick)}
      />
    );
  };

  const handleExportCSVButtonClick = (onClick) => {
    onClick();
  };

  const options = {
    exportCSVBtn: createCustomExportCSVButton,
    page: 1,
    sizePerPageList: [
      {
        text: '10',
        value: 10,
      },
      {
        text: '25',
        value: 25,
      },
      {
        text: 'All',
        value: programs,
      },
    ],
    sizePerPage: 15,
    pageStartIndex: 1,
    paginationSize: 3,
    prePage: 'Prev',
    nextPage: 'Next',
    firstPage: 'First',
    lastPage: 'Last',
    paginationShowsTotal: renderShowsTotal,
    paginationPosition: 'bottom',
  };
  return (
    <div className='voucher-table-container'>
      {role === 'admin' && <AddVoucherProgramButton divisions={divisions} />}

      <BootstrapTable
        data={programs}
        striped
        hover
        pagination={true}
        options={options}
        exportCSV
        csvFileName={
          'programs' +
          moment(Date.now()).local().format('YYYY_MM_DD_HHmmss') +
          '.csv'
        }
      >
        <TableHeaderColumn
          isKey
          dataSort={true}
          dataField='programName'
          thStyle={tdStyle}
          tdStyle={tdStyle}
        >
          Program Name
        </TableHeaderColumn>
        <TableHeaderColumn
          dataSort={true}
          dataField='startDate'
          dataFormat={timeConvert}
          thStyle={tdStyle}
          tdStyle={tdStyle}
          width='100px'
        >
          Start Date
        </TableHeaderColumn>
        <TableHeaderColumn
          dataSort={true}
          dataField='endDate'
          dataFormat={timeConvert}
          thStyle={tdStyle}
          tdStyle={tdStyle}
          width='100px'
        >
          End Date
        </TableHeaderColumn>
        <TableHeaderColumn
          dataSort={true}
          dataField='divisions'
          dataFormat={formatDivisions}
          thStyle={tdStyle}
          tdStyle={tdStyle}
        >
          Participating Divisions
        </TableHeaderColumn>
        <TableHeaderColumn
          dataSort={true}
          dataField='defaultVoucherValue'
          dataFormat={formatVoucherValues}
          thStyle={tdStyle}
          tdStyle={tdStyle}
          width='130px'
        >
          Vouchers Value
        </TableHeaderColumn>
        <TableHeaderColumn
          dataSort={true}
          dataField='maxVouchersPerDivision'
          thStyle={tdStyle}
          tdStyle={tdStyle}
          width='150px'
        >
          Vouchers per Division
        </TableHeaderColumn>
        <TableHeaderColumn
          dataSort={true}
          dataField='defaultVoucherValidity'
          thStyle={tdStyle}
          tdStyle={tdStyle}
          width='140px'
        >
          Voucher Validity
        </TableHeaderColumn>
        <TableHeaderColumn
          dataSort={true}
          dataField='totalPeriodVouchersValue'
          dataFormat={formatVoucherValues}
          thStyle={tdStyle}
          tdStyle={tdStyle}
          width='130px'
        >
          Program Value
        </TableHeaderColumn>
        <TableHeaderColumn
          dataSort={true}
          dataField='approved'
          dataFormat={formatVoucherProgramStatus}
          thStyle={tdStyle}
          tdStyle={tdStyle}
          width='120px'
        >
          Status
        </TableHeaderColumn>
        {showActions && (
          <TableHeaderColumn
            dataField='action'
            dataFormat={actionButton}
            dataAlign='center'
            thStyle={tdStyle}
            tdStyle={tdStyle}
            //   width='150px'
          >
            Action
          </TableHeaderColumn>
        )}
      </BootstrapTable>
    </div>
  );
}

export default DPHEView;
