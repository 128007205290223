import React, { useState } from "react";
import "alertifyjs/build/css/alertify.min.css";
import "alertifyjs/build/css/themes/semantic.min.css";
var unirest = require("unirest");
const alertifyjs = require("alertifyjs");

const addr = require("../env_config").default;

function Login() {
  const [isDO, setIsDO] = useState(false);

  function login(e) {
    e.preventDefault();

    alertifyjs.message("Signing in ..", 2);

    var req = unirest("POST", addr + "auth/admin/login");

    req.headers({
      "cache-control": "no-cache",
      "content-type": "application/x-www-form-urlencoded",
    });
    var email_address = document.getElementById("email_address");
    var password = document.getElementById("password");

    let data = {
      email_address: email_address.value,
      password: password.value,
      ...(isDO && { isDO }),
    };

    req.form(data);

    req.end(function (res) {
      if (res.error) {
        alertifyjs.error(
          "Oops! That email / password combination is not valid.",
          5
        );
      } else {
        sessionStorage.removeItem("wat");
        sessionStorage.removeItem("wai");
        sessionStorage.removeItem("name");
        sessionStorage.removeItem("isAdmin");
        sessionStorage.removeItem("division");
        sessionStorage.removeItem("divisionID");
        sessionStorage.removeItem("voucherProgramsDetails");
        sessionStorage.removeItem("role");
        sessionStorage.removeItem("defaultVoucherValidity");
        sessionStorage.removeItem("defaultVoucherValue");
        sessionStorage.setItem("role", res.body.role);
        sessionStorage.setItem("wat", res.body.token);
        sessionStorage.setItem("wai", res.body.id);
        sessionStorage.setItem("name", res.body.name);
        sessionStorage.setItem("division", res.body.division);
        sessionStorage.setItem("divisionID", res.body.divisionID);
        sessionStorage.setItem(
          "voucherProgramsDetails",
          JSON.stringify(res.body.voucherPrograms)
        );
        sessionStorage.setItem("isAdmin", res.body.officer ? false : true);
        sessionStorage.setItem(
          "defaultVoucherValidity",
          res.body.defaultVoucherValidity
        );
        sessionStorage.setItem(
          "defaultVoucherValue",
          res.body.defaultVoucherValue
        );
        alertifyjs.success("Signed Successfully", 5);
        window.location.href = "./";
      }
    });
  }

  return (
    <>
      <div className="container">
        <form
          onSubmit={(e) => login(e)}
          className="col-md-4 col-md-offset-4 form-signin"
        >
          <br />
          <h2 className="h3 mb-3 font-weight-normal text-center">WEYONJE</h2>
          <h4 className="font-weight-normal text-center">Admin Console</h4>
          <h3 className="text-center">Sign in</h3>
          <br />
          <br />
          <label htmlFor="inputEmail" className="sr-only">
            Email address
          </label>
          <input
            type="email"
            id="email_address"
            className="form-control"
            placeholder="Email address :"
            required
            autoFocus={true}
          />
          <br />
          <label htmlFor="inputPassword" className="sr-only">
            Password
          </label>
          <input
            autoComplete="true"
            type="password"
            id="password"
            className="form-control"
            placeholder="Password"
            required
          />
          <br />
          <br />
          <input
            type="checkbox"
            id="loginAsDO"
            name="loginAsDo"
            // value={checked}
            onChange={(e) => setIsDO(e.target.checked)}
          />
          <label htmlFor="loginAsDO" style={{ marginLeft: 10 }}>
            Login as Division Officer
          </label>
          <button className="btn btn-lg btn-primary btn-block" type="submit">
            Sign in
          </button>
          <br />
        </form>
      </div>
    </>
  );
}

export default Login;
