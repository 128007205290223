import React, { useState } from 'react';
import moment from 'moment';

import {
  BootstrapTable,
  TableHeaderColumn,
  ExportCSVButton,
} from 'react-bootstrap-table';

import { Modal, Button } from 'react-bootstrap';

import AddCATs from '../../components/AddCATs/addCats';
import CATSDetails from '../../components/CATSDetails/CATSDetails';

import {
  renderShowsTotal,
  timeConvert,
  tdStyle,
} from '../utils/tableUtilities';

import '../../styles/voucher.css';

const gSession = window.sessionStorage;
const division = gSession.getItem('division');

const ActionButton = ({ row, isAdmin }) => {
  const [showSPModal, setShowSPModal] = useState(false);

  const handleShowSPModal = () => {
    setShowSPModal(!showSPModal);
  };
  return (
    <>
      <Button
        style={{ marginRight: '10px' }}
        bsStyle='primary'
        onClick={handleShowSPModal}
      >
        View details
      </Button>

      <Modal show={showSPModal} onHide={handleShowSPModal}>
        <Modal.Header closeButton>
          <Modal.Title>{row.full_name}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ height: 450 }}>
          <CATSDetails close={handleShowSPModal} cats={row} sp={true} />
        </Modal.Body>
      </Modal>
    </>
  );
};

const formatSPStatus = (cell) => {
  return cell === true ? 'Yes' : 'No';
};

const AddSPButton = ({ row, isAdmin }) => {
  const [showAddSPModal, setShowAddSPModal] = useState(false);

  const handleShowAddSPModal = () => {
    setShowAddSPModal(!showAddSPModal);
  };

  return (
    <>
      <Button
        style={{ float: 'right', marginBottom: '2rem', marginRight: '10px' }}
        bsStyle='primary'
        onClick={handleShowAddSPModal}
      >
        Add service provider
      </Button>
      <Modal show={showAddSPModal} onHide={handleShowAddSPModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add Voucher SP for {division}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ height: 620 }}>
          <AddCATs close={handleShowAddSPModal} sp={true} />
        </Modal.Body>
      </Modal>
    </>
  );
};

const VoucherSPTable = ({ sps, isAdmin }) => {
  const actionButton = (cell, row, enumObject, rowIndex) => {
    return (
      <ActionButton
        cell={cell}
        row={row}
        rowIndex={rowIndex}
        isAdmin={isAdmin}
      />
    );
  };

  const createCustomExportCSVButton = (onClick) => {
    return (
      <ExportCSVButton
        btnText='Export to CSV'
        btnGlyphicon='glyphicon-download'
        onClick={() => handleExportCSVButtonClick(onClick)}
      />
    );
  };

  const handleExportCSVButtonClick = (onClick) => {
    onClick();
  };

  const options = {
    exportCSVBtn: createCustomExportCSVButton,
    page: 1,
    sizePerPageList: [
      {
        text: '10',
        value: 10,
      },
      {
        text: '25',
        value: 25,
      },
      {
        text: 'All',
        value: sps && sps.length,
      },
    ],
    sizePerPage: 15,
    pageStartIndex: 1,
    paginationSize: 3,
    prePage: 'Prev',
    nextPage: 'Next',
    firstPage: 'First',
    lastPage: 'Last',
    paginationShowsTotal: renderShowsTotal,
    paginationPosition: 'bottom',
    // onRowClick: onRowClick,
  };
  return (
    <div className='voucher-table-container'>
      <AddSPButton />
      <BootstrapTable
        data={sps}
        striped
        hover
        pagination={true}
        options={options}
        exportCSV
        csvFileName={
          'voucher_service_providers_' +
          moment(Date.now()).local().format('YYYY_MM_DD_HHmmss') +
          '.csv'
        }
        thStyle
      >
        <TableHeaderColumn
          isKey
          dataSort={true}
          dataField='full_name'
          thStyle={tdStyle}
          tdStyle={tdStyle}
          csvHeader='Service Provider Name'
        >
          SP Name
        </TableHeaderColumn>
        <TableHeaderColumn
          dataSort={true}
          dataField='org_name'
          thStyle={tdStyle}
          tdStyle={tdStyle}
          csvHeader='Organization Name'
        >
          Organization
        </TableHeaderColumn>
        <TableHeaderColumn
          dataSort={true}
          dataField='phone_number'
          thStyle={tdStyle}
          tdStyle={tdStyle}
          csvHeader='Phone Number'
        >
          Phone number
        </TableHeaderColumn>
        <TableHeaderColumn
          dataSort={true}
          dataField='work_address'
          thStyle={tdStyle}
          tdStyle={tdStyle}
          csvHeader='Work Address / Division'
        >
          Work address
        </TableHeaderColumn>

        <TableHeaderColumn
          dataSort={true}
          dataField='plate'
          thStyle={tdStyle}
          tdStyle={tdStyle}
          csvHeader='Number Plate'
        >
          Number plate
        </TableHeaderColumn>

        <TableHeaderColumn
          dataSort={true}
          dataField='type'
          thStyle={tdStyle}
          tdStyle={tdStyle}
          csvHeader='Service Provider Type'
        >
          Type
        </TableHeaderColumn>
        <TableHeaderColumn
          dataSort={true}
          dataField='no_of_jobs'
          thStyle={tdStyle}
          tdStyle={tdStyle}
          csvHeader='Number of Completed Jobs'
        >
          Completed jobs
        </TableHeaderColumn>
        <TableHeaderColumn
          dataSort={true}
          dataField='approved'
          thStyle={tdStyle}
          tdStyle={tdStyle}
          dataFormat={formatSPStatus}
          csvHeader='Approved'
        >
          Approved
        </TableHeaderColumn>

        <TableHeaderColumn
          dataSort={true}
          dataField='createdAt'
          dataFormat={timeConvert}
          width='130px'
          thStyle={tdStyle}
          tdStyle={tdStyle}
          csvHeader='Date Created'
        >
          Created At
        </TableHeaderColumn>

        <TableHeaderColumn
          dataField='action'
          dataFormat={actionButton}
          dataAlign='center'
          thStyle={tdStyle}
          tdStyle={tdStyle}
        >
          Action
        </TableHeaderColumn>
      </BootstrapTable>
    </div>
  );
};

export default VoucherSPTable;
