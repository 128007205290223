// Chart Options
import { commonChartOptions } from "./chartOptions";
import { getParishData, getInformalSectorData } from "./utils";
import { mapColors } from "./chartColors";

// Maps
let parishes = require("./maps/parishes.json");
let divisions = require("./maps/divisions.json");
let informalSettlements = require("./maps/informal_settlements.json");

const dumpingByDivisionsMap = (data) => {
  let mapData = [
    [
      "CENTRAL",
      data
        .map((t) => t.totalNumberOfDumpingTripsFromCentral)
        .reduce((accumulator, currentValue) => accumulator + currentValue, 0),
    ],
    [
      "KAWEMPE",
      data
        .map((t) => t.totalNumberOfDumpingTripsFromKawempe)
        .reduce((accumulator, currentValue) => accumulator + currentValue, 0),
    ],
    [
      "MAKINDYE",
      data
        .map((t) => t.totalNumberOfDumpingTripsFromMakindye)
        .reduce((accumulator, currentValue) => accumulator + currentValue, 0),
    ],
    [
      "NAKAWA",
      data
        .map((t) => t.totalNumberOfDumpingTripsFromNakawa)
        .reduce((accumulator, currentValue) => accumulator + currentValue, 0),
    ],
    [
      "RUBAGA",
      data
        .map((t) => t.totalNumberOfDumpingTripsFromRubaga)
        .reduce((accumulator, currentValue) => accumulator + currentValue, 0),
    ],
  ];

  return {
    ...commonChartOptions,
    chart: {
      height: 90 + "%",
      map: divisions,
    },
    title: {
      text: "Dumping Trips Per Division",
    },
    mapNavigation: {
      mapNavigation: { ...commonChartOptions.mapNavigation },
    },
    colorAxis: {
      tickPixelInterval: 100,
      maxColor: mapColors.dumpingTrips,
    },
    tooltip: {
      formatter: function() {
        return (
          "<b><u>" +
          this.point.properties.SNAME2014 +
          "</u></b><br/><br/>" +
          "Dumping trips: " +
          this.point.value
        );
      },
    },
    series: [
      {
        data: mapData,
        keys: ["SNAME2014", "value"],
        joinBy: "SNAME2014",
        name: "Dumping Trips",
        borderColor: "white",
        borderWidth: 0.5,
        states: {
          hover: {
            color: "#a4edba",
          },
        },
        dataLabels: {
          enabled: true,
          format: "{point.properties.SNAME2014}",
        },
      },
    ],
  };
};

const emptyingTripsByParishMap = (data) => {
  let mapData = getParishData(data, "totalNumberOfEmptyingTrips");

  return {
    ...commonChartOptions,
    chart: {
      height: 90 + "%",
      map: parishes,
    },
    title: {
      text: "Emptying trips per parish",
    },
    mapNavigation: {
      mapNavigation: { ...commonChartOptions.mapNavigation },
    },
    legend: {
      title: {
        text: "Legend",
      },
      align: "right",
      verticalAlign: "bottom",
      floating: true,
      layout: "vertical",
      valueDecimals: 0,
      backgroundColor: "rgba(255,255,255,0.9)",
      symbolRadius: 0,
      symbolHeight: 14,
    },
    colorAxis: {
      dataClasses: [
        {
          to: 3,
        },
        {
          from: 3,
          to: 10,
        },
        {
          from: 10,
          to: 20,
        },
        {
          from: 20,
          to: 30,
        },
        {
          from: 30,
          to: 40,
        },
        {
          from: 40,
          to: 50,
        },
        {
          from: 50,
        },
      ],
      minColor: mapColors.emptyingTripsMinColor,
      maxColor: mapColors.emptyingTripsMaxColor,
    },
    tooltip: {
      formatter: function() {
        return (
          "<b><u>" +
          this.point.properties.PNAME2014 +
          "</u></b><br/><br/>" +
          "Total trips: " +
          this.point.value +
          "<br/><br/>" +
          "Gulpers: " +
          +new Intl.NumberFormat("lg-UG").format(
            Math.ceil(
              data
                .filter(
                  ({ parish }) => parish === this.point.properties.PNAME2014
                )
                .map((t) => t.emptyingTripsByGulpers)
                .reduce(
                  (accumulator, currentValue) => accumulator + currentValue,
                  0
                )
            )
          ) +
          "<br><br>" +
          "Emptiers: " +
          +new Intl.NumberFormat("lg-UG").format(
            Math.ceil(
              data
                .filter(
                  ({ parish }) => parish === this.point.properties.PNAME2014
                )
                .map((t) => t.emptyingTripsByEmptiers)
                .reduce(
                  (accumulator, currentValue) => accumulator + currentValue,
                  0
                )
            )
          ) +
          "<br><br>" +
          "Other SP: " +
          +new Intl.NumberFormat("lg-UG").format(
            Math.ceil(
              data
                .filter(
                  ({ parish }) => parish === this.point.properties.PNAME2014
                )
                .map((t) => t.emptyingTripsByOther)
                .reduce(
                  (accumulator, currentValue) => accumulator + currentValue,
                  0
                )
            )
          )
        );
      },
    },
    series: [
      {
        data: mapData,
        keys: ["PNAME2014", "value"],
        joinBy: "PNAME2014",
        name: "Emtpying trips",
        borderColor: "white",
        borderWidth: 0.5,
        states: {
          hover: {
            color: "#a4edba",
            borderWidth: 10,
          },
        },
        tooltip: {
          valueSuffix: " trips",
        },
      },
    ],
  };
};

const emptyingTripsFromInformalSettlementsMap = (data) => {
  let mapData = getParishData(data, "emptyingTripsFromInformalSector", false);

  let chart = {
    ...commonChartOptions,
    chart: {
      height: 90 + "%",
      map: informalSettlements,
    },
    title: {
      text: "Emptying trips from informal settlements per parish",
    },
    mapNavigation: {
      mapNavigation: { ...commonChartOptions.mapNavigation },
    },
    legend: {
      title: {
        text: "Legend",
      },
      align: "right",
      verticalAlign: "bottom",
      floating: true,
      layout: "vertical",
      valueDecimals: 0,
      backgroundColor: "rgba(255,255,255,0.9)",
      symbolRadius: 0,
      symbolHeight: 14,
    },
    colorAxis: {
      dataClasses: [
        {
          to: 3,
        },
        {
          from: 3,
          to: 10,
        },
        {
          from: 10,
          to: 20,
        },
        {
          from: 20,
          to: 30,
        },
        {
          from: 30,
          to: 40,
        },
        {
          from: 40,
          to: 50,
        },
        {
          from: 50,
        },
      ],
      minColor: mapColors.emptyingTripsMinColor,
      maxColor: mapColors.emptyingTripsMaxColor,
    },
    tooltip: {
      formatter: function() {
        return (
          "<b><u>" +
          this.point.properties.Slum_Name +
          "</u></b><br/><br/>" +
          "Total trips: " +
          this.point.value
        );
      },
    },
    series: [
      {
        data: mapData,
        keys: ["Slum_Name", "value"],
        joinBy: "Slum_Name",
        name: "Emtpying trips",
        borderColor: "white",
        borderWidth: 0.5,
        states: {
          hover: {
            color: "#a4edba",
            borderWidth: 10,
          },
        },
        tooltip: {
          valueSuffix: " trips",
        },
      },
    ],
  };

  return chart;
};

export {
  dumpingByDivisionsMap,
  emptyingTripsByParishMap,
  emptyingTripsFromInformalSettlementsMap,
};
