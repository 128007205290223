import React, { Component } from "react";
import { CustomLoading, Loading } from "./inc/Layout";
import { LiveTracking } from "./LiveTracking.jsx";
import { JobsNew } from "./Jobs.jsx";
import { Sps } from "./Sp_new";
import { Customers } from "./Customers";
import { Dumps } from "./Dumps";
import { NotFound } from "./Common.jsx";
import Login from "./Login";
import Dashboard from "../views/Dashboard/Dashboard";
import Voucher from "../views/Voucher/Voucher";
import VoucherDetails from "../views/Voucher/VoucherDetails";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import ServiceProviderProfile from "./ServiceProvider";
import CustomerProfile from "./Customer";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import "@fortawesome/fontawesome-free/css/fontawesome.min.css";
import { Dispatchers } from "./Dispatchers";
import { Privacy } from "./PrivacyPolicy";
import "alertifyjs/build/css/alertify.min.css";
import "alertifyjs/build/css/themes/semantic.min.css";
const alertifyjs = require("alertifyjs");
const addr = require("../env_config").default;
const gSession = window.sessionStorage;
const auth = gSession.getItem("wat");
NProgress.start();

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: false,
      token: "",
      isLoaded: false,
    };
    this.IsAdminLoggedin.bind(this);
    if (
      window.location.pathname == "/login" ||
      window.location.pathname == "/privacy"
    ) {
      //do not authenticate
    } else {
      this.IsAdminLoggedin();
    }
  }

  IsAdminLoggedin() {
    const thisApp = this;
    let status;
    fetch(addr + "auth/admin/login/verify", {
      headers: {
        authorization: auth,
      },
    })
      .then((res) => {
        status = res.status;
        res.json();
      })
      .then(function(data) {
        if (status != 200) {
          thisApp.setState(
            {
              isLoggedIn: false,
              token: "",
              isLoaded: false,
            },
            () => console.log(thisApp.state)
          );
          window.location.href = "/login";
        } else {
          thisApp.setState(
            {
              isLoggedIn: true,
              token: auth,
              isLoaded: true,
            }
            // () => console.log(thisApp.state)
          );
        }
      })
      .catch(function(error) {
        thisApp.setState(
          {
            isLoggedIn: false,
            token: "",
            isLoaded: true,
          },
          () => console.log(thisApp.state)
        );
        console.log(JSON.stringify(error));
        window.location.href = "/login";
      });
  }

  componentDidMount() {
    NProgress.done();
    window.addEventListener("offline", function(e) {
      console.log(
        "Application is offline, please check your internet connection"
      );
      alertifyjs.error(
        "Application is offline, please check your internet connection",
        3,
        function() {
          console.log("dismissed");
        }
      );
    });
  }
  render() {
    return (
      <Router basename="/">
        <Switch>
          <Route
            exact
            path="/"
            render={() =>
              this.state.isLoaded ? <LiveTracking /> : <Loading />
            }
          />
          <Route exact path="/jobs" render={() => <JobsNew />} />

          <Route exact path="/dashboard" render={() => <Dashboard />} />
          <Route exact path="/jobs/new" render={() => <JobsNew />} />

          <Route exact path="/sps" render={() => <Sps />} />
          <Route exact path="/customers" render={() => <Customers />} />
          <Route exact path="/dumps" render={() => <Dumps />} />

          <Route exact path="/dispatchers" render={() => <Dispatchers />} />
          <Route exact path="/vouchers" render={() => <Voucher />} />

          <Route exact path="/sp/:id" component={ServiceProviderProfile} />
          <Route exact path="/customer/:id" component={CustomerProfile} />
          <Route exact path="/vouchers/:id" component={VoucherDetails} />

          <Route exact path="/privacy" render={() => <Privacy />} />
          <Route
            exact
            path="/login"
            render={() =>
              this.state.isLoggedIn ? <Redirect to="/" /> : <Login />
            }
          />
          <Route component={NotFound} />
        </Switch>
      </Router>
    );
  }
}

export default App;
