import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";

import "../../styles/dashboard.css";
export class StatsCard extends Component {
  render() {
    return (
      <div className="card card-stats">
        <div className="content">
          <Row>
            <Col xs={12}>
              <div className="numbers">
                <p>{this.props.statsText}</p>
                {/* {this.props.statsValue} */}
                <div>
                  <div className="statsValue">
                    {this.props.statsValue}
                    <sup>
                      {this.props.statsSymbol}
                      <sup>{this.props.statsExtraSymbol}</sup>
                    </sup>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <div className="footer">
            <hr />
            <div className="stats">
              <div>
                {this.props.difference > 0 ? (
                  <i className="down" />
                ) : this.props.difference < 0 ? (
                  <i className="up" />
                ) : (
                  <i />
                )}

                {isNaN(this.props.difference) ? (
                  <b />
                ) : (
                  <b> {Math.abs(this.props.difference)} %</b>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default StatsCard;
