import React, { useState, useEffect } from "react";
import Switch from "react-toggle-switch";
import "alertifyjs/build/css/alertify.min.css";
import "alertifyjs/build/css/themes/semantic.min.css";

import { CustomLoading } from "../inc/Layout";

const addressEndpoint = require("../../env_config").default;
const alertifyjs = require("alertifyjs");
const gSession = window.sessionStorage;
const token = gSession.getItem("wat");
const division = gSession.getItem("division");
const divisionID = gSession.getItem("divisionID");

const DEFAULT_OPTIONS = {
  headers: {
    cache: "no-store",
    credentials: "same-origin",
    authorization: token,
    "content-type": "application/json",
  },
};

const CATSDetails = ({ close, sp }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [fullName, setFullName] = useState("");
  const [phone, setPhone] = useState("");
  const [isApproved, setIsApproved] = useState(false);
  const [isVoucherSP, setIsVoucherSP] = useState(false);
  const [workAddress, setWorkAddress] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [type, setType] = useState("");
  const [orgName, setOrgName] = useState("");
  const [plate, setPlate] = useState("");

  const toggleSwitch = () => {
    setIsApproved(!isApproved);
  };

  const toggleSPVoucherSwitch = () => {
    setIsVoucherSP(!isVoucherSP);
  };

  const handleSubmit = async (e) => {
    if (password !== confirmPassword) {
      setErrorMessage("Passwords do not match");
      return;
    }

    e.preventDefault();

    const payload = {
      full_name: fullName,
      phone_number: phone,
      ...(sp && {
        org: {
          division: divisionID,
          name: orgName,
          plate: plate,
        },
        type,
        work_address: workAddress,
        approved: isApproved,
        password,
        isVoucherSP,
      }),

      ...(!sp && {
        isApprovedByDO: isApproved,
        division,
      }),
    };

    const url = sp
      ? addressEndpoint + `auth/entreprenuer/register`
      : addressEndpoint + `api/v1/cats/`;

    await fetch(url, {
      method: "POST",
      body: JSON.stringify(payload),
      ...DEFAULT_OPTIONS,
    })
      .then((result) => {
        if (!result.ok) throw result;
        return result.json();
      })
      .then((res) => {
        setLoading(false);
        close();

        alertifyjs.success(
          `Successfully added ${sp ? "SP" : "CATS"} ${fullName}`,
          10
        );

        window.location.href = "/vouchers";
      })
      .catch((error) => {
        error.json().then((body) => {
          setLoading(false);
          setErrorMessage(body.error);
        });
      });
  };

  useEffect(() => {
    if (password !== confirmPassword) {
      setErrorMessage("Passwords do not match");
    } else {
      setErrorMessage("");
    }
  }, [password, confirmPassword]);

  return (
    <div className="bootstrap-iso">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12">
            {loading ? (
              <CustomLoading text={`Adding ${sp ? "SP" : "VHT"} ${fullName}`} />
            ) : (
              <form
                onSubmit={(e) => handleSubmit(e)}
                style={{ marginTop: "50px" }}
              >
                <div className="col-md-12 editCATForm">
                  <div className="form-group ">
                    <label
                      className="control-label requiredField"
                      htmlFor="full_name"
                    >
                      Full name (*)
                    </label>
                  </div>

                  <div className="form-group ">
                    <input
                      placeholder="First name Last name"
                      className="form-control"
                      id="full_name"
                      name="full_name"
                      onChange={(e) => setFullName(e.target.value)}
                      style={{ width: "500px" }}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-12 editCATForm">
                  <div className="form-group ">
                    <label
                      className="control-label requiredField"
                      htmlFor="phone_number"
                    >
                      Phone number (*)
                    </label>
                  </div>

                  <div className="form-group">
                    <input
                      placeholder="Will be used for login"
                      className="form-control"
                      id="phone_number"
                      name="phone_number"
                      onChange={(e) => setPhone(e.target.value)}
                      style={{ width: "500px" }}
                      required
                    />
                  </div>
                </div>

                {sp && (
                  <div className="col-md-12 editCATForm">
                    <>
                      <div className="form-group ">
                        <label
                          className="control-label requiredField"
                          htmlFor="org_name"
                        >
                          Service provider type (*)
                        </label>
                      </div>

                      <div className="form-group ">
                        <select
                          className="form-control"
                          id="type"
                          name="type"
                          onChange={(e) => setType(e.target.value)}
                          style={{ width: "500px" }}
                          required
                        >
                          <option value="Emptier">Select type</option>
                          <option value="Emptier">Emptier</option>
                          <option value="Gulper">Gulper</option>
                        </select>
                      </div>
                    </>
                  </div>
                )}

                {sp && (
                  <div className="col-md-12 editCATForm">
                    <>
                      <div className="form-group ">
                        <label
                          className="control-label requiredField"
                          htmlFor="org_name"
                        >
                          Organization name (*)
                        </label>
                      </div>

                      <div className="form-group ">
                        <input
                          className="form-control"
                          id="org_name"
                          name="org_name"
                          onChange={(e) => setOrgName(e.target.value)}
                          style={{ width: "500px" }}
                          required
                        />
                      </div>
                    </>
                  </div>
                )}

                {sp && (
                  <div className="col-md-12 editCATForm">
                    <>
                      <div className="form-group ">
                        <label
                          className="control-label requiredField"
                          htmlFor="plate"
                        >
                          Licence plate (*)
                        </label>
                      </div>

                      <div className="form-group ">
                        <input
                          className="form-control"
                          id="plate"
                          name="plate"
                          onChange={(e) => setPlate(e.target.value)}
                          style={{ width: "500px" }}
                          required
                        />
                      </div>
                    </>
                  </div>
                )}

                {sp && (
                  <div className="col-md-12 editCATForm">
                    <>
                      <div className="form-group ">
                        <label
                          className="control-label requiredField"
                          htmlFor="work_address"
                        >
                          Work address (*)
                        </label>
                      </div>

                      <div className="form-group ">
                        <input
                          className="form-control"
                          id="work_address"
                          name="work_address"
                          onChange={(e) => setWorkAddress(e.target.value)}
                          style={{ width: "500px" }}
                          required
                        />
                      </div>
                    </>
                  </div>
                )}

                {sp && (
                  <div className="col-md-12 editCATForm">
                    <>
                      <div className="form-group ">
                        <label
                          className="control-label requiredField"
                          htmlFor="password"
                        >
                          Password (*)
                        </label>
                      </div>

                      <div className="form-group ">
                        <input
                          type="password"
                          className="form-control"
                          id="password"
                          name="password"
                          onChange={(e) => setPassword(e.target.value)}
                          style={{ width: "500px" }}
                          required
                        />
                      </div>
                    </>
                  </div>
                )}

                {sp && (
                  <div className="col-md-12 editCATForm">
                    <>
                      <div className="form-group ">
                        <label
                          className="control-label requiredField"
                          htmlFor="confirm-password"
                        >
                          Confirm password (*)
                        </label>
                      </div>

                      <div className="form-group ">
                        <input
                          type="password"
                          className="form-control"
                          id="confirm-password"
                          name="confirm-password"
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          style={{ width: "500px" }}
                          required
                        />
                      </div>
                    </>
                  </div>
                )}

                {sp && (
                  <div className="col-md-12 editCATForm">
                    <div className="form-group ">
                      <label
                        className="control-label requiredField"
                        htmlFor="full_name"
                      >
                        Approved for voucher jobs (*)
                      </label>
                    </div>

                    <Switch onClick={toggleSPVoucherSwitch} on={isVoucherSP} />
                  </div>
                )}
                <div className="col-md-12 editCATForm">
                  <div className="form-group ">
                    <label
                      className="control-label requiredField"
                      htmlFor="full_name"
                    >
                      Active (If inactive, they will not be able to login) (*)
                    </label>
                  </div>

                  <Switch onClick={toggleSwitch} on={isApproved} />
                </div>

                {errorMessage && (
                  <div className="col-md-12">
                    <p style={{ color: "red" }}>
                      {errorMessage.message || errorMessage}
                    </p>
                  </div>
                )}

                <div className="col-md-12">
                  <div className="form-group">
                    <button
                      className="btn btn-primary "
                      name="submit"
                      type="submit"
                      disabled={sp && password !== confirmPassword}
                    >
                      SAVE
                    </button>
                    &nbsp; &nbsp; &nbsp;
                    <button
                      onClick={close}
                      className="btn btn-default "
                      name="cancel"
                      type="button"
                    >
                      CANCEL
                    </button>
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CATSDetails;
