import React, { Component } from 'react';
import { Layout, CustomLoading } from './inc/Layout';
import { NavDropdown, MenuItem } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWater } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { Check } from './Jobs';
import {
  BootstrapTable,
  TableHeaderColumn,
  ExportCSVButton,
} from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';

//
const addr = require('../env_config').default;
var unirest = require('unirest');
const gSession = window.sessionStorage;
const token = gSession.getItem('wat');

export class Sps extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sps: [],
      type: 'All',
      isLoaded: false,
      manageColomns: {
        full_name: false,
        last_seen: false,
        no_of_dumps: false,
        no_of_jobs: false,
        phone_number: false,
        type: false,
      },
    };
    this.getData = this.getData.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }
  componentDidMount() {
    //passin mentor id
    this.getData();
  }
  getData(url = 'api/entreprenuers/all/1') {
    const thisApp = this;
    let status;
    var req = unirest('GET', addr + url);

    req.headers({
      'cache-control': 'no-cache',
      authorization: token,
    });

    req.end(function (res) {
      if (res.error) console.log(res);

      if (res.status == 200) {
        let x = [];
        //  let q = x.push(res.body);
        thisApp.setState({
          sps: res.body.data,
          isLoaded: true,
        });
      } else {
        thisApp.setState({
          isLoaded: true,
        });
      }
    });
  }
  onRowClick(row) {
    window.location.href = '/sp/' + row._id;
  }
  timeConvert(cell, row) {
    return cell && cell
      ? moment(cell).format('DD/MM/YY h:mm:ss a')
      : 'Date not available';
  }
  dumpsFormat(cell, row) {
    let old_dumps = row.no_of_dumps_old;
    let new_dumps = row.no_of_dumps_new;
    let total = parseInt(old_dumps + new_dumps);
    return total;
  }
  timeFromNow(cell, row) {
    return cell && cell ? moment(cell).fromNow() : 'Date not available';
  }

  enumFormatter(cell, row, enumObject) {
    return enumObject[cell];
  }
  handleExportCSVButtonClick = (onClick) => {
    // Custom your onClick event here,
    // it's not necessary to implement this function if you have no any process before onClick
    onClick();
  };
  createCustomExportCSVButton = (onClick) => {
    return (
      <button className='btn btn-primary' onClick={onClick}>
        Export to CSV
      </button>
    );
  };

  renderShowsTotal(start, to, total) {
    return (
      <p style={{ color: 'blue' }}>
        From {start} to {to}, totals is {total}&nbsp;&nbsp;
      </p>
    );
  }
  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState(
      {
        [name]: value,
      },
      () =>
        //status
        //from
        //to
        this.getData('api/entreprenuers/all/1?type=' + this.state.type)
    );
  }

  updateTable(colomn) {
    //make a copy of state
    let manageColomns = this.state.manageColomns;

    if (this.state.manageColomns[colomn] === true) {
      manageColomns[colomn] = false;
      this.setState({
        manageColomns: manageColomns,
      });
    } else {
      manageColomns[colomn] = true;
      this.setState({
        manageColomns: manageColomns,
      });
    }
  }
  handleExportCSVButtonClick = (onClick) => {
    const thisApp = this;

    onClick();
    // this.returnAlltheData(function(){
    //   thisApp.refs.table.handleExportCSV();
    // });
  };
  createCustomExportCSVButton = (onClick) => {
    return (
      <ExportCSVButton
        btnText='Export to CSV'
        btnGlyphicon='glyphicon-download'
        onClick={() => this.handleExportCSVButtonClick(onClick)}
      />
    );
  };

  render() {
    let products = this.state.sps;

    const statusType = {
      Gulper: 'Gulper',
      Emptier: 'Emptier',
    };

    const options = {
      exportCSVBtn: this.createCustomExportCSVButton,
      page: 1, // which page you want to show as default
      sizePerPageList: [
        {
          text: '10',
          value: 10,
        },
        {
          text: '25',
          value: 25,
        },
        {
          text: 'All',
          value: products && products.length,
        },
      ], // you can change the dropdown list for size per page
      // exportCSVBtn: this.createCustomExportCSVButton,
      sizePerPage: 10, // which size per page you want to locate as default
      pageStartIndex: 1, // where to start counting the pages
      paginationSize: 3, // the pagination bar size.
      prePage: 'Prev', // Previous page button text
      nextPage: 'Next', // Next page button text
      firstPage: 'First', // First page button text
      lastPage: 'Last', // Last page button text
      paginationPosition: 'bottom', // default is bottom, top and both is all available
      onRowClick: this.onRowClick,
      // hideSizePerPage: true > You can hide the dropdown for sizePerPage
      // alwaysShowAllBtns: true // Always show next and previous button
      // withFirstAndLast: false > Hide the going to First and Last page button
    };
    let typeList = ['Gulper', 'Emptier'];
    let typeFilter = typeList.map((item) => (
      <option value={item}>{item}</option>
    ));

    return (
      <Layout>
        <div className='col-md-8 title'>
          <h4>
            {' '}
            <FontAwesomeIcon icon={faWater} /> Service provider (SP)
          </h4>
          <br />
        </div>
        <div className='col-md-12'>
          <form class='form-inline'>
            <div class='form-group'>
              <label for='email'>Type of Service provider:</label>
              <select
                name='type'
                value={this.state.type}
                onChange={this.handleInputChange}
                className='form-control'
              >
                <option value='All'>All</option>
                {typeFilter}
              </select>
            </div>

            <NavDropdown
              eventKey={3}
              className='pull-right'
              title='Manage columns'
              id='basic-nav-dropdown'
            >
              <MenuItem
                onClick={(e, full_name) => this.updateTable('full_name')}
                eventKey={3.1}
              >
                <Check state={this.state.manageColomns.full_name} /> Full name
              </MenuItem>
              <MenuItem
                onClick={(e, phone_number) => this.updateTable('phone_number')}
                eventKey={3.1}
              >
                <Check state={this.state.manageColomns.phone_number} /> Phone
                number
              </MenuItem>
              <MenuItem
                onClick={(e, no_of_dumps) => this.updateTable('no_of_dumps')}
                eventKey={3.1}
              >
                <Check state={this.state.manageColomns.no_of_dumps} /> No of
                Dumps
              </MenuItem>
              <MenuItem
                onClick={(e, no_of_jobs) => this.updateTable('no_of_jobs')}
                eventKey={3.1}
              >
                <Check state={this.state.manageColomns.no_of_jobs} /> No of Jobs
              </MenuItem>
              <MenuItem
                onClick={(e, type) => this.updateTable('type')}
                eventKey={3.1}
              >
                <Check state={this.state.manageColomns.type} /> Type
              </MenuItem>
              <MenuItem
                onClick={(e, last_seen) => this.updateTable('last_seen')}
                eventKey={3.1}
              >
                <Check state={this.state.manageColomns.last_seen} /> Last Seen
              </MenuItem>
            </NavDropdown>
          </form>
        </div>
        <div className='card padding-normal col-md-12'>
          {this.state.isLoaded === false ? (
            <CustomLoading text="Loading service providers' data"></CustomLoading>
          ) : (
            <BootstrapTable
              data={products}
              striped
              hover
              pagination={true}
              options={options}
              csvFileName={
                'service_providers_' +
                moment(Date.now()).local().format('YYYY_MM_DD_HHmmss') +
                '.csv'
              }
              headerContainerClass='table-header'
              tableContainerClass='table-responsive table-onScreen'
              exportCSV
              pagination
            >
              <TableHeaderColumn dataSort={true} isKey dataField='ent_id'>
                SP ID
              </TableHeaderColumn>
              <TableHeaderColumn
                hidden={this.state.manageColomns.full_name}
                dataSort={true}
                filter={{ type: 'TextFilter', delay: 20 }}
                dataField='full_name'
              >
                Full name
              </TableHeaderColumn>
              <TableHeaderColumn
                hidden={this.state.manageColomns.phone_number}
                dataField='phone_number'
                filter={{ type: 'TextFilter', delay: 20 }}
              >
                Phone number
              </TableHeaderColumn>
              <TableHeaderColumn
                hidden={this.state.manageColomns.no_of_dumps}
                dataSort={true}
                dataFormat={this.dumpsFormat}
              >
                No of Dumps
              </TableHeaderColumn>
              <TableHeaderColumn
                hidden={this.state.manageColomns.no_of_jobs}
                dataSort={true}
                dataSort={true}
                dataField='no_of_jobs'
              >
                No of Jobs
              </TableHeaderColumn>
              <TableHeaderColumn
                hidden={this.state.manageColomns.type}
                dataSort={true}
                dataField='type'
              >
                Type
              </TableHeaderColumn>
              <TableHeaderColumn
                hidden={this.state.manageColomns.last_seen}
                dataSort={true}
                dataField='last_seen'
                dataFormat={this.timeFromNow}
              >
                Last Seen
              </TableHeaderColumn>
            </BootstrapTable>
          )}
        </div>
      </Layout>
    );
  }
}
