import React from 'react';
import { Grid } from 'react-bootstrap';

// import DivisionsTable from '../DivisionsTable/DivisionsTable';
import DPHEView from '../DPHEView/DPHEView';

const gSession = window.sessionStorage;
const role = gSession.getItem('role');

function VoucherProgramView({ divisions, voucherProgram }) {
  const { data } = voucherProgram;

  return (
    <Grid fluid>
      {role === 'finance' || role === 'dphe' ? (
        <DPHEView programs={data} showActions={true} />
      ) : role === 'admin' ? (
        <DPHEView programs={data} showActions={false} divisions={divisions} />
      ) : (
        <> </>
      )}
    </Grid>
  );
}

export default VoucherProgramView;
