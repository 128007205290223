/**
 * Calculate % difference between last month and this month
 * Returns a number. +ve means there was a decrease. -ve means there was an increase
 * We handle this in the UI to show appropriate up and down icons.
 * Ex: [{category: 10}, {category: 5}] => 50%
 * @param {Oject} data
 * @param {String} category
 * @retuns int
 */

const calculatePercentageDifference = (data, category, decimals = 1) => {
  if (data && data.length > 1) {
    // Get unique months in data object
    let validmonths = [...new Set(data.map(m => m["month"]))];

    let lastMonthTotal = data
      .filter(({ month }) => month === validmonths[0])
      .map(i => i[category])
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

    let thisMonthTotal = data
      .filter(({ month }) => month === validmonths[validmonths.length - 1])
      .map(i => i[category])
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

    // To avoid infinity (any number divided by 0), we check if the denominator is 0,
    // if it is, we return 0, otherwise we calculate the % decrease / increase
    if (thisMonthTotal === 0 || isNaN(thisMonthTotal)) {
      return;
    } else {
      const calculation =
        ((parseInt(lastMonthTotal) - parseInt(thisMonthTotal)) /
          parseInt(thisMonthTotal)) *
        100;

      return calculation.toFixed(decimals);
    }
  } else {
    // Only one month //
    return;
  }
};

export { calculatePercentageDifference };
