import React, { Component } from 'react';
import { Layout, CustomLoading } from './inc/Layout';
import { NavDropdown, MenuItem } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTint, faTimes } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment';
import { Check } from './Jobs';
import 'alertifyjs/build/css/alertify.min.css';
import 'alertifyjs/build/css/themes/semantic.min.css';
import { BootstrapTable, TableHeaderColumn, ExportCSVButton } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';



const addr = require('../env_config').default;
var unirest = require("unirest");
const alertifyjs = require('alertifyjs');

const gSession = window.sessionStorage;
const token = gSession.getItem("wat");
const loggedInId = gSession.getItem("wai");

export class Customers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customers: [],
      customers_copy: [],
      totalDataSize:0,
      isLoaded: false,
      loadingText: "Loading...",
      facility_type:"All",
      visibility:"none",
      manageColomns: {
        address: false,
        division: false,
        emptying_trips: false,
        facility_type: false,
        full_name: false,
        last_serviced: true,
        phone_number: false

      },
      currentPage: 1
    }
    this.onPageChange = this.onPageChange.bind(this);
    this.onSortChange = this.onSortChange.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
    this.getData = this.getData.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.returnAlltheData = this.returnAlltheData.bind(this);
  }
  componentDidMount() {
    //passin mentor id
    this.getData();
  }
  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
      isLoaded:false
    }, () =>
        this.getData("api/customers?records=" + this.state.currentPage + "&facility_type=" + this.state.facility_type+ "&limit=" + this.state.sizePerPage)
    );
  }
  updateTable(colomn) {
    //make a copy of state
    let manageColomns = this.state.manageColomns;

    if (this.state.manageColomns[colomn] === true) {
      manageColomns[colomn] = false;
      this.setState({
        manageColomns: manageColomns
      })
    } else {
      manageColomns[colomn] = true;
      this.setState({
        manageColomns: manageColomns
      })
    }

  }
  returnAlltheData(onClick) {
    //"api/customers?records=1" +page + "&facility_type=" + this.state.facility_type+ "&limit=" + totalDataSize
    let url = "api/customers?records=1&facility_type=" + this.state.facility_type+ "&limit=" + this.state.totalDataSize;
    const thisApp = this;
    let x = [];
    thisApp.setState({
      isLoaded:false,
      loadingText:"Generating Customers CSV file, please wait..."
    });
    let status;
    var req = unirest("GET", addr + url);
    req.headers({
      "cache-control": "no-cache",
      "authorization": token
    });
    req.end(function (res) {
      if (res.error) console.log(res);
      if (res.status == 200) {
        
        thisApp.setState({
          customers:res.body.data,
          isLoaded: true
        },()=>{
            console.log(thisApp.state.jobs);
            onClick()       
        });
        
      }
      else {
        //
        alertifyjs.error('Failed to generate csv file, please check your internet connection and try again', 5, function(){  console.log('dismissed'); });
        thisApp.setState({
          isLoaded: true
        });
      }
    });
    return x;
  }

  getData(url = "api/customers?records=" + this.state.currentPage) {
    const thisApp = this;
    let status;
    var req = unirest("GET", addr + url);

    req.headers({
      "cache-control": "no-cache",
      "authorization": token
    });
    req.end(function (res) {
      if (res.error) console.log(res);

      console.log(res.status);
      if (res.status == 200) {
        console.log(res.body);
        let x = [];
        //  let q = x.push(res.body);
        thisApp.setState({
          customers: res.body.data,
          customers_copy: res.body.data,
          isLoaded: true,
          totalDataSize: res.body.total,
          sizePerPage: res.body.limit
        }, () => console.log(thisApp.state));
      }
      else {
        thisApp.setState({
          isLoaded: true
        }, () => console.log(thisApp.state));
      }
    });
  }
  timeConvert(cell, row) {

    return cell && cell ? (moment(cell).format("DD/MM/YY h:mm:ss a")) : ("Date not available");
  }

  enumFormatter(cell, row, enumObject) {
    return enumObject[cell];
  }
  handleExportCSVButtonClick = (onClick) => {
    // Custom your onClick event here,
    // it's not necessary to implement this function if you have no any process before onClick
    console.log('This is my custom function for ExportCSVButton click event');
    onClick();
  }
  createCustomExportCSVButton = (onClick) => {
    return (
      <button className="btn btn-primary" onClick={onClick}>Export to CSV</button>

    );
  }

  renderShowsTotal(start, to, total) {
    return (
      <p style={{ color: 'blue' }}>
        From {start} to {to}, totals is {total}&nbsp;&nbsp;
          </p>
    );
  }
  timeFromNow(cell, row) {
    //console.log(row);
    //.fromNow();
    return cell && cell ? (moment(cell).fromNow()) : ("Date not available");

  }
  onFilterChange(filterObj) {
    console.log("On Filter Change");
    this.setState({
      customers: this.state.customers_copy
    });
    if (Object.keys(filterObj).length === 0) {
      this.setState({
        customers: this.state.customers_copy
      });
      return;
    }
    const thisApp = this;
    const data = this.state.customers.filter((customer) => {
      let valid = true;
      let filterValue;
      for (const key in filterObj) {
        const targetValue = customer[key];
        switch (filterObj[key].type) {
          case 'NumberFilter': {
            filterValue = filterObj[key].value.number;
            valid = thisApp.filterNumber(targetValue, filterValue, filterObj[key].value.comparator);
            break;
          }
          default: {
            filterValue = (typeof filterObj[key].value === 'string') ?
              filterObj[key].value.toLowerCase() : filterObj[key].value;
            valid = thisApp.filterText(targetValue, filterValue);
            break;
          }
        }

        if (!valid) {
          break;
        }
      }
      return valid;
    });
    this.setState({
      customers: data
    });
  }
  filterNumber(targetVal, filterVal, comparator) {
    let valid = true;
    switch (comparator) {
      case '=': {
        if (targetVal !== filterVal) {
          valid = false;
        }
        break;
      }
      case '>': {
        if (targetVal <= filterVal) {
          valid = false;
        }
        break;
      }
      case '<=': {
        if (targetVal > filterVal) {
          valid = false;
        }
        break;
      }
      default: {
        console.error('Number comparator provided is not supported');
        break;
      }
    }
    return valid;
  }

  filterText(targetVal, filterVal) {
    if (targetVal.toString().toLowerCase().indexOf(filterVal) === -1) {
      return false;
    }

    return true;
  }
  onSortChange(sortName, sortOrder) {
    console.log(sortName);
    const x = this.state.customers
    if (sortOrder === 'asc') {
      x.sort(function (a, b) {
        console.log(JSON.stringify(a));
        if (parseInt(a[sortName], 10) > parseInt(b[sortName], 10)) {
          return 1;
        } else if (parseInt(b[sortName], 10) > parseInt(a[sortName], 10)) {
          return -1;
        }
        return 0;
      });
    } else {
      x.sort(function (a, b) {
        if (parseInt(a[sortName], 10) > parseInt(b[sortName], 10)) {
          return -1;
        } else if (parseInt(b[sortName], 10) > parseInt(a[sortName], 10)) {
          return 1;
        }
        return 0;
      });
    }

    this.setState({
      customers: x
    });
  }
  onPageChange(page, sizePerPage) {
    console.log("Page:" + page + " sizePerPage " + sizePerPage);
    const currentIndex = (page - 1) * sizePerPage;
    this.getData("api/customers?records=" +page + "&facility_type=" + this.state.facility_type+ "&limit=" + sizePerPage)
    this.setState({
      currentPage: page,
      isLoaded: false,
      sizePerPage: sizePerPage
    });
  }
  toNearest(number) {

    switch (true) {
      case number <= 10:
        return 1;
        break;
      case number <= 10:
        return number;
        break;
      case number <= 100:
        return Math.ceil(number / 5) * 5;
        break;
      case number > 100 < 1000:
        return Math.ceil(number / 50) * 50;
        break;
      case number >= 1000 < 10000:
        return Math.ceil(number / 500) * 500;
        break;
      case number >= 1000:
        return Math.ceil(number / 5000) * 5000;
        break;
      default:
        console.log("D");
        return 1;

    }

  }
  onRowClick(row){
    // return <Redirect to={"/sp/"+row._id}></Redirect>
     window.location.href="/customer/"+row.phone_number
   }
   handleExportCSVButtonClick = () => {
    const thisApp = this;
    this.returnAlltheData(function(){
      thisApp.refs.table.handleExportCSV();
    });
    
  }
  createCustomExportCSVButton = (onClick) => {
    return (
      <ExportCSVButton
        btnText='Export to CSV'
        btnGlyphicon='glyphicon-download'
        onClick={ () => this.handleExportCSVButtonClick() }/>
    );
  }


  render() {
    let products = this.state.customers
    let xxx = parseInt(this.state.totalDataSize) <= 50 ?(
      [{
      text: 'All', value: this.state.totalDataSize
    }]
    ):(
      [{
        text: this.toNearest(parseInt(this.state.totalDataSize / 5)), value: this.toNearest(parseInt(this.state.totalDataSize / 5))
      }, {
        text: this.toNearest(parseInt(this.state.totalDataSize / 4)), value: this.toNearest(parseInt(this.state.totalDataSize / 4))
      }, {
        text: this.toNearest(parseInt(this.state.totalDataSize / 3)), value: this.toNearest(parseInt(this.state.totalDataSize / 3))
      }
        , {
        text: this.toNearest(parseInt(this.state.totalDataSize / 2)), value: this.toNearest(parseInt(this.state.totalDataSize / 2))
      }, {
        text: 'All', value: this.state.totalDataSize
      }]
    )
    const options = {
      exportCSVBtn: this.createCustomExportCSVButton,
      page: this.state.currentPage,  // which page you want to show as default
      onPageChange: this.onPageChange,
      onSortChange: this.onSortChange,
      onRowClick: this.onRowClick,
      onFilterChange: this.onFilterChange,
      exportCSVBtn: this.createCustomExportCSVButton,
      sizePerPageList:xxx, // you can change the dropdown list for size per page
      sizePerPage: parseInt(this.state.sizePerPage),  // which size per page you want to locate as default
      pageStartIndex: 1, // where to start counting the pages
      paginationSize: 10,  // the pagination bar size.
      prePage: 'Prev', // Previous page button text
      nextPage: 'Next', // Next page button text
      firstPage: 'First', // First page button text
      lastPage: 'Last', // Last page button text
      paginationPosition: 'bottom'  // default is bottom, top and both is all available
      // hideSizePerPage: true > You can hide the dropdown for sizePerPage
      // alwaysShowAllBtns: true // Always show next and previous button
      // withFirstAndLast: false > Hide the going to First and Last page button
    };

    let ft = ['Lined', 'Unlined', 'Lined pit latrine with slab', 'Unlined pit latrine without slab', 'Flush toilet connected to septic tank', 'Unlined pit latrine with slab', 'Composting toilet'];

    let facilityTypeFilter = ft.map((item) =>
      <option value={item}>{item}</option>
    );


    return (
      <Layout>
        <div className="col-md-8 title">
          <h4> <FontAwesomeIcon icon={faTint} /> Customers</h4>
        </div>
        <div className="col-md-12">
          <form class="form-inline">

            <div class="form-group">
              <label for="email">Type of Facility:</label>
              <select name="facility_type" value={this.state.facility_type} onChange={this.handleInputChange} className="form-control">
                <option value="All">All</option>
                {facilityTypeFilter}
              </select>
            </div>

            <NavDropdown eventKey={3} className="pull-right" title="Manage columns" id="basic-nav-dropdown">
              <MenuItem onClick={(e, full_name) => this.updateTable("full_name")} eventKey={3.1}>
                <Check state={this.state.manageColomns.full_name} />  Full name
                  </MenuItem>
              <MenuItem onClick={(e, phone_number) => this.updateTable("phone_number")} eventKey={3.1}>
                <Check state={this.state.manageColomns.phone_number} /> Phone number
                  </MenuItem>
              <MenuItem onClick={(e, address) => this.updateTable("address")} eventKey={3.1}>
                <Check state={this.state.manageColomns.address} /> Address
                  </MenuItem>
                  <MenuItem onClick={(e, division) => this.updateTable("division")} eventKey={3.1}>
                <Check state={this.state.manageColomns.division} /> Division
                  </MenuItem>
              <MenuItem onClick={(e, emptying_trips) => this.updateTable("emptying_trips")} eventKey={3.1}>
                <Check state={this.state.manageColomns.emptying_trips} /> Emptying trips
                  </MenuItem>
              <MenuItem onClick={(e, facility_type) => this.updateTable("facility_type")} eventKey={3.1}>
                <Check state={this.state.manageColomns.facility_type} /> Facility type
                  </MenuItem>
              <MenuItem onClick={(e, last_serviced) => this.updateTable("last_serviced")} eventKey={3.1}>
                <Check state={this.state.manageColomns.last_serviced} /> Last serviced
                  </MenuItem>
            </NavDropdown>

          </form>

        </div>
        <div className="card padding-normal col-md-12">
          {this.state.isLoaded === false ? (
            <CustomLoading text={this.state.loadingText}></CustomLoading>
          ) : (<React.Fragment>
              <BootstrapTable data={products}
                headerContainerClass='table-header'
                tableContainerClass='table-responsive table-onScreen'
                striped
                csvFileName={'customers_'+moment(Date.now()).local().format("YYYY_MM_DD_HHmmss")+".csv"}
                remote={true}
                fetchInfo={{ dataTotalSize: this.state.totalDataSize }}
                hover
serviceprovider                ref='table'
                pagination={true}
                options={options} exportCSV pagination>
                <TableHeaderColumn hidden={true} width="80px" dataSort={true} dataField='customer_id'>CUST ID</TableHeaderColumn>
                <TableHeaderColumn isKey hidden={this.state.manageColomns.phone_number} filter={{ type: 'TextFilter', delay: 0 }} dataField='phone_number' >Customer phone_number</TableHeaderColumn>
                <TableHeaderColumn hidden={this.state.manageColomns.full_name} dataField='full_name' filter={{ type: 'TextFilter', delay: 0 }} dataSort={true}>Customer name</TableHeaderColumn>
                <TableHeaderColumn hidden={this.state.manageColomns.address} dataField='address' dataSort={true}>Customer address</TableHeaderColumn>
                <TableHeaderColumn hidden={this.state.manageColomns.division} dataField='division' dataSort={true}>Division</TableHeaderColumn>
                <TableHeaderColumn hidden={this.state.manageColomns.emptying_trips} dataField='emptying_trips' dataSort={true}>Emptying trips</TableHeaderColumn>
                <TableHeaderColumn hidden={this.state.manageColomns.facility_type} dataField='facility_type' dataSort={true} >Facility Type</TableHeaderColumn>
                <TableHeaderColumn hidden={this.state.manageColomns.last_serviced} dataField='last_serviced' dataSort={true} dataFormat={this.timeFromNow} >Last Serviced</TableHeaderColumn>

              </BootstrapTable>
              </React.Fragment>
            )}

        </div>
      </Layout>
    );
  }


}




