import React, { useState } from "react";
import { Button, Checkbox } from "react-bootstrap";

function PaymentDetails({ voucher, close }) {
  const [paid, setPaid] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(e);
    const payload = {
      paid,
      //   isApprovedByDO: editedStatus || voucher.isApprovedByDO,
    };
  };

  return (
    <div className="bootstrap-iso">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12">
            <form onSubmit={(e) => handleSubmit(e)}>
              <div className="col-md-12 editCATForm">
                <div className="form-group ">
                  <label
                    className="control-label requiredField"
                    htmlFor="full_name"
                  >
                    Voucher requested by (CATS /VHT)
                  </label>
                </div>

                <div className="form-group ">
                  <input
                    className="form-control"
                    id="full_name"
                    name="full_name"
                    defaultValue={voucher.catsName}
                    readOnly
                    style={{ width: "500px" }}
                  />
                </div>
              </div>
              <div className="col-md-12 editCATForm">
                <div className="form-group ">
                  <label
                    className="control-label requiredField"
                    htmlFor="full_name"
                  >
                    Voucher requested on
                  </label>
                </div>

                <div className="form-group ">
                  <input
                    className="form-control"
                    id="voucher_approved_on"
                    name="voucher_approved_on"
                    defaultValue={voucher.createdAt}
                    readOnly
                    style={{ width: "500px" }}
                  />
                </div>
              </div>
              <div className="col-md-12 editCATForm">
                <div className="form-group ">
                  <label
                    className="control-label requiredField"
                    htmlFor="full_name"
                  >
                    Voucher approved by (Division Officer)
                  </label>
                </div>

                <div className="form-group ">
                  <input
                    className="form-control"
                    id="voucher_approved_on"
                    name="voucher_approved_on"
                    defaultValue={voucher.approvedBy}
                    readOnly
                    style={{ width: "500px" }}
                  />
                </div>
              </div>

              <div className="col-md-12 editCATForm">
                <div className="form-group ">
                  <label
                    className="control-label requiredField"
                    htmlFor="full_name"
                  >
                    Voucher approved / generated on
                  </label>
                </div>

                <div className="form-group ">
                  <input
                    className="form-control"
                    id="voucher_approved_on"
                    name="voucher_approved_on"
                    defaultValue={voucher.approvedOn}
                    readOnly
                    style={{ width: "500px" }}
                  />
                </div>
              </div>

              <div className="col-md-12 editCATForm">
                <div className="form-group ">
                  <label
                    className="control-label requiredField"
                    htmlFor="full_name"
                  >
                    Service Provider
                  </label>
                </div>

                <div className="form-group ">
                  <input
                    className="form-control"
                    id="voucher_approved_on"
                    name="voucher_approved_on"
                    defaultValue={voucher.serviceProvider}
                    readOnly
                    style={{ width: "500px" }}
                  />
                </div>
              </div>
              <div className="col-md-12 editCATForm">
                <div className="form-group ">
                  <label
                    className="control-label requiredField"
                    htmlFor="full_name"
                  >
                    Voucher Dispatched to SP on
                  </label>
                </div>

                <div className="form-group ">
                  <input
                    className="form-control"
                    id="voucher_approved_on"
                    name="voucher_approved_on"
                    defaultValue={voucher.redeemedOn}
                    readOnly
                    style={{ width: "500px" }}
                  />
                </div>
              </div>

              <div className="col-md-12 editCATForm">
                <div className="form-group ">
                  <label
                    className="control-label requiredField"
                    htmlFor="full_name"
                  >
                    Job completed on
                  </label>
                </div>

                <div className="form-group">
                  <input
                    className="form-control"
                    id="voucher_job_completed_on"
                    name="voucher_job_completed_on"
                    defaultValue={voucher.jobCompletedOn}
                    readOnly
                    style={{ width: "500px" }}
                  />
                </div>
              </div>

              <div className="col-md-12 editCATForm">
                <div className="form-group ">
                  <label
                    className="control-label requiredField"
                    htmlFor="full_name"
                  >
                    CATs approval remarks
                  </label>
                </div>

                <div className="form-group">
                  <p>{voucher.catsApprovalRemarks}</p>
                </div>
              </div>

              <div className="col-md-12 editCATForm">
                <div className="form-group ">
                  <label
                    className="control-label requiredField"
                    htmlFor="full_name"
                  >
                    Division officer approval remarks
                  </label>
                </div>

                <div className="form-group">
                  <p>{voucher.officerApprovalRemarks}</p>
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <button
                    className="btn btn-primary "
                    name="submit"
                    type="submit"
                  >
                    SAVE
                  </button>
                  &nbsp; &nbsp; &nbsp;
                  <button
                    onClick={close}
                    className="btn btn-default "
                    name="cancel"
                    type="button"
                  >
                    CANCEL
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentDetails;
