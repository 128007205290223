import React, { useState } from "react";

import { CustomLoading } from "../../components/inc/Layout";
import "alertifyjs/build/css/alertify.min.css";
import "alertifyjs/build/css/themes/semantic.min.css";

import "../../styles/voucher.css";
const gSession = window.sessionStorage;
const token = gSession.getItem("wat");

const voucherProgramDetails = JSON.parse(
  gSession.getItem("voucherProgramsDetails")
);
const alertifyjs = require("alertifyjs");

const DEFAULT_OPTIONS = {
  headers: {
    cache: "no-store",
    credentials: "same-origin",
    authorization: token,
    "content-type": "application/json",
  },
};
const doID = gSession.getItem("wai");
const addressEndpoint = require("../../env_config").default;

export const ApproveVoucherRequest = ({ close, voucherDetails }) => {
  const program = voucherProgramDetails.find(
    (p) => p.programName === voucherDetails.voucherProgram
  );

  const today = new Date();
  today.setDate(today.getDate() + parseInt(program.defaultVoucherValidity));

  const dd = today.getDate();
  const mm = today.getMonth() + 1;
  const year = today.getFullYear();

  const value = program.defaultVoucherValue;
  const expiryDate = `${year}-0${mm}-${dd}`;

  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleApproveVoucher = async (e) => {
    setLoading(true);
    e.preventDefault();
    const url =
      addressEndpoint +
      `api/v1/officer/voucher/actionVoucher/${voucherDetails._id}`;

    const data = {
      doID,
      expiryDate,
      status: 1,
    };

    await fetch(url, {
      method: "PATCH",
      body: JSON.stringify(data),
      ...DEFAULT_OPTIONS,
    })
      .then((result) => {
        if (!result.ok) throw result;
        return result.json();
      })
      .then((res) => {
        setLoading(false);

        if (
          res.message ===
          "You have already approved the maximum number of vouchers for this month"
        ) {
          // setLoading(false);
          setErrorMessage(res.message);
        } else {
          close();

          alertifyjs.success(`Successfully approved voucher`, 10);

          window.location.href = "/vouchers";
        }
      })
      .catch((error) => {
        error.json().then((body) => {
          setLoading(false);
          setErrorMessage(body.error);
        });
      });
  };
  return (
    <div>
      {loading ? (
        <CustomLoading text="Approving Voucher" />
      ) : (
        <form onSubmit={(e) => handleApproveVoucher(e)}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <p> Reasons for Eligibility</p>
              {voucherDetails?.reasonForEligibility?.map((reason) => {
                return (
                  <ul key={Math.random()} className="vouchers-ul">
                    {<li key={Math.random()}> {reason}</li>}
                  </ul>
                );
              })}
            </div>
            <div
              style={{
                display: "flex",
                alignSelf: "center",
                justifyContent: "space-between",
                width: "60%",
              }}
            >
              <div className={"section"}>
                <p className={"title"}>
                  Expiry Date ({program.defaultVoucherValidity} days from today)
                </p>

                <p>{expiryDate}</p>
              </div>

              <div className={"section"}>
                <p className={"title"}>Voucher Value</p>
                <p>UGX {new Intl.NumberFormat("lg-UG").format(value)}</p>
              </div>

              <div className={"section"}>
                <p className={"title"}>Beneficiary amount</p>
                <p>
                  UGX
                  {new Intl.NumberFormat("lg-UG").format(
                    program.beneficiaryAmount
                  )}
                </p>
              </div>
            </div>
          </div>

          {errorMessage && (
            <div className="col-md-12" style={{ float: "none" }}>
              <p style={{ color: "red" }}>
                {errorMessage.message || errorMessage}
              </p>
            </div>
          )}

          <div
            className="form-group"
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              marginTop: 15,
            }}
          >
            <button className="btn btn-primary " name="submit" type="submit">
              APPROVE
            </button>

            <button
              onClick={close}
              className="btn btn-default "
              name="cancel"
              type="button"
              style={{
                backgroundColor: "red",
                color: "white",
              }}
            >
              CANCEL
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export const DeclineVoucherRequest = ({ close, voucherDetails }) => {
  const [reasonForDenial, setReasonForDenial] = useState("");
  const [loading, setLoading] = useState(false);

  const declineReasons = [
    "----------------",
    "Household not in division",
    "Already used voucher",
    "Other",
  ];

  const declineReasonsOptions = declineReasons.map((reason) => (
    <option value={reason} key={Math.random()}>
      {reason}
    </option>
  ));

  const handleDeclineVoucher = async (e) => {
    e.preventDefault();
    setLoading(true);
    const url =
      addressEndpoint +
      `api/v1/officer/voucher/actionVoucher/${voucherDetails._id}`;

    const data = {
      doID,
      reasonForDenial,
      status: 2,
    };

    await fetch(url, {
      method: "PATCH",
      body: JSON.stringify(data),
      ...DEFAULT_OPTIONS,
    }).then((res) => {
      if (res.status === 200) {
        setLoading(false);

        window.location.href = "/vouchers";
      } else {
      }
    });
  };

  return (
    <div className={"action-voucher-form"}>
      {loading ? (
        <CustomLoading text="Declining Voucher" />
      ) : (
        <form onSubmit={(e) => handleDeclineVoucher(e)}>
          <div className={"section"}>
            <p className={"title"}>Reason for Denial</p>
            <select
              style={{ width: "300px" }}
              className="select form-control"
              id="facility_type"
              required
              name="select"
              onChange={(e) => setReasonForDenial(e.target.value)}
            >
              {declineReasonsOptions}
            </select>
          </div>
          <div className={"section"}>
            <div className="form-group">
              <button className="btn btn-primary " name="submit" type="submit">
                DECLINE
              </button>

              <button
                onClick={close}
                className="btn btn-default "
                name="cancel"
                type="button"
                style={{
                  marginLeft: 220,
                  backgroundColor: "red",
                  color: "white",
                }}
              >
                CANCEL
              </button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};
export const EditVoucher = ({ close, voucherDetails }) => {
  return <div>Hi</div>;
};

export const DeactivateVoucher = ({ close, voucherDetails }) => {
  const [reasonForDeactivation, setReasonForDeactivation] = useState("");
  const [loading, setLoading] = useState(false);

  const deactivationReasons = [
    "----------------",
    "Household not in division",
    "Already used voucher",
    "Other",
  ];

  const declineReasonsOptions = deactivationReasons.map((reason) => (
    <option value={reason} key={Math.random()}>
      {reason}
    </option>
  ));

  const handleDeactivateVoucher = async (e) => {
    e.preventDefault();
    setLoading(true);
    const url =
      addressEndpoint +
      `api/v1/officer/voucher/actionVoucher/${voucherDetails._id}`;

    const data = {
      doID,
      reasonForDeactivation,
      status: 6,
    };

    await fetch(url, {
      method: "PATCH",
      body: JSON.stringify(data),
      ...DEFAULT_OPTIONS,
    }).then((res) => {
      if (res.status === 200) {
        setLoading(false);

        window.location.href = "/vouchers";
      } else {
      }
    });
  };

  return (
    <div className={"action-voucher-form"}>
      {loading ? (
        <CustomLoading text="Declining Voucher" />
      ) : (
        <form onSubmit={(e) => handleDeactivateVoucher(e)}>
          <div className={"section"}>
            <p className={"title"}>Reason for Denial</p>
            <select
              style={{ width: "300px" }}
              className="select form-control"
              id="facility_type"
              required
              name="select"
              onChange={(e) => setReasonForDeactivation(e.target.value)}
            >
              {declineReasonsOptions}
            </select>
          </div>
          <div className={"section"}>
            <div className="form-group">
              <button className="btn btn-primary " name="submit" type="submit">
                DEACTIVATE
              </button>

              <button
                onClick={close}
                className="btn btn-default "
                name="cancel"
                type="button"
                style={{
                  marginLeft: 220,
                  backgroundColor: "red",
                  color: "white",
                }}
              >
                CANCEL
              </button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export const ApproveVoucherJob = ({ close, voucherDetails }) => {
  const [officerRemarks, setOfficerRemarks] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      doID,
      officerRemarks,
      status: 6,
    };

    const url =
      addressEndpoint +
      `api/v1/officer/voucher/actionVoucher/${voucherDetails._id}`;

    await fetch(url, {
      method: "PATCH",
      body: JSON.stringify(payload),
      ...DEFAULT_OPTIONS,
    }).then((res) => {
      if (res.status === 200) {
        setLoading(false);
        close();
        window.location.href = "/vouchers";
      } else {
        setLoading(false);
      }
    });
  };

  return (
    <div className="bootstrap-iso">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12">
            <form
              onSubmit={(e) => handleSubmit(e)}
              style={{ marginTop: "50px" }}
            >
              <div className="col-md-12 editCATForm">
                <div className="form-group ">
                  <label
                    className="control-label requiredField"
                    htmlFor="full_name"
                  >
                    CATS Approval Comments:
                  </label>
                </div>

                <div className="form-group ">
                  <p>{voucherDetails.catsApprovalRemarks}</p>
                </div>
              </div>
              <div className="col-md-12 editCATForm">
                <div className="form-group ">
                  <label
                    className="control-label requiredField"
                    htmlFor="full_name"
                  >
                    Do Approval Comments:
                  </label>
                </div>

                <div className="form-group">
                  <input
                    className="form-control"
                    id="officerRemarks"
                    name="officerRemarks"
                    onChange={(e) => setOfficerRemarks(e.target.value)}
                    style={{ width: "700px" }}
                  />
                </div>
              </div>

              <div className="col-md-12 editCATForm">
                {/* <div className="form-group"> */}
                <button
                  className="btn btn-primary "
                  name="submit"
                  type="submit"
                >
                  SAVE
                </button>
                <button
                  onClick={close}
                  className="btn btn-default "
                  name="cancel"
                  type="button"
                >
                  CANCEL
                </button>
              </div>
              {/* </div> */}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export const CompleteAllJobs = ({ vouchers, sps, close }) => {
  const [scanning, setScanning] = useState(false);
  const [loading, setLoading] = useState(false);

  const [validVouchers, setValidVouchers] = useState([]);

  const [selectedVoucherProgram, setSelectedVoucherProgram] = useState();

  const [errorMessage, setErrorMessage] = useState("");

  const [counter, setCounter] = useState("");

  const programs = [
    "Select program",
    ...new Set(vouchers?.map((v) => v.voucherProgram)),
  ];

  const handleScanVouchers = () => {
    // 1. Get all the vouchers for this division / cats / program
    //  - Must match the program
    //  - Status must be 1
    setScanning(true);
    const validVouchers = vouchers?.filter(
      (v) => v.voucherProgram === selectedVoucherProgram && v.status === 4
    );

    if (validVouchers.length) {
      setErrorMessage(
        `Found ${validVouchers.length}. Please press start to begin`
      );
      // setFoundVouchers(validVouchers.length);
      setValidVouchers(validVouchers);
    } else {
      setErrorMessage(
        `Did not find any valid vouchers for ${selectedVoucherProgram}`
      );
      setValidVouchers([]);
    }

    setScanning(false);
  };
  const handleMarkCompleted = async () => {
    // console.log(validVouchers);

    // 1. Go through each voucher and make a request to the server to mark it as completed.

    validVouchers.forEach(async (voucher, index) => {
      // set up data payload
      const sp = sps?.filter((sp) => sp.type === voucher.spType)[0];
      console.log(sp?._id);

      const payload = {
        _id: voucher._id,
        division: voucher.validForDivision,
        data: {
          status: 6, // This is the completed status
          approved: {
            status: 1,
            time: new Date(),
            approvedBy: doID,
          },
          dispatched: {
            status: 3,
            time: new Date(),
            dispatchedBy: doID,
            spID: sp?._id,
          },
          jobApproved: {
            status: 6,
            time: new Date(),
          },
          jobStatus: {
            status: 1,
            completedOn: new Date(),
          },
          // jobID: "",
        },
      };

      const url = addressEndpoint + `api/v1/officer/voucher/bulkUpdate`;

      await fetch(url, {
        method: "PATCH",
        body: JSON.stringify(payload),
        ...DEFAULT_OPTIONS,
      }).then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setCounter(index + 1);
          // close();
          // window.location.href = "/vouchers";
        } else {
          setLoading(false);
        }
      });

      // console.log(payload);
    });
  };

  return (
    <div>
      <p style={{ color: "red", fontSize: 20, fontWeight: "bold" }}>
        Warning! This will mark all jobs that have been marked as approved as
        completed.
      </p>
      <p> This is what will happen</p>
      <ul>
        <li>
          It will search for all vouchers that you have approved for a
          particular voucher program
        </li>
        <li>It will "dispatch" these to the respective SP</li>
        <li>It will mark the job as completed by the SP</li>
        <li>It will mark the job as approved by the CATs / VHT</li>
        <li>It will mark the job as approved YOU</li>
        <li>It will mark the job as COMPLETED and ready for payment</li>
      </ul>
      <br />
      <p style={{ color: "red" }}>
        If you are okay with this, please select a voucher program and click the
        "Scan Vouchers" button to begin.
      </p>
      <br />

      <div
        className="form-group"
        style={{
          display: "flex",
          alignItems: "center",
          // justifyContent: "space-between",
        }}
      >
        <select
          className="form-control"
          id="voucherProgram"
          name="voucherProgram"
          onChange={(e) => setSelectedVoucherProgram(e.target.value)}
          style={{ width: "70%" }}
          required
        >
          {programs?.map((program) => {
            return <option value={program}>{program}</option>;
          })}
        </select>
        <button
          style={{ marginLeft: "30px" }}
          onClick={handleScanVouchers}
          className="btn btn-default "
          name="scan"
          type="button"
          disabled={
            !selectedVoucherProgram || selectedVoucherProgram === undefined
          }
        >
          {scanning ? "Scanning" : "Scan Vouchers"}
        </button>
      </div>

      <br />

      {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
      {counter && <p> {`Patching ${counter} / ${validVouchers.length}`}</p>}

      <br />

      <div className="col-md-12">
        <div className="form-group">
          <button
            className="btn btn-primary "
            name="submit"
            type="submit"
            disabled={validVouchers.length < 1}
            onClick={handleMarkCompleted}
          >
            START
          </button>
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          <button
            onClick={close}
            className="btn btn-default "
            name="cancel"
            type="button"
          >
            CANCEL
          </button>
        </div>
      </div>
    </div>
  );
};
