// Various utility functions for graphs and maps

const getUTCDateValue = value => {
  // Construct date value from ID
  // Used in highcharts
  // Uses JS date because firefox wont render moment.utc formats
  return Date.UTC(value._id.year, value._id.month - 1, value._id.day);
};

const getMonths = data => {
  if (data.length === 1) {
    // One day chosen
    let months = data.map(m => m._id.day + " " + m.month + " " + m._id.year);
    return months;
  } else {
    // Period chosen
    let months = data.map(m => m.month + " " + m._id.year);

    // Return only unique months
    let uniqueMonths = [...new Set(months)];
    return uniqueMonths;
  }
};

const divisions = [
  "CENTRAL",
  "KAWEMPE",
  "MAKINDYE",
  "NAKAWA",
  "Other",
  "RUBAGA"
];

const getDivisions = data => {
  // Months before Jan 2019 do not have any divisions attached to them.
  // This helper function determines which divisions to parse to the graph based on the data
  // Returned from the backend/
  let divisionsFromData = data.map(d => d.divisions.sort());

  if (divisionsFromData > 0) {
    // We have divisions in our data set
    return divisionsFromData.slice(-1)[0];
  } else {
    // No divisions, so we parse the divisions array
    return divisions;
  }
};

const getParishData = (data, fieldToAggregate, parish = true) => {
  // Function to hold all parish trips aggregated.
  // Data returned has multiple instances of 'Parish' and 'totalNumberOfTrips'
  // We collect all these instances into an array of arrays for each instance
  // This data then has duplicate parish names
  // We later aggregate this data for each parish to give us the total number of
  // trips.
  // https://jsfiddle.net/obarvdem/15/
  const mapData = [];

  if (parish) {
    Object.entries(data).forEach(([key, value]) => {
      mapData.push([value.parish, value[fieldToAggregate]]);
    });
  } else {
    Object.entries(data).forEach(([key, value]) => {
      // Remove empty strings from the value array
      let filtered = value.informalSettlement.filter(function (el) { return el; });
      mapData.push([filtered[0], value[fieldToAggregate]]);
    });

  }

  const aggregatedMapData = [];

  for (var i = 0; i < mapData.length - 1; i++) {
    // Loop through each returned parish and if its a duplicate,
    // add the values, if not return.
    // temp 0 holds the total at running time, later reset when we have
    // a match

    let temp = 0;
    for (var k = i; k < mapData.length; k++) {
      if (mapData[i][0] === mapData[k][0]) {
        temp += mapData[k][1];
        mapData[k][1] = 0;
      }
    }
    if (temp !== 0) aggregatedMapData.push([mapData[i][0], temp]);
  }

  return aggregatedMapData;
};

const aggregateMonthlyVolumesByMonth = (data, months, fieldToAggregate) => {
  let aggregateData = [];

  if (data.length === 1) {
    // Extract month date object parsed
    let returnedMonth = months[0].split(" ")[1];

    let dayTotal = data
      .filter(({ month }) => month === returnedMonth)
      .reduce(
        (accumulator, currentValue) =>
          accumulator + Math.ceil(currentValue[fieldToAggregate] / 1000),
        0
      );
    aggregateData.push(dayTotal);
  } else {
    months.forEach(uniqueMonth => {
      // Month is returned with year as "January 2019"
      // To perform a match, we strip out the year (last 5 characters)
      //  and remain with January using .slice() method
      let monthTotal = data
        .filter(({ month }) => month === uniqueMonth.slice(0, -5))
        .reduce(
          (accumulator, currentValue) =>
            accumulator + Math.ceil(currentValue[fieldToAggregate] / 1000),
          0
        );
      aggregateData.push(monthTotal);
    });
  }
  return aggregateData;
};

export {
  getUTCDateValue,
  getParishData,
  getDivisions,
  getMonths,
  aggregateMonthlyVolumesByMonth
};
