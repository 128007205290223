import React, { Component } from 'react';
import { Layout, CustomLoading } from './inc/Layout';
import { NavDropdown, MenuItem } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTachometerAlt } from '@fortawesome/free-solid-svg-icons'
import 'alertifyjs/build/css/alertify.min.css';
import 'alertifyjs/build/css/themes/semantic.min.css';
import moment from 'moment';
import { BootstrapTable, TableHeaderColumn, ExportCSVButton } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';



const addr = require('../env_config').default;
var unirest = require("unirest");
const alertifyjs = require('alertifyjs');

const gSession = window.sessionStorage;
const token = gSession.getItem("wat");
const loggedInId = gSession.getItem("wai");


let order = 'desc';
let startOFDay = new Date();
startOFDay.setHours(0, 0, 0, 0);

let prevMonthFirstDay = moment().subtract(1, 'months').date(1).local().format('YYYY-MM-DD');

var endOfDay = new Date();
endOfDay.setHours(23, 59, 59, 999);

export class JobsNew extends Component {
  constructor(props) {
    super(props);

    this.state = {
      jobs: [],
      isLoaded: false,
      loadingText:"Loading ..",
      status: "All",
      from: prevMonthFirstDay,
      to: moment(endOfDay).local().format('YYYY-MM-DD'),
      initiatedBy: null,

      facility_type: "All",
      showCoords: true,
      manageColomns: {
        date_of_registration: true,
        facility_type: false,
        finish_time: false,
        full_name: false,
        income_recieved: false,
        job_id: false,
        lat: true,
        location: false,
        division: false,
        parish: false,
        long: true,
        phone_number: true,
        service_provider: false,
        service_provider_number: true,
        source: true,
        status: false,
        start_point_lat:true,
        start_point_long:true,
        initiated_by:true,
        referral_code: true
      },
      // remote pagination
      currentPage: 1,
      sizePerPage: 50,
      totalDataSize: 0
    }
    this.getData = this.getData.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.updateTable = this.updateTable.bind(this);
    this.getJobsSummary = this.getJobsSummary.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.onSortChange = this.onSortChange.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
    this.returnAlltheData = this.returnAlltheData.bind(this);
  }
  componentDidMount() {
    //passin mentor id
    this.getData();
    // this.getJobsSummary();
  }
  onPageChange(page, sizePerPage) {
    console.log("Page:" + page + " sizePerPage " + sizePerPage);
    const currentIndex = (page - 1) * sizePerPage;
    this.getData("api/jobs?records=" + page + "&status=" + this.state.status + "&from=" + this.state.from + "&to=" + this.state.to + "&facility_type=" + this.state.facility_type + "&limit=" + sizePerPage)
    this.setState({
      currentPage: page,
      isLoaded: false,
      sizePerPage: sizePerPage
    });
  }
  returnAlltheData(onClick) {
    let url = "api/jobs?records=1" + "&status=" + this.state.status + "&from=" + this.state.from + "&to=" + this.state.to + "&facility_type=" + this.state.facility_type + "&limit=" + this.state.totalDataSize;
    const thisApp = this;
    let x = [];
    thisApp.setState({
      isLoaded:false,
      loadingText:"Generating Jobs CSV file, please wait..."
    });
    let status;
    var req = unirest("GET", addr + url);
    req.headers({
      "cache-control": "no-cache",
      "authorization": token
    });
    req.end(function (res) {
      if (res.error) console.log(res);
      if (res.status == 200) {

        thisApp.setState({
          jobs:res.body.data,
          isLoaded: true
        },()=>{
            console.log(thisApp.state.jobs);
            onClick()
        });

      }
      else {
        //
        alertifyjs.error('Failed to generate csv file, please check your internet connection and try again', 5, function(){  console.log('dismissed'); });
        thisApp.setState({
          isLoaded: true
        });
      }
    });
    return x;
  }

  getData(url = "api/jobs?records=" + this.state.currentPage) {
    const thisApp = this;
    thisApp.setState({
      jobs: [],
      jobs_copy: [],
      loadingText:"Loading jobs...",
    });
    let status;
    var req = unirest("GET", addr + url);

    req.headers({
      "cache-control": "no-cache",
      "authorization": token
    });


    req.end(function (res) {
      if (res.error) console.log(res);

      console.log(res.status);
      if (res.status == 200) {
        console.log(res.body);
        let x = [];
        //  let q = x.push(res.body);
        thisApp.setState({
          jobs: res.body.data,
          jobs_copy: res.body.data,
          isLoaded: true,
          totalDataSize: res.body.total,
          sizePerPage: res.body.limit
        }, () => thisApp.getJobsSummary("api/jobs/summary?status=" + thisApp.state.status + "&from=" + thisApp.state.from + "&to=" + thisApp.state.to+ "&facility_type=" + thisApp.state.facility_type));
      }
      else {
        //
        thisApp.setState({
          isLoaded: true
        });
      }
    });
  }
  getJobsSummary(url = "api/jobs/summary") {
    const thisApp = this;
    let status;
    var req = unirest("GET", addr + url);

    req.headers({
      "cache-control": "no-cache",
      "authorization": token
    });


    req.end(function (res) {
      if (res.error) console.log(res);

      console.log(res.status);
      if (res.status == 200) {
        console.log(res.body);
        thisApp.setState({
          summary: res.body,
          isLoaded: true,
        }, () => console.log(thisApp.state));
      }
      else {
        thisApp.setState({
          isLoaded: true
        });
      }
    });

  }
  timeConvert(cell, row) {
    //console.log(row);

    return cell && cell ? (moment(cell).format("DD/MM/YY h:mm:ss a")) : ("Date not available");
  }

  moneyConvert(cell, row) {
    let amount = "UGX 0";
    if (cell) {
      amount = "UGX " + parseInt(cell).toLocaleString('en')
    }
    return amount;
  }
  statusFomatter(cell, row) {
    let status;
    switch (cell) {
      case 0:
        status = "Pending";
        break;
      case 1:
        status = "Scheduled";
        break;
      case 2:
        status = "In progress";
        break;
      case 3:
        status = "Completed";
        break;
      default:
        status = "Pending";
    }

    return status;
  }

  initiatedByFormatter(cell, row) {
    let initiatedBy;
    switch (cell) {
      case "Driver":
        initiatedBy = "Service provider";
        break;
      case "Admin":
        initiatedBy = "KCCA Call center";
        break;
      default:
        initiatedBy = "Service provider";
    }

    return initiatedBy;
  }

  enumFormatter(cell, row, enumObject) {
    return enumObject[cell];
  }


  renderShowsTotal(start, to, total) {
    return (
      <p style={{ color: 'blue' }}>
        From {start} to {to}, totals is {total}&nbsp;&nbsp;
          </p>
    );
  }
  onSortChange(sortName, sortOrder) {
    console.log(sortName);
    const x = this.state.jobs
    if (sortOrder === 'asc') {
      x.sort(function (a, b) {
        console.log(JSON.stringify(a));
        if (parseInt(a[sortName], 10) > parseInt(b[sortName], 10)) {
          return 1;
        } else if (parseInt(b[sortName], 10) > parseInt(a[sortName], 10)) {
          return -1;
        }
        return 0;
      });
    } else {
      x.sort(function (a, b) {
        if (parseInt(a[sortName], 10) > parseInt(b[sortName], 10)) {
          return -1;
        } else if (parseInt(b[sortName], 10) > parseInt(a[sortName], 10)) {
          return 1;
        }
        return 0;
      });
    }

    this.setState({
      jobs: x
    });
  }
  handleBtnClick = () => {
    if (order === 'desc') {
      this.refs.table.handleSort('asc', 'name');
      order = 'asc';
    } else {
      this.refs.table.handleSort('desc', 'name');
      order = 'desc';
    }
  }
  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
      isLoaded: false
    }, () =>
        this.getData("api/jobs?records=" + this.state.currentPage + "&status=" + this.state.status + "&from=" + this.state.from + "&to=" + this.state.to + "&facility_type=" + this.state.facility_type + "&limit=" + this.state.sizePerPage)
    );
  }
  updateTable(colomn) {
    //make a copy of state
    let manageColomns = this.state.manageColomns;

    if (this.state.manageColomns[colomn] === true) {
      manageColomns[colomn] = false;
      this.setState({
        manageColomns: manageColomns
      })
    } else {
      manageColomns[colomn] = true;
      this.setState({
        manageColomns: manageColomns
      })
    }

  }
  onFilterChange(filterObj) {
    console.log("On Filter Change");
    this.setState({
      jobs: this.state.jobs_copy
    });
    if (Object.keys(filterObj).length === 0) {
      this.setState({
        jobs: this.state.jobs_copy
      });
      return;
    }
    const thisApp = this;
    const data = this.state.jobs.filter((jobs) => {
      let valid = true;
      let filterValue;
      for (const key in filterObj) {
        const targetValue = jobs[key];
        switch (filterObj[key].type) {
          case 'NumberFilter': {
            filterValue = filterObj[key].value.number;
            valid = thisApp.filterNumber(targetValue, filterValue, filterObj[key].value.comparator);
            break;
          }
          default: {
            filterValue = (typeof filterObj[key].value === 'string') ?
              filterObj[key].value.toLowerCase() : filterObj[key].value;
            valid = thisApp.filterText(targetValue, filterValue);
            break;
          }
        }

        if (!valid) {
          break;
        }
      }
      return valid;
    });
    this.setState({
      jobs: data
    });
  }
  filterNumber(targetVal, filterVal, comparator) {
    let valid = true;
    switch (comparator) {
      case '=': {
        if (targetVal !== filterVal) {
          valid = false;
        }
        break;
      }
      case '>': {
        if (targetVal <= filterVal) {
          valid = false;
        }
        break;
      }
      case '<=': {
        if (targetVal > filterVal) {
          valid = false;
        }
        break;
      }
      default: {
        console.error('Number comparator provided is not supported');
        break;
      }
    }
    return valid;
  }

  filterText(targetVal, filterVal) {
    console.log(filterVal);
    if (targetVal && targetVal.toString().toLowerCase().indexOf(filterVal) === -1) {
      return false;
    }

    return true;
  }
  handleExportCSVButtonClick = () => {
    const thisApp = this;
    this.returnAlltheData(function(){
      thisApp.refs.table.handleExportCSV();
    });

  }
  createCustomExportCSVButton = (onClick) => {
    return (
      <ExportCSVButton
        btnText='Export to CSV'
        btnGlyphicon='glyphicon-download'
        onClick={ () => this.handleExportCSVButtonClick() }/>
    );
  }

  toNearest(number) {

    switch (true) {
      case number <= 10:
        return 1;
        break;
      case number <= 10:
        return number;
        break;
      case number <= 100:
        return Math.ceil(number / 5) * 5;
        break;
      case number > 100 < 1000:
        return Math.ceil(number / 50) * 50;
        break;
      case number >= 1000 < 10000:
        return Math.ceil(number / 500) * 500;
        break;
      case number >= 1000:
        return Math.ceil(number / 5000) * 5000;
        break;
      default:
        console.log("D");
        return 1;

    }

  }
  render() {
    let products = this.state.jobs
    const statusType = {
      0: 'Pending',
      1: 'Scheduled',
      2: 'In progress',
      3: 'Completed'
    };


    let xxx = parseInt(this.state.totalDataSize) <= 50 ?(
      [{
      text: 'All', value: this.state.totalDataSize
    }]
    ):(
      [{
        text: this.toNearest(parseInt(this.state.totalDataSize / 5)), value: this.toNearest(parseInt(this.state.totalDataSize / 5))
      }, {
        text: this.toNearest(parseInt(this.state.totalDataSize / 4)), value: this.toNearest(parseInt(this.state.totalDataSize / 4))
      }, {
        text: this.toNearest(parseInt(this.state.totalDataSize / 3)), value: this.toNearest(parseInt(this.state.totalDataSize / 3))
      }
        , {
        text: this.toNearest(parseInt(this.state.totalDataSize / 2)), value: this.toNearest(parseInt(this.state.totalDataSize / 2))
      }, {
        text: 'All', value: this.state.totalDataSize
      }]
    )

    const options = {
      page: this.state.currentPage,  // which page you want to show as default
      onPageChange: this.onPageChange,
      onSortChange: this.onSortChange,
      onFilterChange: this.onFilterChange,
      sizePerPageList: xxx, // you can change the dropdown list for size per page
      sizePerPage: parseInt(this.state.sizePerPage),  // which size per page you want to locate as default
      //remote export
      exportCSVBtn: this.createCustomExportCSVButton,
      pageStartIndex: 1, // where to start counting the pages
      paginationSize: 10,
      prePage: 'Prev', // Previous page button text
      nextPage: 'Next', // Next page button text
      firstPage: 'First', // First page button text
      paginationPosition: 'bottom'  // default is bottom, top and both is all available
    };
    let statusList = [
      { name: 'Pending', value: 0 },
      { name: 'Scheduled', value: 1 },
      { name: 'In progress', value: 2 },
      { name: 'Completed', value: 3 }
    ];
    let iniTiatedBy = ["Driver", "Admin"];
    let statusFilter = statusList.map((item) =>
      <option value={item.value}>{item.name}</option>
    );
    let iniTiatedByFilter = iniTiatedBy.map((item) =>
      <option value={item}>{item}</option>
    );
    let ft = ['Lined', 'Unlined', 'Lined pit latrine with slab', 'Unlined pit latrine without slab', 'Flush toilet connected to septic tank', 'Unlined pit latrine with slab', 'Composting toilet'];

    let facilityTypeFilter = ft.map((item) =>
      <option value={item}>{item}</option>
    );
    return (
      <Layout>
        <div className="col-md-8 title">
          <h4> <FontAwesomeIcon icon={faTachometerAlt} /> Jobs</h4>
          <br />
        </div>
        <div className="col-md-12">
          <form className="form-inline">

            <div className="form-group">
              <label htmlFor="email">Status:</label>
              <select name="status" value={this.state.status} onChange={this.handleInputChange} className="form-control">
                <option value="All">All</option>
                {statusFilter}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="email">Facility type:</label>
              <select name="facility_type" value={this.state.facility_type} onChange={this.handleInputChange} className="form-control">
                <option value="All">All</option>
                {facilityTypeFilter}
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="email">From:</label>
              <input name="from" value={this.state.from} onChange={this.handleInputChange} className="form-control" type="date" />
            </div>
            <div className="form-group">
              <label htmlFor="email">To:</label>
              <input name="to" value={this.state.to} onChange={this.handleInputChange} className="form-control" type="date" />
            </div>

            <NavDropdown eventKey={3} className="pull-right" title="Manage columns" id="basic-nav-dropdown">
              <MenuItem onClick={(e, full_name) => this.updateTable("full_name")} eventKey={3.1}> <Check state={this.state.manageColomns.full_name} /> Customer Name</MenuItem>
              <MenuItem onClick={(e, phone_number) => this.updateTable("phone_number")} eventKey={3.1}> <Check state={this.state.manageColomns.phone_number} /> Customer phone number</MenuItem>
              <MenuItem onClick={(e, location) => this.updateTable("location")} eventKey={3.1}><Check state={this.state.manageColomns.location} /> Customer location</MenuItem>
              <MenuItem onClick={(e, facility_type) => this.updateTable("facility_type")} eventKey={3.1}><Check state={this.state.manageColomns.facility_type} /> Facility type</MenuItem>
              <MenuItem onClick={(e, initiated_by) => this.updateTable("initiated_by")} eventKey={3.1}><Check state={this.state.manageColomns.initiated_by} /> Initiated by</MenuItem>
              <MenuItem onClick={(e, income_recieved) => this.updateTable("income_recieved")} eventKey={3.1}><Check state={this.state.manageColomns.income_recieved} /> Income received</MenuItem>
              <MenuItem onClick={(e, status) => this.updateTable("status")} eventKey={3.1}><Check state={this.state.manageColomns.status} /> Status</MenuItem>
              <MenuItem onClick={(e, service_provider) => this.updateTable("service_provider")} eventKey={3.1}><Check state={this.state.manageColomns.service_provider} />  Service provider</MenuItem>
              <MenuItem onClick={(e, service_provider_number) => this.updateTable("service_provider_number")} eventKey={3.1}><Check state={this.state.manageColomns.service_provider_number} /> Service provider number</MenuItem>
              <MenuItem onClick={(e, date_of_registration) => this.updateTable("date_of_registration")} eventKey={3.1}><Check state={this.state.manageColomns.date_of_registration} />  Start time</MenuItem>
              <MenuItem onClick={(e, finish_time) => this.updateTable("finish_time")} eventKey={3.1}><Check state={this.state.manageColomns.finish_time} /> Finish time</MenuItem>
              <MenuItem onClick={(e, start_point_long) => this.updateTable("start_point_long")} eventKey={3.1}><Check state={this.state.manageColomns.start_point_long} /> Start Point Longitudes</MenuItem>
              <MenuItem onClick={(e, start_point_lat) => this.updateTable("start_point_lat")} eventKey={3.1}><Check state={this.state.manageColomns.start_point_lat} /> Start Point Latitudes</MenuItem>
              <MenuItem onClick={(e, referral_code) => this.updateTable("referral_code")} eventKey={3.1}><Check state={this.state.manageColomns.referral_code} />  Referral Code</MenuItem>

              <MenuItem onClick={(e, long) => this.updateTable("long")} eventKey={3.1}><Check state={this.state.manageColomns.long} /> Longitudes</MenuItem>
              <MenuItem onClick={(e, lat) => this.updateTable("lat")} eventKey={3.1}><Check state={this.state.manageColomns.lat} />  Latitudes</MenuItem>
              <MenuItem onClick={(e, division) => this.updateTable("division")} eventKey={3.1}><Check state={this.state.manageColomns.division} />  Division</MenuItem>
              <MenuItem onClick={(e, parish) => this.updateTable("parishes")} eventKey={3.1}><Check state={this.state.manageColomns.parish} />  Parishes</MenuItem>

            </NavDropdown>

          </form>

        </div>
        <div className="padding-normal col-md-12">
          <br />
          <span>Total jobs <b>{this.state.summary && this.state.summary.total ? (this.state.summary.total) : (0)}</b></span> &nbsp; &nbsp; &nbsp; &nbsp;
              {
            this.state.status != "All" ? ("") : (
              <React.Fragment>
                <span>In progress jobs <b>{this.state.summary && this.state.summary.inProgress && this.state.summary.inProgress}</b></span>&nbsp; &nbsp; &nbsp; &nbsp;
              <span>Pending jobs <b>{this.state.summary && this.state.summary.pendingJobs && this.state.summary.pendingJobs}</b></span>&nbsp; &nbsp; &nbsp; &nbsp;
              <span>Completed jobs <b>{this.state.summary && this.state.summary.completedJobs && this.state.summary.completedJobs}</b></span>&nbsp; &nbsp; &nbsp; &nbsp;
              <span>Scheduled jobs <b>{this.state.summary && this.state.summary.scheduledJobs && this.state.summary.scheduledJobs}</b></span>
              </React.Fragment>)
          }

        </div>
        <div className="padding-top content-container col-md-12">

          {this.state.isLoaded === true ? (
            <BootstrapTable data={products}
              striped
              hover
              csvFileName={'jobs_'+moment(Date.now()).local().format("YYYY_MM_DD_HHmmss")+".csv"}
              ref='table'
              remote={true}
              headerContainerClass='table-header'
              tableContainerClass='table-responsive table-onScreen'
              fetchInfo={{ dataTotalSize: this.state.totalDataSize }}
              pagination={true}
              options={options}
              exportCSV
              pagination>
              <TableHeaderColumn dataSort={true} isKey dataField='job_id'>Job ID</TableHeaderColumn>
              <TableHeaderColumn hidden={this.state.manageColomns.full_name} dataSort={true} filter={{ type: 'TextFilter', delay: 0 }} dataField='full_name'>Customer name</TableHeaderColumn>
              <TableHeaderColumn hidden={this.state.manageColomns.phone_number} dataField='phone_number' filter={{ type: 'TextFilter', delay: 0 }} >Customer Phone</TableHeaderColumn>
              <TableHeaderColumn hidden={this.state.manageColomns.location} dataSort={true} filter={{ type: 'TextFilter', delay: 0 }} dataField='location'>Location</TableHeaderColumn>
              <TableHeaderColumn hidden={this.state.manageColomns.division} dataSort={true} filter={{ type: 'TextFilter', delay: 0 }} dataField='division'>Division</TableHeaderColumn>
              <TableHeaderColumn hidden={this.state.manageColomns.parish} dataSort={true} filter={{ type: 'TextFilter', delay: 0 }} dataField='parish'>Parish</TableHeaderColumn>
              <TableHeaderColumn hidden={this.state.manageColomns.facility_type} dataSort={true} dataField='facility_type'>Facility type</TableHeaderColumn>
              <TableHeaderColumn hidden={this.state.manageColomns.initiated_by} dataSort={true} csvFormat={this.initiatedByFormatter} dataFormat={this.initiatedByFormatter} dataField='initiated_by'>Initiated by</TableHeaderColumn>
              <TableHeaderColumn hidden={this.state.manageColomns.income_recieved} dataSort={true} dataField='income_received' dataFormat={this.moneyConvert} csvFormat={this.moneyConvert}>Amount Received</TableHeaderColumn>
              <TableHeaderColumn hidden={this.state.manageColomns.status} dataSort={true} dataField='status' dataFormat={this.statusFomatter} csvFormat={this.statusFomatter}>Status</TableHeaderColumn>
              <TableHeaderColumn hidden={this.state.manageColomns.service_provider} dataSort={true} filter={{ type: 'TextFilter', delay: 0 }} dataField='service_provider'>Service Provider Name</TableHeaderColumn>
              <TableHeaderColumn hidden={this.state.manageColomns.service_provider_number} dataSort={true} filter={{ type: 'TextFilter', delay: 0 }} dataField='service_provider_number'>Service provider number</TableHeaderColumn>
              <TableHeaderColumn hidden={this.state.manageColomns.date_of_registration} dataField='date_of_registration' csvFormat={this.timeConvert} dataFormat={this.timeConvert}>Start Time</TableHeaderColumn>
              <TableHeaderColumn hidden={this.state.manageColomns.finish_time} dataField='finish_time' csvFormat={this.timeConvert} dataFormat={this.timeConvert}>Finish Time</TableHeaderColumn>
              <TableHeaderColumn hidden={this.state.manageColomns.referral_code} dataSort={true} filter={{ type: 'TextFilter', delay: 0 }} dataField='referral_code'>Referral Code</TableHeaderColumn>


              <TableHeaderColumn hidden={this.state.manageColomns.start_point_long} dataField='start_point_long'>Start point Longitudes</TableHeaderColumn>
              <TableHeaderColumn hidden={this.state.manageColomns.start_point_lat} dataField='start_point_lat'>Start point Latitudes</TableHeaderColumn>
              <TableHeaderColumn hidden={this.state.manageColomns.long} dataField='long'>Longitudes</TableHeaderColumn>
              <TableHeaderColumn hidden={this.state.manageColomns.lat} dataField='lat'>Latitudes</TableHeaderColumn>



            </BootstrapTable>
          ) : (
              <CustomLoading text={this.state.loadingText} />
            )}
        </div>
      </Layout>
    );
  }


}


export class Check extends React.Component {
  constructor(props) {
    super(props);

  }
  render() {
    return (
      <React.Fragment>
        <div className="checkboxWrapper">
          <div className="disabler"></div>
          {this.props.state === false ? (<input type="checkbox" checked={true} />) : (<input type="checkbox" checked={false} />)}
        </div>
      </React.Fragment>
    );
  }
}





