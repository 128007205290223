import { useState, useEffect } from "react";

// Network stuff
const gSession = window.sessionStorage;
const token = gSession.getItem("wat");
const DEFAULT_OPTIONS = {
  headers: {
    cache: "no-store",
    credentials: "same-origin",
    authorization: token,
  },
};

// Endpoints
const addressEndpoint = require("../../env_config").default;

export function useGetData(fromFilter, toFilter) {
  const dumpsUrl =
    addressEndpoint + `api/dashboard/dumps?from=${fromFilter}&to=${toFilter}`;
  const jobsUrl =
    addressEndpoint + `api/dashboard/jobs?from=${fromFilter}&to=${toFilter}`;
  const jobsCustomersAndSPUrl =
    addressEndpoint +
    `api/dashboard/jobs/customersAndSPRequests?from=${fromFilter}&to=${toFilter}`;

  const [isLoading, setIsLoading] = useState(false);
  const [dumps, setDumps] = useState(null);
  const [jobs, setJobs] = useState(null);
  const [jobsCustomerAndSP, setJobsCustomersAndSPReq] = useState(null);

  useEffect(() => {
    setIsLoading(true);

    const dumpsReq = () => {
      return fetch(dumpsUrl, DEFAULT_OPTIONS).then((response) =>
        response.json()
      );
    };

    const jobsReq = () => {
      return fetch(jobsUrl, DEFAULT_OPTIONS).then((response) =>
        response.json()
      );
    };

    const jobsCustomersAndSPReq = () => {
      return fetch(jobsCustomersAndSPUrl, DEFAULT_OPTIONS).then((response) =>
        response.json()
      );
    };

    const getAllData = () => {
      // Request both dumps and jobs in parallel and return a Promise if both resolve
      // Promise.all returns a new promise that resolves when all of its arguments resolve
      return Promise.all([dumpsReq(), jobsReq(), jobsCustomersAndSPReq()]);
    };

    // When Promise reolves, both dumps and jobs are available and we can set state appropriately
    getAllData().then(([dumps, jobs, jobsCustomerAndSP]) => {
      // both jobs and dumps have loaded successfully
      setDumps(dumps);
      setJobs(jobs);
      setJobsCustomersAndSPReq(jobsCustomerAndSP);
      setIsLoading(false);
    });
  }, [fromFilter, toFilter]);

  return [
    {
      dumps,
      jobs,
      jobsCustomerAndSP,
      isLoading,
    },
  ];
}

export function useGetVoucherData(
  isAdmin,
  doID,
  voucherID,
  fromFilter,
  toFilter,
  divisionID
) {
  const url = isAdmin
    ? addressEndpoint + `api/v1/officer/vouchers`
    : addressEndpoint + `api/v1/officer/vouchers/${doID}`;

  const singleVoucherUrl =
    addressEndpoint + `api/v1/officer/voucher/${voucherID}`;

  const doCatsURL = addressEndpoint + `api/v1/officer/${doID}/cats`;

  const divisionURL = addressEndpoint + `api/v1/division`;

  const voucherProgramURL = addressEndpoint + `api/v1/voucherProgram`;

  const voucherSPURL =
    addressEndpoint + `api/entreprenuers/all/division/${divisionID}`;

  const [isLoading, setIsLoading] = useState(false);
  const [vouchers, setVouchers] = useState([]);
  // single voucher
  const [voucher, setVoucher] = useState([]);
  const [cats, setCats] = useState([]);
  const [divisions, setDivisions] = useState([]);

  const [voucherProgram, setVoucherProgram] = useState([]);
  const [voucherSPs, setVoucherSPs] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    const vouchersReq = () => {
      return fetch(url, DEFAULT_OPTIONS).then((response) => response.json());
    };

    const catsReq = () => {
      return fetch(doCatsURL, DEFAULT_OPTIONS).then((response) =>
        response.json()
      );
    };

    let voucherReq;

    let divisionsReq;

    let voucherProgramReq;

    let voucherSPsReq;

    if (voucherID) {
      voucherReq = () => {
        return fetch(singleVoucherUrl, DEFAULT_OPTIONS).then((response) =>
          response.json()
        );
      };
    }

    if (isAdmin) {
      divisionsReq = () => {
        return fetch(divisionURL, DEFAULT_OPTIONS).then((response) =>
          response.json()
        );
      };
    }

    if (divisionID) {
      voucherSPsReq = () => {
        return fetch(voucherSPURL, DEFAULT_OPTIONS).then((response) =>
          response.json()
        );
      };
    }

    voucherProgramReq = () => {
      return fetch(voucherProgramURL, DEFAULT_OPTIONS).then((response) =>
        response.json()
      );
    };

    const getAllData = () => {
      return Promise.all([
        vouchersReq(),
        catsReq(),
        voucherReq && voucherReq(),
        divisionsReq && divisionsReq(),
        voucherProgramReq && voucherProgramReq(),
        voucherSPsReq && voucherSPsReq(),
      ]);
    };

    getAllData().then(
      ([vouchers, cats, voucher, divisions, voucherProgram, voucherSPs]) => {
        setVouchers(vouchers);
        setCats(cats);
        voucher && setVoucher(voucher);
        divisions && setDivisions(divisions);
        voucherProgram && setVoucherProgram(voucherProgram);
        voucherSPs && setVoucherSPs(voucherSPs);

        setIsLoading(false);
      }
    );
  }, [doID, voucherID, fromFilter, toFilter]);

  return [
    {
      isLoading,
      vouchers,
      cats,
      divisions,
      voucher,
      voucherProgram,
      voucherSPs,
    },
  ];
}
