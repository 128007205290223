import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { NavDropdown, MenuItem} from 'react-bootstrap';
import 'alertifyjs/build/css/alertify.min.css';
import 'alertifyjs/build/css/themes/semantic.min.css';
const gSession = window.sessionStorage;

export class HeaderNav extends Component{
    constructor(props){
      super(props);
      this.state = {
        loggedInAs:gSession.getItem("name"),
        role: gSession.getItem("role") ,
        show:true
      }
    }
    logout() {
      //clear the session storage
  
      sessionStorage.removeItem('wat');
      sessionStorage.removeItem('wai');
      sessionStorage.removeItem("name");
      sessionStorage.removeItem("isAdmin");
      sessionStorage.removeItem("division");
      sessionStorage.removeItem("role");
      sessionStorage.removeItem("defaultVoucherValidity");
      sessionStorage.removeItem("defaultVoucherValue");
      sessionStorage.removeItem("maxVouchersPerMonth");
      sessionStorage.removeItem('divisionID');
      sessionStorage.removeItem(
        'voucherProgramsDetails'
      );

      window.location.href="./login";
    }
    render(){
        return(
                    <nav className="headerNav navbar navbar-default navbar-static-top">
      <div className="container-fluid">
        <div id="navbar" className="">
          <ul className="profileNav nav navbar-nav navbar-right">
          <button type="button" className="btn pull-left">
          <FontAwesomeIcon icon={faUser}/>
          </button>
              <NavDropdown eventKey={3} title={`${this.state.loggedInAs}`} id="basic-nav-dropdown">
      <MenuItem eventKey={3.1}> Account Settings</MenuItem>
      <MenuItem divider />
      <MenuItem onClick={this.logout} eventKey={3.4}>Logout</MenuItem>
    </NavDropdown>
          </ul>
        </div>
      </div>
    </nav>

        );
    }


}


 