// Utility functions
import { getMonths, aggregateMonthlyVolumesByMonth } from "./utils";

// Chart Options
import { commonChartOptions, commonChartPlotOptions } from "./chartOptions";

// Chart Colors
import { kccaDivisionsColors, SPTypeColors } from "./chartColors";

// Trips
const emptyingTripsPerDivision = data => {
  let months = getMonths(data);

  const empytingTripsFromCentral = aggregateMonthlyVolumesByMonth(
    data,
    months,
    "empytingTripsFromCentral"
  );
  const empytingTripsFromKawempe = aggregateMonthlyVolumesByMonth(
    data,
    months,
    "empytingTripsFromKawempe"
  );
  const empytingTripsFromMakindye = aggregateMonthlyVolumesByMonth(
    data,
    months,
    "empytingTripsFromMakindye"
  );
  const empytingTripsFromNakawa = aggregateMonthlyVolumesByMonth(
    data,
    months,
    "empytingTripsFromNakawa"
  );
  const empytingTripsFromOther = aggregateMonthlyVolumesByMonth(
    data,
    months,
    "empytingTripsFromOther"
  );
  const empytingTripsFromRubaga = aggregateMonthlyVolumesByMonth(
    data,
    months,
    "empytingTripsFromRubaga"
  );

  return {
    ...commonChartOptions,
    chart: {
      type: "column"
    },
    title: {
      text: "Emptying trips per division"
    },
    subtitle: {
      text:
        "Total number of trips in period: " +
        data
          .map(t => t.totalNumberOfEmptyingTrips)
          .reduce((accumulator, currentValue) => accumulator + currentValue, 0)
    },
    xAxis: {
      categories: months,
      crosshair: true
    },
    yAxis: {
      min: 0,
      title: {
        text: "Total number of trips made"
      }
    },
    plotOptions: {
      column: {
        ...commonChartPlotOptions.plotOptions.column
      }
    },
    tooltip: {
      ...commonChartPlotOptions.tooltipTrips
    },
    series: [
      {
        name: "Central",
        data: empytingTripsFromCentral,
        visible:
          empytingTripsFromCentral.reduce((a, b) => a + b, 0) > 0
            ? true
            : false,
        color: kccaDivisionsColors.Central
      },
      {
        name: "Kawempe",
        data: empytingTripsFromKawempe,
        visible:
          empytingTripsFromKawempe.reduce((a, b) => a + b, 0) > 0
            ? true
            : false,
        color: kccaDivisionsColors.Kawempe
      },
      {
        name: "Makindye",
        data: empytingTripsFromMakindye,
        visible:
          empytingTripsFromMakindye.reduce((a, b) => a + b, 0) > 0
            ? true
            : false,
        color: kccaDivisionsColors.Makindye
      },
      {
        name: "Nakawa",
        data: empytingTripsFromNakawa,
        visible:
          empytingTripsFromNakawa.reduce((a, b) => a + b, 0) > 0 ? true : false,
        color: kccaDivisionsColors.Nakawa
      },
      {
        name: "Outside Kampala",
        data: empytingTripsFromOther,
        visible:
          empytingTripsFromOther.reduce((a, b) => a + b, 0) > 0 ? true : false,
        color: kccaDivisionsColors.Outside
      },
      {
        name: "Rubaga",
        data: empytingTripsFromRubaga,
        visible:
          empytingTripsFromRubaga.reduce((a, b) => a + b, 0) > 0 ? true : false,
        color: kccaDivisionsColors.Rubaga
      }
    ]
  };
};

const emptyingTripsOfFSByCesspoolEmptiersVsGulpers = data => {
  let months = getMonths(data);

  const emptyingTripsByEmptiers = aggregateMonthlyVolumesByMonth(
    data,
    months,
    "emptyingTripsByEmptiers"
  );

  const emptyingTripsByGulpers = aggregateMonthlyVolumesByMonth(
    data,
    months,
    "emptyingTripsByGulpers"
  );

  const emptyingTripsByOther = aggregateMonthlyVolumesByMonth(
    data,
    months,
    "emptyingTripsByOther"
  );

  return {
    ...commonChartOptions,
    chart: {
      type: "column"
    },
    title: {
      text: "Emptying by service provider type"
    },
    subtitle: {
      text:
        "[Cesspool Emptiers vs Gulpers] <br> Total number of trips in period: " +
        data
          .map(t => t.totalNumberOfEmptyingTrips)
          .reduce((accumulator, currentValue) => accumulator + currentValue, 0)
    },
    xAxis: {
      categories: months,
      crosshair: true
    },
    yAxis: {
      min: 0,
      title: {
        text: "Total number of trips made"
      }
    },
    plotOptions: {
      column: {
        ...commonChartPlotOptions.plotOptions.column
      }
    },
    tooltip: {
      ...commonChartPlotOptions.tooltipTrips
    },
    series: [
      {
        name: "Emptiers",
        data: emptyingTripsByEmptiers,
        color: SPTypeColors.Emptiers
      },
      {
        name: "Gulpers",
        data: emptyingTripsByGulpers,
        color: SPTypeColors.Gulpers
      },
      {
        name: "Others",
        data: emptyingTripsByOther,
        color: SPTypeColors.Other
      }
    ]
  };
};

const emptyingTripsInInformalSettlementsVsOverallPie = data => {
  let emptyingTripsFromInformalSettlements = data
    .map(t => t.emptyingTripsFromInformalSector)
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  let totalNumberOfEmptyingTrips = data
    .map(t => t.totalNumberOfEmptyingTrips)
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

  let totalFromOtherSectors =
    totalNumberOfEmptyingTrips - emptyingTripsFromInformalSettlements;

  return {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie"
    },

    ...commonChartOptions,
    title: {
      text: "Emptying total: " + totalNumberOfEmptyingTrips
    },
    subtitle: {
      text:
        "Emptying trips from informal settlements vs  emptying trips formal settlements <br> " +
        "Total number of trips from informal settlements: " +
        emptyingTripsFromInformalSettlements +
        "<br>" +
        "Total number of trips from formal settlements: " +
        totalFromOtherSectors
    },
    tooltip: {
      pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>"
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: false
        },
        showInLegend: true
      }
    },
    series: [
      {
        name: "Settlements / Divisions",
        colorByPoint: true,
        data: [
          {
            name: "Informal settlements",
            y: emptyingTripsFromInformalSettlements
            // color: dumpingSiteColors.Bugolobi
          },
          {
            name: "Formal settlements",
            y: totalFromOtherSectors
            // color: dumpingSiteColors.Lubigi
          }
        ]
      }
    ]
  };
};

export {
  // Trips
  emptyingTripsPerDivision,
  emptyingTripsOfFSByCesspoolEmptiersVsGulpers,
  emptyingTripsInInformalSettlementsVsOverallPie
};
