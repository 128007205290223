import React, { useState } from "react";
import moment from "moment";

import { Modal, Button } from "react-bootstrap";

import {
  BootstrapTable,
  TableHeaderColumn,
  ExportCSVButton,
} from "react-bootstrap-table";

import PaymentDetails from "../PaymentDetails/PaymentDetails";

import {
  renderShowsTotal,
  timeConvert,
  timeConvertDifference,
  formatVoucherValues,
  formatStatusValues,
  tdStyle,
} from "../utils/tableUtilities";

const ActionButton = ({ row, isAdmin }) => {
  const [showPaymentsModal, setShowPaymentsModal] = useState(false);

  const handleShowPaymentsModal = () => {
    setShowPaymentsModal(!showPaymentsModal);
  };

  return (
    <>
      <Button
        style={{ marginRight: "10px" }}
        bsStyle="primary"
        onClick={handleShowPaymentsModal}
      >
        View Details
      </Button>

      <Modal show={showPaymentsModal} onHide={handleShowPaymentsModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            Voucher - {row.voucherReadableID} - {row.value}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ height: 550 }}>
          <PaymentDetails close={handleShowPaymentsModal} voucher={row} />
        </Modal.Body>
      </Modal>
    </>
  );
};

const VoucherPaymentsTable = ({ vouchers }) => {
  const { data } = vouchers;

  const vouchersForPayments =
    data &&
    data.filter((voucher) => voucher.status === 6 && voucher.jobStatus === 1);

  const actionButton = (cell, row, enumObject, rowIndex) => {
    return <ActionButton cell={cell} row={row} rowIndex={rowIndex} />;
  };

  const createCustomExportCSVButton = (onClick) => {
    return (
      <ExportCSVButton
        btnText="Export to CSV"
        btnGlyphicon="glyphicon-download"
        onClick={() => handleExportCSVButtonClick(onClick)}
      />
    );
  };

  const handleExportCSVButtonClick = (onClick) => {
    onClick();
  };

  const options = {
    exportCSVBtn: createCustomExportCSVButton,
    page: 1,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "25",
        value: 25,
      },
      {
        text: "All",
        value: vouchersForPayments && vouchersForPayments.length,
      },
    ],
    sizePerPage: 15,
    pageStartIndex: 1,
    paginationSize: 3,
    prePage: "Prev",
    nextPage: "Next",
    firstPage: "First",
    lastPage: "Last",
    paginationShowsTotal: renderShowsTotal,
    paginationPosition: "bottom",
  };
  return (
    <div className="voucher-table-container">
      <BootstrapTable
        data={vouchersForPayments}
        striped
        hover
        pagination={true}
        options={options}
        exportCSV
        csvFileName={
          "vouchers_payments" +
          moment(Date.now()).local().format("YYYY_MM_DD_HHmmss") +
          ".csv"
        }
      >
        <TableHeaderColumn
          isKey
          dataSort={true}
          dataField="voucherReadableID"
          width="140px"
          thStyle={tdStyle}
          tdStyle={tdStyle}
          csvHeader="Voucher Number"
        >
          Voucher
        </TableHeaderColumn>
        <TableHeaderColumn
          dataSort={true}
          dataField="beneficiary"
          width="180px"
          thStyle={tdStyle}
          tdStyle={tdStyle}
          csvHeader="Beneficiary"
        >
          Beneficiary Name
        </TableHeaderColumn>
        <TableHeaderColumn
          dataSort={true}
          dataField="beneficiaryNumber"
          width="140px"
          thStyle={tdStyle}
          tdStyle={tdStyle}
          csvHeader="Beneficiary Number"
        >
          Phone
        </TableHeaderColumn>
        <TableHeaderColumn
          dataSort={true}
          dataField="householdID"
          width="130px"
          thStyle={tdStyle}
          tdStyle={tdStyle}
          csvHeader="Household Identifier"
        >
          Household ID
        </TableHeaderColumn>
        <TableHeaderColumn
          dataSort={true}
          dataField="validForDivision"
          width="100px"
          thStyle={tdStyle}
          tdStyle={tdStyle}
          csvHeader="Division"
        >
          Division
        </TableHeaderColumn>
        <TableHeaderColumn
          dataSort={true}
          dataField="value"
          dataFormat={formatVoucherValues}
          width="100px"
          thStyle={tdStyle}
          tdStyle={tdStyle}
          csvHeader="Voucher Value"
        >
          Value
        </TableHeaderColumn>
        <TableHeaderColumn
          dataSort={true}
          dataField="catsName"
          width="130px"
          thStyle={tdStyle}
          tdStyle={tdStyle}
          csvHeader="VHT / CATs Name"
        >
          CATs
        </TableHeaderColumn>
        <TableHeaderColumn
          dataSort={true}
          dataField="officerName"
          width="130px"
          thStyle={tdStyle}
          tdStyle={tdStyle}
          csvHeader="Division Officer"
        >
          Officer
        </TableHeaderColumn>
        <TableHeaderColumn
          dataSort={true}
          dataField="serviceProvider"
          width="150px"
          thStyle={tdStyle}
          tdStyle={tdStyle}
          csvHeader="Service Provider"
        >
          Service Provider
        </TableHeaderColumn>
        <TableHeaderColumn
          dataSort={true}
          dataField="updatedAt"
          dataFormat={timeConvert}
          width="130px"
          thStyle={tdStyle}
          tdStyle={tdStyle}
          csvHeader="Redeemed On"
        >
          Redeemed On
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="status"
          dataFormat={formatStatusValues}
          width="130px"
          thStyle={tdStyle}
          tdStyle={tdStyle}
          csvFormat={formatStatusValues}
          csvHeader="Status"
        >
          Status
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="action"
          dataFormat={actionButton}
          dataAlign="center"
          thStyle={tdStyle}
          tdStyle={tdStyle}
        >
          Action
        </TableHeaderColumn>
      </BootstrapTable>
    </div>
  );
};

export default VoucherPaymentsTable;
