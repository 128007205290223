import Highcharts from "highcharts";

// Utility functions
import {
  getUTCDateValue,
  getMonths,
  getDivisions,
  aggregateMonthlyVolumesByMonth
} from "./utils";

// Chart Options
import { commonChartOptions, commonChartPlotOptions } from "./chartOptions";

// Chart Colors
import {
  dumpingSiteColors,
  kccaDivisionsColors,
  SPTypeColors
} from "./chartColors";

// Volumes
const dumpingVolumeAtSewagePlants = data => {
  const months = getMonths(data);

  const volumeAtBugolobi = aggregateMonthlyVolumesByMonth(
    data,
    months,
    "totalVolumeDumpedAtBugolobi"
  );

  const volumeAtLubigi = aggregateMonthlyVolumesByMonth(
    data,
    months,
    "totalVolumeDumpedAtLubigi"
  );

  return {
    ...commonChartOptions,
    chart: {
      type: "column"
    },
    title: {
      text:
        "Dumping volumes recorded at Bugolobi and Lubigi Sewage treatment plant"
    },
    plotOptions: {
      column: {
        ...commonChartPlotOptions.plotOptions.column
      }
    },
    tooltip: {
      ...commonChartPlotOptions.tooltipVolumes
    },
    xAxis: {
      categories: months,
      crosshair: true
    },
    yAxis: {
      min: 0,
      title: {
        text: "Total volume dumped (m3)"
      }
    },
    series: [
      {
        name: "Bugolobi",
        data: volumeAtBugolobi,
        visible: volumeAtBugolobi.reduce((a, b) => a + b, 0) > 0 ? true : false,
        color: dumpingSiteColors.Bugolobi
      },
      {
        name: "Lubigi",
        data: volumeAtLubigi,
        visible: volumeAtLubigi.reduce((a, b) => a + b, 0) > 0 ? true : false,
        color: dumpingSiteColors.Lubigi
      }
    ]
  };
};

const dumpingVolumeBySPTypeTimeSeries = data => {
  if (data.length > 1) {
    // User has selected a filter spanning more than a day, we construct
    // a line time series by day chart
    let totalVolumeDumpedArray = [];
    let gulpersVolumeDumpedArray = [];
    let emptiersVolumeDumpedArray = [];
    let othersVolumeDumpedArray = [];

    Object.entries(data).forEach(([key, value]) => {
      let dateValue = getUTCDateValue(value);

      totalVolumeDumpedArray.push([dateValue, value.totalVolumeDumped / 1000]);

      gulpersVolumeDumpedArray.push([
        dateValue,
        value.totalVolumeDumpedByGulpers / 1000
      ]);

      emptiersVolumeDumpedArray.push([
        dateValue,
        value.totalVolumeDumpedByEmptiers / 1000
      ]);

      othersVolumeDumpedArray.push([
        dateValue,
        value.totalVolumeDumpedByOthers / 1000
      ]);
    });

    return {
      ...commonChartOptions,
      chart: {
        zoomType: "x"
      },
      title: {
        text: "Trend of dumping volumes by service provider type"
      },
      subtitle: {
        text:
          document.ontouchstart === undefined
            ? "Gulpers vs Emptiers <br>Total volume dumped: " +
            new Intl.NumberFormat("lg-UG").format(
              Math.ceil(
                data
                  .map(d => d.totalVolumeDumped / 1000)
                  .reduce(
                    (accumulator, currentValue) => accumulator + currentValue,
                    0
                  )
              )
            ) +
            " m3 <br>Click and drag in the plot area to zoom in"
            : "Pinch the chart to zoom in"
      },

      xAxis: {
        type: "datetime"
      },
      yAxis: {
        title: {
          text: "Total volume dumped (m3)"
        }
      },
      tooltip: {
        split: true,
        valueSuffix: " m3 dumped"
      },
      ...commonChartPlotOptions,
      series: [
        {
          name: "Total Volume",
          data: totalVolumeDumpedArray,
          type: "spline",
          color: SPTypeColors.Other
        },
        {
          name: "Gulpers",
          data: gulpersVolumeDumpedArray,
          type: "areaspline",
          color: SPTypeColors.Gulpers
        },
        {
          name: "Emptiers",
          data: emptiersVolumeDumpedArray,
          type: "areaspline",
          color: SPTypeColors.Emptiers
        }
      ]
    };
  } else {
    // User has selected a filter spanning 1 day

    let month = getMonths(data);

    let totalVolumeDumped = [
      data
        .map(d => d.totalVolumeDumped / 1000)
        .reduce((accumulator, currentValue) => accumulator + currentValue, 0)
    ];

    let totalVolumeDumpedByGulpers = [
      data
        .map(d => d.totalVolumeDumpedByGulpers / 1000)
        .reduce((accumulator, currentValue) => accumulator + currentValue, 0)
    ];

    let totalVolumeDumpedByEmptiers = [
      data
        .map(d => d.totalVolumeDumpedByEmptiers / 1000)
        .reduce((accumulator, currentValue) => accumulator + currentValue, 0)
    ];

    return {
      ...commonChartOptions,
      chart: {
        type: "column"
      },
      title: {
        text: "Trend of dumping volumes by service provider type"
      },
      subtitle: {
        text:
          "Gulpers vs Emptiers <br>Total volume dumped: " +
          new Intl.NumberFormat("lg-UG").format(
            Math.ceil(
              data
                .map(d => d.totalVolumeDumped / 1000)
                .reduce(
                  (accumulator, currentValue) => accumulator + currentValue,
                  0
                )
            )
          ) +
          " m3"
      },
      plotOptions: {
        column: { ...commonChartPlotOptions.plotOptions.column }
      },
      xAxis: {
        categories: month,
        crosshair: true
      },
      yAxis: {
        min: 0,
        title: {
          text: "Total volume dumped (m3)"
        }
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.0f} m3</b></td></tr>',
        footerFormat: "</table>",
        shared: true,
        useHTML: true
      },
      series: [
        {
          name: "Total volume dumped",
          data: totalVolumeDumped,
          color: SPTypeColors.Other
        },
        {
          name: "Gulpers",
          data: totalVolumeDumpedByGulpers,
          visible: totalVolumeDumpedByGulpers > 0 ? true : false,
          color: SPTypeColors.Gulpers
        },
        {
          name: "Emptiers",
          data: totalVolumeDumpedByEmptiers,
          visible: totalVolumeDumpedByEmptiers > 0 ? true : false,
          color: SPTypeColors.Emptiers
        }
      ]
    };
  }
};

const dumpingVolumeAtSewagePlantsPie = data => {
  let dataTotalVolumeDumpedAtBugolobi = Math.ceil(
    ...data
      .map(v => v.totalVolumeDumpedAtBugolobi / 1000)
      .reduce((accumulator, currentValue) => [accumulator[0] + currentValue], [
        0
      ])
  );
  let dataTotalVolumeDumpedAtLubigi = Math.ceil(
    ...data
      .map(v => v.totalVolumeDumpedAtLubigi / 1000)
      .reduce((accumulator, currentValue) => [accumulator[0] + currentValue], [
        0
      ])
  );

  return {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie"
    },

    ...commonChartOptions,
    title: {
      text: "Dumping volumes at sewage plants"
    },
    subtitle: {
      text:
        "Bugolobi vs Lubigi <br> " +
        "Total volume dumped: " +
        new Intl.NumberFormat("lg-UG").format(
          Math.ceil(
            data
              .map(d => d.totalVolumeDumped / 1000)
              .reduce(
                (accumulator, currentValue) => accumulator + currentValue,
                0
              )
          )
        ) +
        " m3"
    },
    tooltip: {
      pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>"
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: false
        },
        showInLegend: true
      }
    },
    series: [
      {
        name: "Sewage plants",
        colorByPoint: true,
        data: [
          {
            name: "Bugolobi",
            y: dataTotalVolumeDumpedAtBugolobi,
            color: dumpingSiteColors.Bugolobi
          },
          {
            name: "Lubigi",
            y: dataTotalVolumeDumpedAtLubigi,
            color: dumpingSiteColors.Lubigi
          }
        ]
      }
    ]
  };
};

// Trips
const trendOfDumpingTrips = data => {
  if (data.length > 1) {
    // User has selected a filter spanning more than a day, we construct
    // a line time series by day chart

    let totalTripsMade = [];

    Object.entries(data).forEach(([key, value]) => {
      let dateValue = getUTCDateValue(value);

      totalTripsMade.push([
        // moment.utc(dateValue).valueOf(),
        dateValue,
        value.totalNumberOfDumpingTrips
      ]);
    });
    return {
      chart: {
        zoomType: "x"
      },
      ...commonChartOptions,
      title: {
        text: "Trend of dumping trips"
      },
      subtitle: {
        text:
          document.ontouchstart === undefined
            ? "Total number of trips made: " +
            +new Intl.NumberFormat("lg-UG").format(
              Math.ceil(
                data
                  .map(d => d.totalNumberOfDumpingTrips)
                  .reduce(
                    (accumulator, currentValue) => accumulator + currentValue,
                    0
                  )
              )
            ) +
            "<br>Click and drag in the plot area to zoom in"
            : "Pinch the chart to zoom in"
      },
      plotOptions: {
        area: {
          ...commonChartPlotOptions.plotOptions.area,
          fillColor: {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 1
            },
            stops: [
              [0, Highcharts.getOptions().colors[0]],
              [
                1,
                Highcharts.Color(Highcharts.getOptions().colors[0])
                  .setOpacity(0)
                  .get("rgba")
              ]
            ]
          }
        }
      },
      xAxis: {
        type: "datetime"
      },
      yAxis: {
        title: {
          text: "Number of trips made"
        },
        min: 0
      },
      legend: {
        enabled: false
      },
      series: [
        {
          type: "area",
          name: "Trips Made",
          data: totalTripsMade,
          color: SPTypeColors.Other
        }
      ]
    };
  } else {
    // User has selected a filter spanning 1 day
    let month = getMonths(data);

    return {
      ...commonChartOptions,
      chart: {
        type: "column"
      },
      title: {
        text: "Number of dumping trips"
      },
      subtitle: {
        text:
          "Total number of trips made: " +
          +new Intl.NumberFormat("lg-UG").format(
            Math.ceil(
              data
                .map(d => d.totalNumberOfDumpingTrips)
                .reduce(
                  (accumulator, currentValue) => accumulator + currentValue,
                  0
                )
            )
          )
      },
      plotOptions: {
        column: { ...commonChartPlotOptions.plotOptions.column }
      },
      xAxis: {
        categories: month,
        crosshair: true
      },
      yAxis: {
        min: 0,
        title: {
          text: "Total number of trips made"
        }
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.0f} trips</b></td></tr>',
        footerFormat: "</table>",
        shared: true,
        useHTML: true
      },
      series: [
        {
          name: "Trips",
          data: [
            data
              .map(d => d.totalNumberOfDumpingTrips)
              .reduce(
                (accumulator, currentValue) => accumulator + currentValue,
                0
              )
          ],
          color: SPTypeColors.Other
        }
      ]
    };
  }
};

const dumpingTripsRecordedAtSewageLocationsPerDivision = data => {
  return {
    ...commonChartOptions,
    chart: {
      type: "column"
    },
    title: {
      text:
        "Dumping trips recorded at Bugolobi and Lubigi Sewage Treatment Plant per division"
    },
    plotOptions: {
      column: { ...commonChartPlotOptions.plotOptions.column }
    },
    xAxis: {
      categories: getDivisions(data),
      crosshair: true
    },
    yAxis: {
      min: 0,
      title: {
        text: "Total number of trips made"
      }
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        '<td style="padding:0"><b>{point.y:.0f} trips</b></td></tr>',
      footerFormat: "</table>",
      shared: true,
      useHTML: true
    },
    series: [
      {
        name: "Bugolobi",
        data: [
          ...data
            .map(
              t => t.totalNumberOfDumpingTripsFromCentralToBugolobiDumpingSite
            )
            .reduce(
              (accumulator, currentValue) => [accumulator[0] + currentValue],
              [0]
            ),
          ...data
            .map(
              t => t.totalNumberOfDumpingTripsFromKawempeToBugolobiDumpingSite
            )
            .reduce(
              (accumulator, currentValue) => [accumulator[0] + currentValue],
              [0]
            ),
          ...data
            .map(
              t => t.totalNumberOfDumpingTripsFromMakindyeToBugolobiDumpingSite
            )
            .reduce(
              (accumulator, currentValue) => [accumulator[0] + currentValue],
              [0]
            ),
          ...data
            .map(
              t => t.totalNumberOfDumpingTripsFromNakawaToBugolobiDumpingSite
            )
            .reduce(
              (accumulator, currentValue) => [accumulator[0] + currentValue],
              [0]
            ),
          ...data
            .map(
              t =>
                t.totalNumberOfDumpingTripsFromOutsideKampalaToBugolobiDumpingSite
            )
            .reduce(
              (accumulator, currentValue) => [accumulator[0] + currentValue],
              [0]
            ),
          ...data
            .map(
              t => t.totalNumberOfDumpingTripsFromRubagaToBugolobiDumpingSite
            )
            .reduce(
              (accumulator, currentValue) => [accumulator[0] + currentValue],
              [0]
            )
        ],
        color: dumpingSiteColors.Bugolobi
      },
      {
        name: "Lubigi",
        data: [
          ...data
            .map(t => t.totalNumberOfDumpingTripsFromCentralToLubigiDumpingSite)
            .reduce(
              (accumulator, currentValue) => [accumulator[0] + currentValue],
              [0]
            ),
          ...data
            .map(t => t.totalNumberOfDumpingTripsFromKawempeToLubigiDumpingSite)
            .reduce(
              (accumulator, currentValue) => [accumulator[0] + currentValue],
              [0]
            ),
          ...data
            .map(
              t => t.totalNumberOfDumpingTripsFromMakindyeToLubigiDumpingSite
            )
            .reduce(
              (accumulator, currentValue) => [accumulator[0] + currentValue],
              [0]
            ),
          ...data
            .map(t => t.totalNumberOfDumpingTripsFromNakawaToLubigiDumpingSite)
            .reduce(
              (accumulator, currentValue) => [accumulator[0] + currentValue],
              [0]
            ),
          ...data
            .map(
              t =>
                t.totalNumberOfDumpingTripsFromOutsideKampalaToLubigiDumpingSite
            )
            .reduce(
              (accumulator, currentValue) => [accumulator[0] + currentValue],
              [0]
            ),
          ...data
            .map(t => t.totalNumberOfDumpingTripsFromRubagaToLubigiDumpingSite)
            .reduce(
              (accumulator, currentValue) => [accumulator[0] + currentValue],
              [0]
            )
        ],
        color: dumpingSiteColors.Lubigi
      }
    ]
  };
};

const dumpingTripsPerDivisionByKCCADrivers = data => {
  let totalTripsByKCCA = [];
  let tripsByKCCAFromCentral = [];
  let tripsByKCCAFromKawempe = [];
  let tripsByKCCAFromMakindye = [];
  let tripsByKCCAFromNakawa = [];
  let tripsByKCCAFromOther = [];
  let tripsByKCCAFromRubaga = [];

  Object.entries(data).forEach(([key, value]) => {
    let dateValue = getUTCDateValue(value);

    totalTripsByKCCA.push([
      dateValue,
      value.totalNumberOfDumpingTripsByKCCATrucks
    ]);

    tripsByKCCAFromCentral.push([
      dateValue,
      value.totalNumberOfDumpingTripsFromCentralByKCCATrucks
    ]);

    tripsByKCCAFromKawempe.push([
      dateValue,
      value.totalNumberOfDumpingTripsFromKawempeByKCCATrucks
    ]);

    tripsByKCCAFromMakindye.push([
      dateValue,
      value.totalNumberOfDumpingTripsFromMakindyeByKCCATrucks
    ]);

    tripsByKCCAFromNakawa.push([
      dateValue,
      value.totalNumberOfDumpingTripsFromNakawaByKCCATrucks
    ]);

    tripsByKCCAFromOther.push([
      dateValue,
      value.totalNumberOfDumpingTripsFromOutsideKampalaByKCCATrucks
    ]);

    tripsByKCCAFromRubaga.push([
      dateValue,
      value.totalNumberOfDumpingTripsFromRubagaByKCCATrucks
    ]);
  });

  return {
    ...commonChartOptions,
    title: {
      text: "Trend of dumping trips per division by KCCA drivers"
    },
    subtitle: {
      text:
        "Total number of trips made: " +
        new Intl.NumberFormat("lg-UG").format(
          Math.ceil(
            data
              .map(t => t.totalNumberOfDumpingTripsByKCCATrucks)
              .reduce(
                (accumulator, currentValue) => accumulator + currentValue,
                0
              )
          )
        ) +
        "<br>Click the columns to view dumping trips over time"
    },
    xAxis: [
      {
        id: 0,
        type: "datetime"
      },
      {
        id: 1,
        type: "category"
      }
    ],
    yAxis: {
      title: {
        text: "Number of trips"
      }
    },
    plotOptions: {
      series: {
        ...commonChartPlotOptions.plotOptions.column
      },
      spline: {
        ...commonChartPlotOptions.plotOptions.spline
      }
    },
    series: [
      {
        name: "KCCA Trips",
        type: "column",
        xAxis: 1,
        data: [
          {
            name: "Central",
            y: Math.ceil(
              data
                .map(t => t.totalNumberOfDumpingTripsFromCentralByKCCATrucks)
                .reduce(
                  (accumulator, currentValue) => accumulator + currentValue,
                  0
                )
            ),
            color: kccaDivisionsColors.Central,
            drilldown: "Central"
          },
          {
            name: "Kawempe",
            y: Math.ceil(
              data
                .map(v => v.totalNumberOfDumpingTripsFromKawempeByKCCATrucks)
                .reduce(
                  (accumulator, currentValue) => accumulator + currentValue,
                  0
                )
            ),
            color: kccaDivisionsColors.Kawempe,
            drilldown: "Kawempe"
          },
          {
            name: "Makindye",
            y: Math.ceil(
              data
                .map(v => v.totalNumberOfDumpingTripsFromMakindyeByKCCATrucks)
                .reduce(
                  (accumulator, currentValue) => accumulator + currentValue,
                  0
                )
            ),
            color: kccaDivisionsColors.Makindye,
            drilldown: "Makindye"
          },
          {
            name: "Nakawa",
            y: Math.ceil(
              data
                .map(v => v.totalNumberOfDumpingTripsFromNakawaByKCCATrucks)
                .reduce(
                  (accumulator, currentValue) => accumulator + currentValue,
                  0
                )
            ),
            color: kccaDivisionsColors.Nakawa,
            drilldown: "Nakawa"
          },
          {
            name: "Other",
            y: Math.ceil(
              data
                .map(
                  v => v.totalNumberOfDumpingTripsFromOutsideKampalaByKCCATrucks
                )
                .reduce(
                  (accumulator, currentValue) => accumulator + currentValue,
                  0
                )
            ),
            color: kccaDivisionsColors.Outside,
            drilldown: "Other"
          },
          {
            name: "Rubaga",
            y: Math.ceil(
              data
                .map(v => v.totalNumberOfDumpingTripsFromRubagaByKCCATrucks)
                .reduce(
                  (accumulator, currentValue) => accumulator + currentValue,
                  0
                )
            ),
            color: kccaDivisionsColors.Rubaga,
            drilldown: "Rubaga"
          }
        ]
      }
    ],
    drilldown: {
      allowPointDrilldown: false,
      xAxis: 0,

      series: [
        {
          name: "Central",
          id: "Central",
          type: "spline",
          data: tripsByKCCAFromCentral,
          color: kccaDivisionsColors.Central
        },
        {
          name: "Kawempe",
          id: "Kawempe",
          type: "spline",
          data: tripsByKCCAFromKawempe,
          color: kccaDivisionsColors.Kawempe
        },
        {
          name: "Makindye",
          id: "Makindye",
          type: "spline",
          data: tripsByKCCAFromMakindye,
          color: kccaDivisionsColors.Makindye
        },
        {
          name: "Nakawa",
          id: "Nakawa",
          type: "spline",
          data: tripsByKCCAFromNakawa,
          color: kccaDivisionsColors.Nakawa
        },
        {
          name: "Other",
          id: "Other",
          type: "spline",
          data: tripsByKCCAFromOther,
          color: kccaDivisionsColors.Outside
        },
        {
          name: "Rubaga",
          id: "Rubaga",
          type: "spline",
          data: tripsByKCCAFromRubaga,
          color: kccaDivisionsColors.Rubaga
        }
      ]
    }
  };
};

export {
  //Volumes
  dumpingVolumeAtSewagePlants,
  dumpingVolumeBySPTypeTimeSeries,
  dumpingVolumeAtSewagePlantsPie,
  //Trips
  trendOfDumpingTrips,
  dumpingTripsRecordedAtSewageLocationsPerDivision,
  dumpingTripsPerDivisionByKCCADrivers
};
