import React, { useState } from "react";
import { Grid, Tabs, Tab, Row, Col } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCreditCard } from "@fortawesome/free-solid-svg-icons";

// Components
import { Layout } from "../../components/inc/Layout";
import { CustomLoading } from "../../components/inc/Layout";
import VoucherPaymentsTable from "../../components/VoucherPaymentsTable/VoucherPaymentsTable";
import VoucherOverviewCard from "../../components/VoucherOverviewCard/VoucherOverviewCard";
import VoucherTable from "../../components/VoucherTable/VoucherTable";
import VoucherSPTable from "../../components/VoucherSPTable/VoucherSPTable";
import CATSTable from "../../components/CATSTable/CATSTable";
import VoucherProgramView from "../../components/VoucherProgramView/VoucherProgramView";
import CATSOverviewCard from "../../components/CATSOverviewCard/CATSOverviewCard";

import { useGetVoucherData } from "../../components/customHooks/tweyonjeDB";

const gSession = window.sessionStorage;

function Voucher() {
  const [key, setKey] = useState("overview");
  const id = gSession.getItem("wai");
  const division = gSession.getItem("division");
  const divisionID = gSession.getItem("divisionID");
  const isAdmin = gSession.getItem("isAdmin") === "true" ? true : false;

  const [
    { isLoading, vouchers, cats, divisions, voucherProgram, voucherSPs },
  ] = useGetVoucherData(isAdmin, id, null, null, null, divisionID);

  return (
    <Layout>
      <div className="col-md-6 title">
        <h4>
          <FontAwesomeIcon icon={faCreditCard} /> Vouchers
        </h4>
        <br />
      </div>
      <div className="col-md-12" style={{ marginTop: "15px" }}>
        {isLoading ? (
          <CustomLoading text="Loading voucher programme data..." />
        ) : (
          <>
            <Tabs
              id="tabs"
              activeKey={key}
              onSelect={(key) => setKey(key)}
              bsStyle="pills"
            >
              <Tab eventKey="overview" title="OVERVIEW">
                <Grid fluid>
                  <Row>
                    <Col lg={12} sm={12} md={12}>
                      <VoucherOverviewCard
                        vouchers={vouchers}
                        voucherProgram={voucherProgram}
                        isAdmin={isAdmin}
                        division={division}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12} sm={12}>
                      <VoucherTable
                        vouchers={vouchers}
                        isAdmin={isAdmin}
                        sps={voucherSPs}
                      />
                    </Col>
                  </Row>
                </Grid>
              </Tab>
              <Tab
                eventKey="vouchers"
                title={`VOUCHERS (${vouchers && vouchers.count})`}
              >
                <Grid fluid>
                  <Row>
                    <Col lg={12} sm={12}>
                      <VoucherTable
                        vouchers={vouchers}
                        isAdmin={isAdmin}
                        sps={voucherSPs}
                      />
                    </Col>
                  </Row>
                </Grid>
              </Tab>
              <Tab
                eventKey="cats"
                title={`CATs / VHTs (${cats && cats.count})`}
              >
                <Grid fluid>
                  <Row>
                    <Col lg={12} sm={12}>
                      <CATSOverviewCard CATS={cats} isAdmin={isAdmin} />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12} sm={12}>
                      <CATSTable CATS={cats} isAdmin={isAdmin} />
                    </Col>
                  </Row>
                </Grid>
              </Tab>

              <Tab
                eventKey="voucherServiceProviders"
                title={`SERVICE PROVIDERS`}
              >
                <Grid fluid>
                  <Row>
                    <Col lg={12} sm={12}>
                      <VoucherSPTable sps={voucherSPs} isAdmin={isAdmin} />
                    </Col>
                  </Row>
                </Grid>
              </Tab>

              <Tab eventKey="payments" title={`READY FOR PAYMENT`}>
                <Grid fluid>
                  <Row>
                    <Col lg={12} sm={12}>
                      <VoucherPaymentsTable vouchers={vouchers} />
                    </Col>
                  </Row>
                </Grid>
              </Tab>

              <Tab eventKey="settings" title={`PROGRAMS`} disabled={!isAdmin}>
                <Grid fluid>
                  <Row>
                    <Col lg={12} sm={12}>
                      <div>{/* <button value=""> Button </button> */}</div>
                      <VoucherProgramView
                        voucherProgram={voucherProgram}
                        vouchers={vouchers}
                        divisions={divisions}
                      />
                    </Col>
                  </Row>
                </Grid>
              </Tab>
            </Tabs>
          </>
        )}
      </div>
    </Layout>
  );
}

export default Voucher;
