import React, { Component } from 'react';
import 'bootstrap3/dist/css/bootstrap.min.css';
import '../../styles/global.css';
import {SideNav} from './SideNav';
import {HeaderNav} from './HeaderNav';
export class Layout extends Component{
    constructor(props){
      super(props);
      this.state = {
  
      }
    }

    render(){
        return(
            <React.Fragment>
                 <div className="container-fluid">
                    <div id="sideNavBar" className="col-md-2 noPadding">
                         <SideNav />
                    </div>
                 <div id="detailComponent"className="col-md-10 noPadding">
                   <HeaderNav />
                         <div className="col-md-12">
                             {this.props.children}
                        </div>
                 </div>
                    
                 </div>
            </React.Fragment>
        );
    }


}
export class Loading extends Component {
    render(){
        return(
            <div className="col-md-12 loading">

            </div>
        );
    }
}
export class CustomLoading extends Component {
    render(){
        return(
            <div className="col-md-12 custom loading">
                <span>{this.props.text}</span>
            </div>
        );
    }
}


 