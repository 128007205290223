import React, { Component } from 'react';
import { Layout, CustomLoading } from './inc/Layout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareSquare, faTimes } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { Modal } from 'react-bootstrap';
import { ReassignJob } from './Dispatch';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import 'react-toggle-switch/dist/css/switch.min.css';
import 'alertifyjs/build/css/alertify.min.css';
import 'alertifyjs/build/css/themes/semantic.min.css';
const alertifyjs = require('alertifyjs');
require('moment-countdown');

const addr = require('../env_config').default;
var unirest = require('unirest');

const gSession = window.sessionStorage;
const token = gSession.getItem('wat');

export class Dispatchers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pending: [],
      isLoaded: false,
    };
    this.getData = this.getData.bind(this);
    this.statusFormater = this.statusFormater.bind(this);
  }

  componentDidMount() {
    const thisApp = this;
    setInterval(function () {
      thisApp.getData();
    }, 1000);
    this.getData();
  }
  getData() {
    const thisApp = this;
    var req = unirest('GET', addr + 'api/jobs/pending/all');

    req.headers({
      'cache-control': 'no-cache',
      authorization: token,
    });

    req.end(function (res) {
      if (res.error) console.log(res);

      if (res.status == 200) {
        thisApp.setState({
          pending: res.body,
          isLoaded: true,
        });
      } else {
        thisApp.setState({
          isLoaded: true,
        });
      }
    });
  }
  timeConvert(cell, row) {
    return cell && cell
      ? moment(cell).format('h:mm:ss a DD/MM/YY')
      : 'Date not available';
  }

  handleExportCSVButtonClick = (onClick) => {
    onClick();
  };
  createCustomExportCSVButton = (onClick) => {
    return (
      <button className='btn btn-primary' onClick={onClick}>
        Export to CSV
      </button>
    );
  };

  renderShowsTotal(start, to, total) {
    return (
      <p style={{ color: 'blue' }}>
        From {start} to {to}, totals is {total}&nbsp;&nbsp;
      </p>
    );
  }

  enumFormatter(cell, row, enumObject) {
    return enumObject[cell];
  }
  timeFromNow(cell, row) {
    //moment("1982-05-25").countdown().toString()
    return cell && cell ? moment(cell).fromNow() : 'Date not availbale';
  }
  statusFormater(cell, row) {
    var x = moment();
    var y = moment(row.dispatch_time);
    var duration = moment.duration(x.diff(y)).as('minutes');
    let end_time = moment(y).add(3, 'minutes');
    let timeLeft = moment(end_time).countdown().toString();

    if (cell >= 0 && row.entreprenuer_id && row.entreprenuer_id.length > 0) {
      return <span className='label label-success'>Accepted</span>;
    } else if (cell === 0 && row.declined.length > 0 && !row.entreprenuer_id) {
      return <span className='label label-danger'>Declined</span>;
    } else if (cell === 0 && row.recommended && row.recommended.length > 0) {
      return <span className='label label-warning'>Reffered</span>;
    } else if (
      cell === 0 &&
      duration <= 3 &&
      row.notification &&
      row.notification.length > 0 &&
      !row.entreprenuer_id
    ) {
      return <span className='label label-warning'>{timeLeft}</span>;
    } else {
      return <span className='label label-default'>Unattended</span>;
    }
  }
  onDeleteRow(row) {
    this.products = this.products.filter((product) => {
      return product._id !== row[0];
    });

    this.setState({
      data: this.products,
    });
  }

  removeButton(cell, row, enumObject, index) {
    var x = moment();
    var y = moment(row && row.dispatch_time);
    var duration = moment.duration(x.diff(y)).as('minutes');
    if (
      duration <= 3 &&
      row.notification &&
      row.notification.length > 0 &&
      !row.entreprenuer_id
    ) {
      return <React.Fragment></React.Fragment>;
    } else {
      return <ButtonRemove duration={duration} row={row} _id={cell} />;
    }
  }
  showTitle(cell, row, enumObject, index) {
    return <span title={cell}>{cell} </span>;
  }

  render() {
    let products = this.state.pending;

    const dispatch = {
      true: 'Auto',
      false: 'Manual',
    };

    const options = {
      page: 1, // which page you want to show as default
      sizePerPageList: [
        {
          text: '10',
          value: 10,
        },
        {
          text: '25',
          value: 25,
        },
        {
          text: 'All',
          value: products && products.length,
        },
      ], // you can change the dropdown list for size per page
      sizePerPage: 10, // which size per page you want to locate as default
      pageStartIndex: 1, // where to start counting the pages
      paginationSize: 3, // the pagination bar size.
      prePage: 'Prev', // Previous page button text
      nextPage: 'Next', // Next page button text
      firstPage: 'First', // First page button text
      lastPage: 'Last', // Last page button text
      paginationShowsTotal: this.renderShowsTotal, // Accept bool or function
      paginationPosition: 'bottom', // default is bottom, top and both is all available
      // hideSizePerPage: true > You can hide the dropdown for sizePerPage
      // alwaysShowAllBtns: true // Always show next and previous button
      // withFirstAndLast: false > Hide the going to First and Last page button
      deleteBtn: this.createCustomDeleteButton,
    };

    return (
      <Layout>
        <div className='col-md-8 title'>
          <h4>
            <FontAwesomeIcon icon={faShareSquare} /> Dispatchers
          </h4>
        </div>
        <div className='content-container padding-normal  col-md-12'>
          {this.state.isLoaded === false ? (
            <CustomLoading text="Loading dispatchers' data"></CustomLoading>
          ) : (
            <BootstrapTable
              tableBodyClass='tableCustom'
              data={products}
              striped
              hover
              pagination={true}
              options={options}
            >
              <TableHeaderColumn
                hidden={true}
                width='70px'
                dataSort={true}
                isKey
                dataField='job_id'
              >
                Job ID
              </TableHeaderColumn>
              <TableHeaderColumn
                width='120px'
                dataSort={true}
                dataField='full_name'
              >
                Customer name
              </TableHeaderColumn>
              <TableHeaderColumn width='105px' dataField='phone_number'>
                Customer Phone
              </TableHeaderColumn>
              <TableHeaderColumn dataSort={true} dataField='location'>
                Customer location
              </TableHeaderColumn>
              <TableHeaderColumn dataSort={true} dataField='facility_type'>
                Facility Type
              </TableHeaderColumn>
              <TableHeaderColumn
                width='120px'
                dataSort={true}
                dataFormat={this.showTitle}
                dataField='entreprenuer'
              >
                Service provider
              </TableHeaderColumn>
              <TableHeaderColumn
                width='105px'
                dataField='entreprenuer_phone_number'
              >
                Service provider Phone number
              </TableHeaderColumn>
              <TableHeaderColumn width='50px' dataField='number_of_tries'>
                No. of tries
              </TableHeaderColumn>
              <TableHeaderColumn
                width='150px'
                dataFormat={this.statusFormater}
                dataField='status'
              >
                Status
              </TableHeaderColumn>
              <TableHeaderColumn
                width='90px'
                dataSort={true}
                dataField='auto_dispatch'
                dataFormat={this.enumFormatter}
                formatExtraData={dispatch}
              >
                Dispatch
              </TableHeaderColumn>
              <TableHeaderColumn dataField='_id' dataFormat={this.removeButton}>
                Options
              </TableHeaderColumn>
            </BootstrapTable>
          )}
        </div>
      </Layout>
    );
  }
}

class ButtonRemove extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }
  render() {
    return (
      <React.Fragment>
        {this.props.row &&
        this.props.row.status >= 0 &&
        this.props.row.entreprenuer_id &&
        this.props.row.entreprenuer_id.length > 0 &&
        this.props.row.notification &&
        this.props.row.notification.length > 0 ? (
          <span></span>
        ) : (
          <React.Fragment>
            <button
              className='btn btn-secondary'
              onClick={(_id, e) => this.handleShow(_id, e)}
              _id={this.props._id}
            >
              <FontAwesomeIcon icon={faShareSquare} /> RE-ASSIGN
            </button>
            &nbsp;
            <button
              className='btn btn-light'
              onClick={(_id, e) => this.onBtnClick(this.props._id, e)}
              _id={this.props._id}
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
            <Modal show={this.state.show} onHide={this.handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Reassign a job dispatch</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <ReassignJob _id={this.props._id} close={this.handleClose} />
              </Modal.Body>
            </Modal>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
  handleClose() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }
  onBtnClick(id) {
    const confirmDelete = window.confirm('Are you sure you want to delete?');
    if (confirmDelete === true) {
      this.removeJob(id);
    } else {
      return false;
    }
  }
  removeJob(id) {
    var req = unirest('DELETE', addr + 'api/job/' + id);

    req.headers({
      'cache-control': 'no-cache',
      authorization: token,
    });

    req.end(function (res) {
      if (res.error) console.log(res);

      if (res.status === 201) {
        alertifyjs.success('Job removed successfully', 2);
      } else {
      }
    });
  }
}
