// Colors
const dumpingSiteColors = {
  Bugolobi: "#F5A623",
  Lubigi: "#4A90E2"
};

const kccaDivisionsColors = {
  Central: "#003f5c",
  Kawempe: "#444e86",
  Makindye: "#955196",
  Nakawa: "#dd5182",
  Outside: "#ff6e54",
  Rubaga: "#31C8DF"
};

const SPTypeColors = {
  Emptiers: "#F15B40",
  Gulpers: "#70C8BE",
  Total: "#052750",
  Other: "#5c4c62"
};

const mapColors = {
  dumpingTrips: "#5c4c62",
  emptyingTripsMinColor: "#E5FEDA",
  emptyingTripsMaxColor: "#316847"
};

export { dumpingSiteColors, kccaDivisionsColors, SPTypeColors, mapColors };
