import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import moment from "moment";

// Components
import { Layout } from "../../components/inc/Layout";
import { CustomLoading } from "../../components/inc/Layout";
import { AddNewJobForm } from "../../components/Dispatch";
import {
  EditVoucher,
  DeactivateVoucher,
} from "../../components/VoucherActions/VoucherActions";

import { useGetVoucherData } from "../../components/customHooks/tweyonjeDB";
import { timeConvertDifference } from "../../components/utils/tableUtilities";
const gSession = window.sessionStorage;

function VoucherDetails({ match }) {
  const { id } = match.params;
  const voucherID = id;
  const doID = gSession.getItem("wai");
  const isAdmin = gSession.getItem("isAdmin") === "true" ? true : false;

  const [showDispatchModal, setShowDispatchModal] = useState(false);
  const [showDeactivateModal, setShowDeactivateModal] = useState(false);
  const [showEditVoucherModal, setShowEditVoucherModal] = useState(false);

  const [{ isLoading, voucher }] = useGetVoucherData(isAdmin, doID, voucherID);

  const { data } = voucher;

  const handleShowDispatchModal = () => {
    setShowDispatchModal(!showDispatchModal);
  };

  const handleShowDeactivateModal = () => {
    setShowDeactivateModal(!showDeactivateModal);
  };

  const handleShowEditVoucherModal = () => {
    setShowEditVoucherModal(!showEditVoucherModal);
  };

  return (
    <Layout>
      <div>
        {isLoading ? (
          <CustomLoading text="Loading dashboard data..." />
        ) : (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h2>
                {`Voucher  
              ${
                (data && data[0].status === 1) || (data && data[0].status === 3)
                  ? data && data[0].voucherReadableID
                  : ""
              } `}
              </h2>
              <div style={{ display: "flex" }}>
                <Button
                  bsStyle="primary"
                  // onClick={handleShowEditVoucherModal}
                  style={{ marginRight: 25, width: 100 }}
                  disabled={data && data[0].status === 3}
                >
                  Edit
                </Button>
                {data && data[0].status === 1 && (
                  <Button bsStyle="danger" onClick={handleShowDeactivateModal}>
                    Deactivate
                  </Button>
                )}
              </div>
            </div>
            <div className="voucher-details-info-container">
              <div className="voucher-details-info-left">
                <div className="voucher-details-info-section">
                  <span className="voucher--details-info-title">
                    Voucher Number
                  </span>
                  <span className="voucher--details-info-value">
                    {data && data[0].status === 2
                      ? "Voucher denied"
                      : data && data[0].status === 0
                      ? "Pending approval"
                      : data && data[0].voucherReadableID}
                  </span>
                  <span className="voucher--details-info-title">Value</span>
                  <span className="voucher--details-info-value">
                    {data && data[0].status === 2
                      ? "Voucher denied"
                      : isNaN(data && data[0].value)
                      ? "Pending approval"
                      : `UGX ${new Intl.NumberFormat("lg-UG").format(
                          data && data[0].value
                        )}`}
                  </span>
                  <span className="voucher--details-info-title">Valid for</span>
                  <span className="voucher--details-info-value">
                    {data && timeConvertDifference(data[0].expiryDate, data[0])}
                  </span>
                  {data && data[0].status === 2 && (
                    <>
                      <span className="voucher--details-info-title">
                        Reason for Denial
                      </span>
                      <span className="voucher--details-info-value">
                        {data && data[0].reasonForDenial}
                      </span>
                    </>
                  )}
                </div>
                <div className="voucher-details-info-section">
                  <span className="voucher--details-info-title">
                    Beneficiary Name
                  </span>
                  <span className="voucher--details-info-value">
                    {data && data[0].beneficiary}
                  </span>
                  <span className="voucher--details-info-title">
                    Beneficiary Phone Number
                  </span>
                  <span className="voucher--details-info-value">
                    {data && data[0].beneficiaryNumber}
                  </span>
                  <span className="voucher--details-info-title">
                    Household Number
                  </span>
                  <span className="voucher--details-info-value">
                    {data && data[0].householdID}
                  </span>
                  <span className="voucher--details-info-title">Division</span>
                  <span className="voucher--details-info-value">
                    {data && data[0].validForDivision}
                  </span>
                </div>
                <div
                  className="voucher-details-info-section"
                  style={{ justifyContent: "space-between" }}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <span className="voucher--details-info-title">
                      Service Provider
                    </span>
                    <span className="voucher--details-info-value">
                      {data && data[0].status === 2
                        ? "Voucher denied"
                        : data && data[0].status === 0
                        ? "Pending approval"
                        : data && data[0].serviceProvider}
                    </span>
                    {data && data[0].status === 1 && (
                      <Button bsStyle="info" onClick={handleShowDispatchModal}>
                        Dispatch to SP
                      </Button>
                    )}
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <span className="voucher--details-info-title">Status</span>
                    <span
                      className={`voucher-details-info-status ${
                        data && data[0].status === 0
                          ? "pending"
                          : data && data[0].status === 1
                          ? "approved"
                          : data && data[0].status === 2
                          ? "denied"
                          : "redeemed"
                      }`}
                    >
                      {data && data[0].status === 0
                        ? "Pending approval"
                        : data && data[0].status === 1
                        ? "Approved"
                        : data && data[0].status === 2
                        ? "Denied"
                        : "Redeemed"}
                    </span>
                  </div>
                </div>
              </div>
              <div className="voucher-details-info-right">
                <div className="voucher-details-info-section right">
                  <span className="voucher--details-info-title">
                    Requested By
                  </span>
                  <span className="voucher--details-info-value">
                    {data && data[0].generatedBy}
                  </span>
                  <span className="voucher--details-info-title">
                    Approved By
                  </span>
                  <span className="voucher--details-info-value">
                    {data && data[0].approvedBy}
                  </span>
                </div>
                <div className="voucher-details-info-section right">
                  <span className="voucher--details-info-title">
                    Request Data and Time
                  </span>
                  <span className="voucher--details-info-value">
                    {data &&
                      moment(data[0].createdAt).format("DD/MM/YY h:mm a")}
                  </span>
                  {((data && data[0].status === 1) ||
                    (data && data[0].status >= 3)) && (
                    <>
                      <span className="voucher--details-info-title">
                        Approved Date and Time
                      </span>
                      <span className="voucher--details-info-value">
                        {data &&
                          moment(data[0].approvedOn).format("DD/MM/YY h:mm a")}
                      </span>
                    </>
                  )}
                  {data && data[0].status >= 3 && (
                    <>
                      <span className="voucher--details-info-title">
                        Redeemed Date and Time
                      </span>
                      <span className="voucher--details-info-value">
                        {data &&
                          moment(data[0].redeemedOn).format("DD/MM/YY h:mm a")}
                      </span>
                    </>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <Modal show={showDispatchModal} onHide={handleShowDispatchModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            Dispatch voucher request for {data && data[0].beneficiary}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddNewJobForm
            close={handleShowDispatchModal}
            voucherDetails={data && data[0]}
          />
        </Modal.Body>
      </Modal>

      <Modal show={showEditVoucherModal} onHide={handleShowEditVoucherModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            Edit Voucher {data && data[0].voucherReadableID}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditVoucher
            close={handleShowEditVoucherModal}
            voucherDetails={data && data[0]}
          />
        </Modal.Body>
      </Modal>

      <Modal show={showDeactivateModal} onHide={handleShowDeactivateModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            Deactivate Voucher {data && data[0].voucherReadableID}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DeactivateVoucher
            close={handleShowDeactivateModal}
            voucherDetails={data && data[0]}
          />
        </Modal.Body>
      </Modal>
    </Layout>
  );
}

export default VoucherDetails;
