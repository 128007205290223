import React from "react";
import { Badge } from "react-bootstrap";

import moment from "moment";

const DivisionBadge = ({ divisions }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {divisions.map((division) => {
        return (
          <Badge style={{ marginBottom: 10, padding: 8 }} key={division._id}>
            {division.divisionName}
          </Badge>
        );
      })}
    </div>
  );
};

const todaysDate = moment().local().format("YYYY-MM-DD");

export const renderShowsTotal = (start, to, total) => {
  return (
    <p style={{ color: "blue" }}>
      From {start} to {to}, totals is {total}&nbsp;&nbsp;
    </p>
  );
};

export const timeConvert = (cell, row) => {
  return moment(cell).format("DD/MM/YY");
};

export const formatDivisions = (cell, row) => {
  return <DivisionBadge divisions={cell} />;
};

export const timeConvertDifference = (cell, row) => {
  let value;

  if (row.status === 0) {
    value = "Pending approval";
  } else if (row.status === 2) {
    value = "Denied";
  } else {
    const a = moment(cell);
    value =
      a.diff(todaysDate, "days") < 0
        ? "Expired"
        : `${a.diff(todaysDate, "days")} days`;
  }

  return value;
};

export const formatVoucherValues = (cell, row) => {
  return `UGX 
    ${new Intl.NumberFormat("lg-UG").format(isNaN(cell) ? 0 : cell)}`;
};

export const formatStatusValues = (cell, row) => {
  return cell === 0
    ? "Pending approval"
    : cell === 1
    ? "Approved"
    : cell === 2
    ? "Denied"
    : cell === 3 && row.jobStatus === 0
    ? "Pending job completion"
    : cell === 3 && row.jobStatus === 1
    ? "Pending CATs approval"
    : cell === 4 && row.jobStatus === 1
    ? "Pending Your approval"
    : cell === 5 && row.jobStatus === 1
    ? "Ready for payment"
    : "Completed";
};

export const formatCATSStatus = (cell) => {
  return cell === true ? "Active" : "Inactive";
};

export const formatVoucherProgramStatus = (cell) => {
  return cell === 0 ? "Pending approval" : cell === 1 ? "Approved" : "Denied";
};

export const tdStyle = {
  fontSize: "small",
};
