import React, { useState } from "react";
import moment from "moment";

import {
  BootstrapTable,
  TableHeaderColumn,
  ExportCSVButton,
} from "react-bootstrap-table";
import { Modal, Button } from "react-bootstrap";

import { AddNewJobForm } from "../../components/Dispatch";
import {
  ApproveVoucherRequest,
  DeclineVoucherRequest,
  ApproveVoucherJob,
  CompleteAllJobs,
} from "../../components/VoucherActions/VoucherActions";
import {
  renderShowsTotal,
  timeConvert,
  timeConvertDifference,
  formatVoucherValues,
  formatStatusValues,
  tdStyle,
} from "../utils/tableUtilities";

import "../../styles/voucher.css";

const ActionButton = ({ row, isAdmin }) => {
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [showDeclineModal, setShowDeclineModal] = useState(false);
  const [showDispatchModal, setShowDispatchModal] = useState(false);
  const [showApproveVoucherJobModal, setShowApproveVoucherJobModal] = useState(
    false
  );

  const handleShowApproveModal = () => {
    setShowApproveModal(!showApproveModal);
  };

  const handleShowDeclineModal = () => {
    setShowDeclineModal(!showDeclineModal);
  };

  const handleShowDispatchModal = () => {
    setShowDispatchModal(!showDispatchModal);
  };

  const handleShowApproveVoucherJobModal = () => {
    setShowApproveVoucherJobModal(!showApproveVoucherJobModal);
  };

  const handleRemindCatsToApproveJob = (data) => {
    const {
      catsDeviceID,
      catsName,
      catsPhoneNumber,
      serviceProvider,
      beneficiary,
      voucherReadableID,
    } = data;

    const message = `Dear ${catsName}, \n service provider ${serviceProvider} has indicated that they have completed emptying household belonging to ${beneficiary} [Voucher Number - ${voucherReadableID}]. \n Please go inspect the service and provide your approval in the CATs application`;

    alert(message);
  };

  return (
    <React.Fragment>
      {isAdmin ? (
        <Button style={{ marginRight: "10px" }} bsStyle="primary">
          View
        </Button>
      ) : (
        <>
          {row.status === 0 ? (
            <>
              <Button
                style={{ marginRight: "10px" }}
                bsStyle="primary"
                onClick={handleShowApproveModal}
              >
                Approve
              </Button>
              <Button bsStyle="danger" onClick={handleShowDeclineModal}>
                Decline
              </Button>
            </>
          ) : row.status === 1 ? (
            <Button bsStyle="info" onClick={handleShowDispatchModal}>
              Dispatch to SP
            </Button>
          ) : row.status === 3 && row.jobStatus === 0 ? (
            <Button bsStyle="info" disabled>
              Remind service provider
            </Button>
          ) : row.status === 3 && row.jobStatus === 1 ? (
            <Button
              bsStyle="info"
              onClick={(e) => handleRemindCatsToApproveJob(row)}
            >
              Remind VHT to approve
            </Button>
          ) : row.status === 4 ? (
            <Button
              bsStyle="info"
              onClick={handleShowApproveVoucherJobModal}
              disabled
            >
              Awaiting VHT approval
            </Button>
          ) : row.status === 5 ? (
            <Button bsStyle="info" onClick={handleShowApproveVoucherJobModal}>
              Approve Job
            </Button>
          ) : (
            <>
              {/* <Button
                bsStyle="warning"
                // onClick={() => handleReview(cell, row, rowIndex)}
              >
                Review
              </Button> */}
            </>
          )}
        </>
      )}

      <Modal show={showApproveModal} onHide={handleShowApproveModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            Approve voucher request for {row.beneficiary}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ height: 250 }}>
          <ApproveVoucherRequest
            close={handleShowApproveModal}
            voucherDetails={row}
          />
        </Modal.Body>
      </Modal>

      <Modal show={showDeclineModal} onHide={handleShowDeclineModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            Decline voucher request for {row.beneficiary}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ height: 250 }}>
          <DeclineVoucherRequest
            close={handleShowDeclineModal}
            voucherDetails={row}
          />
        </Modal.Body>
      </Modal>

      <Modal show={showDispatchModal} onHide={handleShowDispatchModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            Dispatch voucher request for {row.beneficiary}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddNewJobForm close={handleShowDispatchModal} voucherDetails={row} />
        </Modal.Body>
      </Modal>

      <Modal
        show={showApproveVoucherJobModal}
        onHide={handleShowApproveVoucherJobModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Approve Voucher Job {row.voucherReadableID}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ApproveVoucherJob
            close={handleShowApproveVoucherJobModal}
            voucherDetails={row}
          />
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

const VoucherTable = ({ vouchers, isAdmin, sps }) => {
  const { data } = vouchers;

  const actionButton = (cell, row, enumObject, rowIndex) => {
    return (
      <ActionButton
        cell={cell}
        row={row}
        rowIndex={rowIndex}
        isAdmin={isAdmin}
      />
    );
  };

  const createCustomExportCSVButton = (onClick) => {
    return (
      <ExportCSVButton
        btnText="Export to CSV"
        btnGlyphicon="glyphicon-download"
        onClick={() => handleExportCSVButtonClick(onClick)}
      />
    );
  };

  const handleExportCSVButtonClick = (onClick) => {
    onClick();
  };

  const onRowClick = (row, cell) => {
    // Prevent action columns triggering vouchers view
    if (cell === undefined) {
      return;
    }
    window.location.href = "/vouchers/" + row._id;
  };

  const options = {
    exportCSVBtn: createCustomExportCSVButton,
    page: 1,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "25",
        value: 25,
      },
      {
        text: "All",
        value: data && data.length,
      },
    ],
    sizePerPage: 15,
    pageStartIndex: 1,
    paginationSize: 3,
    prePage: "Prev",
    nextPage: "Next",
    firstPage: "First",
    lastPage: "Last",
    paginationShowsTotal: renderShowsTotal,
    paginationPosition: "bottom",
    onRowClick: onRowClick,
  };

  const CompleteJobsButton = ({ vouchers, sps }) => {
    const [showAddCatsModal, setShowAddCatsModal] = useState(false);

    const handleShowCompleteAllJobsModal = () => {
      setShowAddCatsModal(!showAddCatsModal);
    };

    return (
      <>
        <Button
          style={{ float: "right", marginBottom: "2rem", marginRight: "10px" }}
          bsStyle="primary"
          onClick={handleShowCompleteAllJobsModal}
        >
          Complete All approved jobs
        </Button>
        <Modal show={showAddCatsModal} onHide={handleShowCompleteAllJobsModal}>
          <Modal.Header closeButton>
            <Modal.Title>Add CATs/VHT</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ height: 480 }}>
            <CompleteAllJobs
              vouchers={data}
              sps={sps}
              close={handleShowCompleteAllJobsModal}
            />
          </Modal.Body>
        </Modal>
      </>
    );
  };

  return (
    <div className="voucher-table-container">
      //<CompleteJobsButton vouchers={data} sps={sps} />
      <BootstrapTable
        data={data}
        striped
        hover
        pagination={true}
        options={options}
        exportCSV
        csvFileName={
          "vouchers_" +
          moment(Date.now()).local().format("YYYY_MM_DD_HHmmss") +
          ".csv"
        }
        thStyle
      >
        <TableHeaderColumn
          isKey
          dataSort={true}
          dataField="voucherReadableID"
          width="140px"
          thStyle={tdStyle}
          tdStyle={tdStyle}
          csvHeader="Voucher Number"
        >
          Voucher
        </TableHeaderColumn>

        <TableHeaderColumn
          dataSort={true}
          dataField="beneficiary"
          thStyle={tdStyle}
          tdStyle={tdStyle}
          csvHeader="Beneficiary Name"
        >
          Beneficiary Name
        </TableHeaderColumn>

        <TableHeaderColumn
          dataSort={true}
          dataField="beneficiaryNumber"
          width="160px"
          thStyle={tdStyle}
          tdStyle={tdStyle}
          csvHeader="Beneficiary Phone Number"
        >
          Beneficiary Phone
        </TableHeaderColumn>
        <TableHeaderColumn
          dataSort={true}
          dataField="address"
          width="140px"
          thStyle={tdStyle}
          tdStyle={tdStyle}
          csvHeader="Address"
        >
          Address
        </TableHeaderColumn>
        <TableHeaderColumn
          dataSort={true}
          dataField="householdID"
          width="80px"
          thStyle={tdStyle}
          tdStyle={tdStyle}
          csvHeader="Household Identifier"
        >
          Household ID
        </TableHeaderColumn>
        <TableHeaderColumn
          dataSort={true}
          dataField="voucherProgram"
          thStyle={tdStyle}
          tdStyle={tdStyle}
          csvHeader="Voucher Program"
          width="140px"
        >
          Program
        </TableHeaderColumn>
        <TableHeaderColumn
          dataSort={true}
          dataField="validForDivision"
          width="100px"
          thStyle={tdStyle}
          tdStyle={tdStyle}
          csvHeader="Division"
        >
          Division
        </TableHeaderColumn>
        <TableHeaderColumn
          dataSort={true}
          dataField="spType"
          width="80px"
          thStyle={tdStyle}
          tdStyle={tdStyle}
          csvHeader="Service Provider Type"
        >
          SP Type
        </TableHeaderColumn>
        <TableHeaderColumn
          dataSort={true}
          dataField="value"
          dataFormat={formatVoucherValues}
          width="100px"
          thStyle={tdStyle}
          tdStyle={tdStyle}
          csvHeader="Value"
        >
          Value
        </TableHeaderColumn>
        <TableHeaderColumn
          dataSort={true}
          dataField="createdAt"
          dataFormat={timeConvert}
          width="80px"
          thStyle={tdStyle}
          tdStyle={tdStyle}
          csvHeader="Date Requested"
        >
          Created At
        </TableHeaderColumn>
        <TableHeaderColumn
          dataSort={true}
          dataField="expiryDate"
          dataFormat={timeConvertDifference}
          width="80px"
          thStyle={tdStyle}
          tdStyle={tdStyle}
          csvHeader="Expiry Date"
        >
          Expires in
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="status"
          dataFormat={formatStatusValues}
          width="160px"
          thStyle={tdStyle}
          tdStyle={tdStyle}
          csvHeader="Status"
          csvFormat={formatStatusValues}
        >
          Status
        </TableHeaderColumn>

        <TableHeaderColumn
          dataField="action"
          dataFormat={actionButton}
          dataAlign="center"
          thStyle={tdStyle}
          tdStyle={tdStyle}
        >
          Action
        </TableHeaderColumn>
      </BootstrapTable>
    </div>
  );
};

export default VoucherTable;
